import { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default class ShareOptions extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired
	};

	render() {
		const { t, getUrl } = this.props;

		return (
			<Fragment>
				<p>{t("ShareOptions.content")}</p>
				<ul>
					<li className="tip">
						<Link to={getUrl("/share/tip")}>
							<p className="small-text">
								<strong>{t("ShareOptions.tip.title")}</strong>
								{t("ShareOptions.tip.description")}
							</p>
						</Link>
					</li>
					<li className="story">
						<Link to={getUrl("/share/story")}>
							<p className="small-text">
								<strong>{t("ShareOptions.story.title")}</strong>
								{t("ShareOptions.story.description")}
							</p>
						</Link>
					</li>
					<li className="feedback">
						<Link to={getUrl("/experience-index")}>
							<p className="small-text">
								<strong>{t("ShareOptions.feedback.title")}</strong>
								{t("ShareOptions.feedback.description")}
							</p>
						</Link>
					</li>
					<li className="living-cost">
						<Link to={getUrl("/share/living-cost")}>
							<p className="small-text">
								<strong>{t("ShareOptions.living_cost.title")}</strong>
								{t("ShareOptions.living_cost.description")}
							</p>
						</Link>
					</li>
				</ul>
			</Fragment>
		);
	}
}
