import { PureComponent } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "@/common/ClickableDrupalContent.jsx";
import { termPropTypes } from "@/common/Term.js";
import "./ServiceReferences.scss";

export const servicesReferencesPropTypes = PropTypes.arrayOf(
	PropTypes.exact({
		id: PropTypes.number.isRequired,
		type: PropTypes.oneOf(["service"]).isRequired,
		title: PropTypes.string.isRequired,
		alias: PropTypes.string.isRequired,
		serviceCategory: termPropTypes,
		body: PropTypes.string.isRequired,
		phone: PropTypes.string,
		email: PropTypes.string
	})
);

export default class ServiceReferences extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		services: servicesReferencesPropTypes
	};

	render() {
		const { t, services } = this.props;
		if (!services || !services.length) return null;
		return (
			<div className="ServiceReferences">
				<h2>{t("ServiceReferences.label")}</h2>
				<ul>
					{services.map((node, index) => (
						<li key={index}>
							<h3>{node.title} </h3>
							<ClickableDrupalContent content={node.body} />
							<ul className="more-info">
								{node.email && (
									<li>
										<label>Email</label>
										<p className="small-text">{node.email}</p>
									</li>
								)}
								{node.phone && (
									<li>
										<label>Phone</label>
										<p className="small-text">{node.phone}</p>
									</li>
								)}
								{node.serviceCategory && (
									<li>
										<label>Category</label>
										<p className="small-text">{node.serviceCategory.title}</p>
									</li>
								)}
							</ul>
						</li>
					))}
				</ul>
			</div>
		);
	}
}
