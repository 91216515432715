import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./AppHeader.scss";
import { Link, NavLink } from "react-router-dom";
import { userPropTypes, getLinkToLoginPage } from "@/common/user.js";
import Avatar from "@/common/Avatar.jsx";

export default class AppHeader extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		user: userPropTypes,
		menus: PropTypes.object.isRequired,
		url: PropTypes.string.isRequired,
		view: PropTypes.oneOf(["stream", "map", "liked", "liked-map", "my-content", "my-content-map", "search"])
			.isRequired
	};

	state = {
		menuActive: false,
		atScrollTop: true
	};

	menuRef = React.createRef();

	componentDidMount() {
		document.addEventListener("mousedown", this.handlePossibleOutsideClick, false);
		document.addEventListener("keydown", this.handlePossibleEscapeKeyPress, false);
		window.addEventListener("scroll", this.scrollChanged, false);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handlePossibleOutsideClick, false);
		document.removeEventListener("keydown", this.handlePossibleEscapeKeyPress, false);
		window.removeEventListener("scroll", this.scrollChanged, false);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.url !== this.props.url) {
			this.closeMenu();
		}
		if (prevProps.view !== this.props.view) {
			this.closeMenu();
		}
	}

	closeMenu = () => this.setState({ menuActive: false });
	toggleMenu = () => this.setState({ menuActive: !this.state.menuActive });

	handlePossibleOutsideClick = e => {
		// Menu is closed. Do nothing.
		if (!this.state.menuActive) return;

		// Click was within the menu. Do nothing.
		if (this.menuRef.current.contains(e.target)) return;

		// Reaching here means that user clicked outside menu. Close menu.
		this.closeMenu();
	};

	handlePossibleEscapeKeyPress = e => {
		// On escape, close.
		if (
			e.key === "Escape" && // https://stackoverflow.com/a/3369624/72478
			this.state.menuActive
		) {
			this.closeMenu();
		}
	};

	scrollChanged = () => this.setState({ atScrollTop: document.documentElement.scrollTop === 0 });

	render() {
		const { t, getUrl, user, menus, url, view } = this.props;
		const { menuActive, atScrollTop } = this.state;
		const unreadNotificationsCount = user
			? user.notifications.filter(notification => notification.read === false).length
			: 0;
		return (
			<div className="AppHeader">
				<nav role="navigation" aria-label={t("AppHeader.aria")}>
					<ul>
						<li className="home">
							<NavLink
								to={getUrl("/", { view: undefined, q: undefined })}
								isActive={() => ["stream", "map"].includes(view) && url === "/"}
							>
								{t("AppHeader.home")}
							</NavLink>
						</li>
						<li className="liked">
							<NavLink
								to={getUrl("/", { view: "liked", q: undefined })}
								isActive={() => ["liked", "liked-map"].includes(view) && url === "/"}
							>
								{t("AppHeader.liked")}
							</NavLink>
						</li>
						<li className="card">
							<NavLink to={getUrl("/card", { view: undefined, q: undefined })}>
								{t("AppHeader.card")}
							</NavLink>
						</li>
						<li className="search">
							<NavLink
								to={getUrl("/", { view: "search", q: undefined })}
								isActive={() => view === "search" && url === "/"}
							>
								{t("AppHeader.search")}
							</NavLink>
						</li>
						<li className="my-content">
							<NavLink
								to={getUrl("/", { view: "my-content", q: undefined })}
								isActive={() => ["my-content", "my-content-map"].includes(view) && url === "/"}
							>
								{t("AppHeader.my-content")}
							</NavLink>
						</li>
						{!user && (
							<li className="login">
								<NavLink to={getLinkToLoginPage()}>{t("login")}</NavLink>
							</li>
						)}
						{user && (
							<li className="profile">
								<NavLink to={getUrl("/profile")}>
									{t("AppHeader.profile", { view: undefined, q: undefined })}
									<Avatar user={user} />
								</NavLink>
							</li>
						)}
						{/* 
						TODO: Pending decision.
						{menus["erasmus-services"] && (
							<li className="services">
								<NavLink to={getUrl("/services", { view: undefined, q: undefined })}>
									{t("AppHeader.services")}
								</NavLink>
							</li>
						)}
						*/}
						<li className="menu" ref={this.menuRef}>
							<button onClick={this.toggleMenu} className={menuActive ? "active" : ""}>
								{t("AppHeader.menu")}
								{unreadNotificationsCount > 0 ? (
									<span className="unread">
										{unreadNotificationsCount}
										<span>{t("AppHeader.unread-notifications")}</span>
									</span>
								) : undefined}
							</button>
							<ul className={"menu-content" + (menuActive ? " active" : "")}>
								<li className="notifications">
									<Link
										to={getUrl("/notifications", { view: undefined, q: undefined })}
										className={unreadNotificationsCount > 0 ? "unread" : ""}
									>
										{t("AppHeader.notifications")}
										{unreadNotificationsCount > 0 ? (
											<span className="unread">
												{unreadNotificationsCount}
												<span>{t("AppHeader.unread-notifications")}</span>
											</span>
										) : undefined}
									</Link>
								</li>
								<li className="accommodation">
									<Link to={getUrl("/accommodation", { view: undefined })}>
										{t("Accommodation.title")}
									</Link>
								</li>
								<li className="links">
									<Link to={getUrl("/links", { view: undefined })}>{t("AppHeader.links")}</Link>
								</li>
								{menus["erasmus-about"] && (
									<li className="about">
										<Link to={getUrl("/about", { view: undefined, q: undefined })}>
											{t("AppHeader.about")}
										</Link>
									</li>
								)}
							</ul>
						</li>
						<li className={"share" + (atScrollTop ? "" : " collapsed")}>
							<Link to={getUrl("/share")} className="button">
								{t("AppHeader.share")}
							</Link>
						</li>
					</ul>
				</nav>
			</div>
		);
	}
}
