import axios from "axios";
import { BACKEND_DOMAIN, BACKEND_PATH, BACKEND_LOGIN_PATH, RUNS_THROUGH_REACT_NATIVE_APP } from "@/config.js";
import PropTypes from "prop-types";
import { imagePropTypes } from "@/common/Image.jsx";
import { languageCodePropTypes, languageLevelPropTypes, termPropTypes } from "@/common/Term.js";

export const notificationsPropTypes = PropTypes.arrayOf(
	PropTypes.exact({
		id: PropTypes.number.isRequired,
		created: PropTypes.number.isRequired,
		title: PropTypes.string.isRequired,
		categoryId: PropTypes.number.isRequired,
		category: termPropTypes, // This may have been resolved by code.
		read: PropTypes.bool.isRequired
	})
);

const applicationStatusPropTypes = PropTypes.oneOf([
	"accepted_by_receiving",
	"accepted_by_sending",
	"accepted_by_student",
	"cancelled",
	"deleted_by_sending",
	"deleted_by_student",
	"draft",
	"rejected_by_receiving",
	"rejected_by_sending",
	"rejected_by_student",
	"submitted",
	"waiting_list"
]);

export const userPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	email: PropTypes.string.isRequired,
	username: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	surname: PropTypes.string.isRequired,
	created: PropTypes.number.isRequired,
	esi: PropTypes.string,
	language: PropTypes.string.isRequired,
	picture: imagePropTypes,
	realPicture: imagePropTypes,
	realPictureCreated: PropTypes.number,
	programme: termPropTypes,
	mobilityPhase: termPropTypes.isRequired,
	likedNodeIds: PropTypes.arrayOf(PropTypes.number), // This is no longer used on the frontend.
	edugainHomeOrganisation: PropTypes.string,
	homeOrganisation: PropTypes.exact({
		id: PropTypes.number.isRequired,
		title: PropTypes.string.isRequired,
		code: PropTypes.string.isRequired,
		country: termPropTypes.isRequired,
		city: termPropTypes.isRequired,
		faculties: PropTypes.arrayOf(PropTypes.string),
		allowsOutgoingApplications: PropTypes.bool
	}),
	homeFaculty: PropTypes.exact({
		title: PropTypes.string.isRequired,
		outgoingApplicationRequirements: PropTypes.exact({
			destinationsAmount: PropTypes.number,
			cvRequired: PropTypes.bool,
			motivationLetterRequired: PropTypes.bool,
			transcriptRequired: PropTypes.bool,
			recommendationLetterRequired: PropTypes.bool,
			externalCertificationRequired: PropTypes.bool,
			referenceContactRequired: PropTypes.bool,
			portfolioRequired: PropTypes.bool,
			requiredIsced: PropTypes.string,
			requiredEqf: PropTypes.string,
			requiredEcts: PropTypes.string,
			otherRequirements: PropTypes.arrayOf(PropTypes.exact({ description: PropTypes.string.isRequired })),
			otherDocuments: PropTypes.arrayOf(PropTypes.exact({ description: PropTypes.string.isRequired })),
			languageLevels: PropTypes.arrayOf(
				PropTypes.exact({
					language: languageCodePropTypes.isRequired,
					level: languageLevelPropTypes.isRequired
				})
			)
		})
	}),
	hostOrganisation: PropTypes.exact({
		id: PropTypes.number.isRequired,
		title: PropTypes.string.isRequired,
		code: PropTypes.string.isRequired,
		country: termPropTypes.isRequired,
		city: termPropTypes.isRequired
	}),
	notifications: notificationsPropTypes,
	subscribedNotificationCategories: PropTypes.arrayOf(PropTypes.number),
	ectsCreditsCompleted: PropTypes.number,
	studyLevel: termPropTypes,
	studyField: termPropTypes,
	languageSkills: PropTypes.arrayOf(
		PropTypes.exact({
			language: PropTypes.number.isRequired,
			level: PropTypes.number.isRequired
		})
	),
	preferredHostFaculties: PropTypes.arrayOf(
		PropTypes.exact({
			coopConditionId: PropTypes.string,
			id: PropTypes.number.isRequired,
			title: PropTypes.string.isRequired,
			isced: PropTypes.string,
			organisationId: PropTypes.number.isRequired,
			organisation: PropTypes.string.isRequired,
			organisationCode: PropTypes.string.isRequired,
			organisationAlias: PropTypes.string.isRequired,
			country: PropTypes.string.isRequired,
			city: PropTypes.string.isRequired,
			incomingApplicationRequirements: PropTypes.exact({
				cvRequired: PropTypes.bool,
				motivationLetterRequired: PropTypes.bool,
				otherRequirements: PropTypes.arrayOf(PropTypes.exact({ description: PropTypes.string.isRequired }))
			}),
			languageLevels: PropTypes.arrayOf(
				PropTypes.exact({
					language: languageCodePropTypes.isRequired,
					level: languageLevelPropTypes.isRequired
				})
			),
			coopConditionInfo: PropTypes.exact({
				yearMobilities: PropTypes.number.isRequired,
				totalMonths: PropTypes.number.isRequired,
				usefulInformation: PropTypes.string,
				studyLevels: PropTypes.arrayOf(termPropTypes)
			})
		})
	),
	lastApplicationUpdate: PropTypes.exact({
		id: PropTypes.string,
		status: applicationStatusPropTypes.isRequired,
		date: PropTypes.number.isRequired,
		message: PropTypes.string, // A title/summary for this update, provided by the external system.
		metadata: PropTypes.string, // Extra rich text content for this update, provided by the external system.
		data: PropTypes.object, // JSON data with the submitted form content, for view/resume on the UI.
		statusHistory: PropTypes.arrayOf(
			PropTypes.exact({
				date: PropTypes.number.isRequired,
				status: applicationStatusPropTypes.isRequired
			})
		)
	})
});

export const userTeaserPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	email: PropTypes.string.isRequired,
	username: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	surname: PropTypes.string.isRequired,
	language: PropTypes.string.isRequired,
	picture: imagePropTypes
});

export const logoutFromDrupal = () => {
	console.log("Logging out from drupal.");
	return axios.get("user/logout");
};

/**
 * Produces a link to the login page (a page with various options that is
 * rendered from our app). The login link contains the current URL (where from
 * the user clicked on the login), so that it can be used to redirect the user
 * back when the login is done.
 */
export const getLinkToLoginPage = () =>
	"/login?destination=" +
	encodeURIComponent(window.location.pathname === "/login" ? "/" : window.location.pathname + window.location.search);

/**
 * Moves the user to the CAS service for login. Note that (due to ERA-57) we
 * encode the return link via "returnto" parameter and not via the "destination".
 * The "returnto" can work with a "../" prefix which is needed to move the user
 * to the correct location.
 */
export const loginViaCas = destination => {
	const returnto = RUNS_THROUGH_REACT_NATIVE_APP
		? "../cms/api/redirect-to-app-wrapper?target=" + window.location.protocol + "//" + window.location.host
		: ".." + destination;
	window.location = BACKEND_DOMAIN + BACKEND_PATH + "eulogin?returnto=" + encodeURIComponent(returnto);
};

/*
 * TODO: documentation.
 * The final redirect in the login chain is to /user page with destination parameter e.g /user?destination=../?view=map.
 */
export const myAidActionUrl = destination => {
	const returnto = RUNS_THROUGH_REACT_NATIVE_APP
		? "../cms/api/redirect-to-app-wrapper?target=" + window.location.protocol + "//" + window.location.host
		: ".." + destination;
	return BACKEND_DOMAIN + BACKEND_LOGIN_PATH + "?destination=" + encodeURIComponent(returnto);
};

/**
 * Returns a number between 0 and 100 based on the academic profile specific
 * fields that the specified user has filled in.
 */
export const getAcademicProfilePercentageCompletion = user =>
	Math.round(
		([user.homeFaculty, user.ectsCreditsCompleted, user.studyLevel, user.studyField, user.languageSkills].filter(
			value => {
				if (value === undefined) return false;
				if (value === null) return false;
				if (Array.isArray(value) && value.length === 0) return false;
				return true;
			}
		).length /
			5) *
			100
	);
export const hasHomeOrganisationWhichAllowsOutgoingApplications = user =>
	user.homeOrganisation !== undefined && user.homeOrganisation.allowsOutgoingApplications === true;

export const canSelectDestinations = user =>
	hasHomeOrganisationWhichAllowsOutgoingApplications(user) && getAcademicProfilePercentageCompletion(user) === 100;

export const canSubmitNewApplication = user =>
	canSelectDestinations(user) &&
	(user.lastApplicationUpdate === undefined ||
		["draft", "cancelled", "deleted_by_sending", "deleted_by_student"].includes(user.lastApplicationUpdate.status));

export const isLastApplicationUpdateReadOnly = user =>
	user.lastApplicationUpdate &&
	user.lastApplicationUpdate.status !== undefined &&
	user.lastApplicationUpdate.status !== "draft";
