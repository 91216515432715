import { PureComponent } from "react";
import PropTypes from "prop-types";

export const termPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired
});

export const programmePropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	body: PropTypes.string,
	hasHomeOrganisation: PropTypes.bool.isRequired,
	hasHostOrganisation: PropTypes.bool.isRequired,
	hasAcademicProfile: PropTypes.bool.isRequired,
	providesCard: PropTypes.bool.isRequired,
	features: PropTypes.arrayOf(PropTypes.string).isRequired
});

export const worldLanguagePropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	european: PropTypes.bool.isRequired
});

export const languageLevelPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	code: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired
});

export const languageCodePropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	code: PropTypes.string.isRequired
});

export default class Term extends PureComponent {
	static propTypes = {
		term: termPropTypes.isRequired
	};

	render() {
		return this.props.term.title;
	}
}
