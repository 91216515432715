import { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { likeNode, unlikeNode } from "@/skeleton/DataAccess.js";
import { nodePropTypes, streamNodePropTypes } from "@/nodes/PropTypes.jsx";
import { userPropTypes, getLinkToLoginPage } from "@/common/user.js";
import history from "@/skeleton/history.js";
import "./Likes.scss";

export default class Likes extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		user: userPropTypes,
		node: PropTypes.oneOfType([nodePropTypes, streamNodePropTypes]).isRequired,
		onNodeLikesChange: PropTypes.func.isRequired
	};

	likeClicked = e => {
		const { user, node, onNodeLikesChange } = this.props;

		// Because this button may be rendered within an anchor.
		e.preventDefault();

		// User is not logged in, so move them to the login page.
		if (!user) {
			history.push(getLinkToLoginPage());
			return;
		}

		// Like (or unlike) and call the callback with the relevant subset of the updated node.
		if (node.liked) {
			unlikeNode(node.id).then(() => onNodeLikesChange({ id: node.id, likes: node.likes - 1, liked: false }));
		} else {
			likeNode(node.id).then(() => onNodeLikesChange({ id: node.id, likes: (node.likes || 0) + 1, liked: true }));
		}
	};

	render() {
		const { t, node } = this.props;
		return (
			<div className="Likes">
				{node.likes > 0 && (
					<Fragment>
						<label>{t("Likes.label")}</label>
						<span>{node.likes}</span>
					</Fragment>
				)}
				<button onClick={this.likeClicked} className={node.liked ? "liked" : undefined}>
					{!node.liked && t("like")}
					{node.liked && t("unlike")}
				</button>
			</div>
		);
	}
}
