import { PureComponent } from "react";
import PropTypes from "prop-types";
import Avatar from "@/common/Avatar.jsx";
import Ago from "@/common/Ago.jsx";
import "./PublishingMeta.scss";

export default class NodePublishingMeta extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		node: PropTypes.object.isRequired // Should have used streamNodePropTypes from nodes/PropTypes.js but: Uncaught ReferenceError: Cannot access 'dealPropTypes' before initialization
	};

	render() {
		const { t, node } = this.props;
		return (
			<header className="NodePublishingMeta">
				{node.author && <Avatar user={node.author} />}
				<div className="info">
					{t.map(
						"NodePublishingMeta.label",
						{},
						{
							_USER_: (
								<strong className="name">
									{node.studentOrganisation
										? node.studentOrganisation
										: node.author
											? `${node.author.name} ${node.author.surname}`
											: t("User.anonymous")}
								</strong>
							),
							_NODE_: <strong className="type">{t("NodeType." + node.type)}</strong>,
							_AGO_: <Ago t={t} timestamp={node.created} />
						}
					)}
				</div>
			</header>
		);
	}
}
