import { PureComponent } from "react";
import PropTypes from "prop-types";
import { userPropTypes, userTeaserPropTypes } from "@/common/user.js";
import Image from "@/common/Image.jsx";
import "./Avatar.scss";

export default class Avatar extends PureComponent {
	static propTypes = {
		user: PropTypes.oneOfType([userPropTypes, userTeaserPropTypes]).isRequired
	};

	getMonogram = () =>
		this.props.user && this.props.user.name && this.props.user.name.length
			? this.props.user.name[0].toUpperCase()
			: "";

	render() {
		const { user } = this.props;
		return (
			<div className="Avatar" title={`${user.name} ${user.surname}`}>
				{user.picture && <Image image={user.picture} type="avatar" />}
				{!user.picture && (
					<span className="no-picture" aria-hidden="true">
						{this.getMonogram()}
					</span>
				)}
			</div>
		);
	}
}
