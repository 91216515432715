import { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import "./Accommodation.scss";
import { userPropTypes, getLinkToLoginPage } from "@/common/user.js";
import Loading from "@/common/Loading.jsx";
import MagicGridWrapper from "@/common/MagicGridWrapper.jsx";
import { Link } from "react-router-dom";
import history from "@/skeleton/history.js";
import { decodeQuery } from "@/common/utils.jsx";
import { handleError } from "@/skeleton/DataAccess.js";
import {
	loadAccommodationInventory,
	loadAccommodationProviders,
	loadAccommodationQualityLabels,
	trackAccommodationModuleOpen,
	trackAccommodationProviderOpen
} from "@/other/accommodation/DataAccess.js";
import SlidingPanel from "@/common/SlidingPanel.jsx";
import { setTitle } from "@/common/DocumentTitle.js";
import { NavLink } from "react-router-dom";
import { AccommodationItem } from "@/other/accommodation/AccommodationItem.jsx";
import WeShareWeCare from "@/other/accommodation/WeShareWeCare.jsx";
import AccommodationFilters from "@/other/accommodation/AccommodationFilters.jsx";
import QualityLabels from "@/other/accommodation/QualityLabels.jsx";
import AccommodationMap from "@/other/accommodation/AccommodationMap.jsx";
import ECSupportFooter from "@/common/ECSupportFooter.jsx";

const housingTypes = ["house", "apartment", "studio", "room"];

export const accommodationStaticRoutes = [
	"/accommodation",
	"/accommodation/filters",
	"/accommodation/filters/city",
	"/accommodation/filters/housing-types",
	"/accommodation/filters/quality-labels",
	"/accommodation/filters/bedroom-count",
	"/accommodation/filters/total-size",
	"/accommodation/filters/rent",
	"/accommodation/filters/sort",
	"/accommodation/quality-labels"
];

// Captures anything under /accommodation so relevant content can be handled by this class.
export const isAccommodationUrl = url => url === "/accommodation" || url.startsWith("/accommodation/");

export default class Accommodation extends PureComponent {
	state = {
		providers: undefined,
		qualityLabels: undefined,
		inventory: undefined,
		loadingStatus: undefined, // [undefined, "loading", "error-404", "error-5xx", "error-network"]
		exitPanelItem: undefined,
		mapPanelItem: undefined
	};

	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		user: userPropTypes,
		url: PropTypes.string.isRequired,
		search: PropTypes.string
	};

	setTitle = () => setTitle([this.props.t("Accommodation.title")]);

	componentDidMount() {
		const { t, user } = this.props;

		// User is not logged in, so move them to the login page.
		if (!user) {
			history.push(getLinkToLoginPage());
			return;
		}

		this.setTitle();

		loadAccommodationProviders()
			.then(providers => {
				this.setState({ providers: providers });
			})
			.catch(error => handleError(t, error));
		loadAccommodationQualityLabels()
			.then(qualityLabels => {
				this.setState({ qualityLabels: qualityLabels });
			})
			.catch(error => handleError(t, error));

		// Slightly delay tracking which is not very important.
		setTimeout(trackAccommodationModuleOpen, 1000);
	}

	isMetadataStateLoaded = state => state.providers !== undefined && state.qualityLabels !== undefined;

	componentDidUpdate(prevProps, prevState) {
		// Metadata has loaded and there is a city on the URL.
		if (
			!this.isMetadataStateLoaded(prevState) &&
			this.isMetadataStateLoaded(this.state) &&
			this.getCityTitleSearchParam()
		) {
			this.loadAccommodationInventory();
		}

		// Any of the filters has changed.
		if (
			this.getCityTitleSearchParam(prevProps) !== this.getCityTitleSearchParam(this.props) ||
			this.getHousingTypesSearchParam(prevProps) !== this.getHousingTypesSearchParam(this.props) ||
			this.getQualityLabelsSearchParam(prevProps) !== this.getQualityLabelsSearchParam(this.props) ||
			this.getBedroomCountMinSearchParam(prevProps) !== this.getBedroomCountMinSearchParam(this.props) ||
			this.getBedroomCountMaxSearchParam(prevProps) !== this.getBedroomCountMaxSearchParam(this.props) ||
			this.getTotalSizeMinSearchParam(prevProps) !== this.getTotalSizeMinSearchParam(this.props) ||
			this.getTotalSizeMaxSearchParam(prevProps) !== this.getTotalSizeMaxSearchParam(this.props) ||
			this.getRentMinSearchParam(prevProps) !== this.getRentMinSearchParam(this.props) ||
			this.getRentMaxSearchParam(prevProps) !== this.getRentMaxSearchParam(this.props) ||
			this.getSortOrderSearchParam(prevProps) !== this.getSortOrderSearchParam(this.props)
		) {
			this.loadAccommodationInventory();
		}
	}

	filtersEffective = () =>
		this.getCityTitleSearchParam() !== undefined ||
		this.getHousingTypesSearchParam() !== undefined ||
		this.getQualityLabelsSearchParam() !== undefined ||
		this.getBedroomCountMinSearchParam() !== undefined ||
		this.getBedroomCountMaxSearchParam() !== undefined ||
		this.getTotalSizeMinSearchParam() !== undefined ||
		this.getTotalSizeMaxSearchParam() !== undefined ||
		this.getRentMinSearchParam() !== undefined ||
		this.getRentMaxSearchParam() !== undefined ||
		this.getSortOrderSearchParam() !== undefined;

	loadAccommodationInventory = (loadMore = false) => {
		const { t } = this.props;
		const { qualityLabels } = this.state;
		if (this.getCityTitleSearchParam() === undefined) {
			this.setState({ inventory: undefined });
			return;
		}
		this.setState({ loadingStatus: "loading" });
		// Index 1.
		const page = !loadMore ? 1 : this.state.inventory.pagination.page + 1;
		loadAccommodationInventory(
			this.getCityTitleSearchParam(),
			this.getHousingTypesSearchParam() !== undefined ? this.getHousingTypesSearchParam().split("-") : undefined,
			this.getQualityLabelsSearchParam() !== undefined
				? this.getQualityLabelsSearchParam()
						.split("-")
						.map(Number)
						.map(qualityLabelId => qualityLabels[qualityLabelId])
						.filter(Boolean) // Filter out not found quality labels.
						.map(qualityLabel => qualityLabel.name)
				: undefined,
			this.getBedroomCountMinSearchParam(),
			this.getBedroomCountMaxSearchParam(),
			this.getTotalSizeMinSearchParam(),
			this.getTotalSizeMaxSearchParam(),
			this.getRentMinSearchParam(),
			this.getRentMaxSearchParam(),
			this.getUrlSortBy(),
			this.getSortOrderSearchParam(),
			page
		)
			.then(inventory => {
				if (!loadMore) {
					// Initial load. Fully replace state.
					this.setState({
						inventory: { listings: inventory.listings, pagination: inventory.pagination },
						loadingStatus: undefined
					});
				} else {
					// Concat results into existing items because we don't do pagination, but "load more".
					this.setState({
						inventory: {
							listings: this.state.inventory.listings.concat(inventory.listings),
							pagination: inventory.pagination
						},
						loadingStatus: undefined
					});
				}
			})
			.catch(error =>
				handleError(
					t,
					error,
					undefined, // 401
					() => this.setState({ loadingStatus: "error-404" }),
					() => this.setState({ loadingStatus: "error-5xx" }),
					() => this.setState({ loadingStatus: "error-network" })
				)
			);
	};

	getCityTitleSearchParam = (props = this.props) => this.getStringSearchParam(props.search, "cityTitle");
	getHousingTypesSearchParam = (props = this.props) => this.getStringSearchParam(props.search, "housingTypes");
	getQualityLabelsSearchParam = (props = this.props) => this.getStringSearchParam(props.search, "qualityLabels");
	getBedroomCountMinSearchParam = (props = this.props) => this.getIntSearchParam(props.search, "bedroomCountMin");
	getBedroomCountMaxSearchParam = (props = this.props) => this.getIntSearchParam(props.search, "bedroomCountMax");
	getTotalSizeMinSearchParam = (props = this.props) => this.getIntSearchParam(props.search, "totalSizeMin");
	getTotalSizeMaxSearchParam = (props = this.props) => this.getIntSearchParam(props.search, "totalSizeMax");
	getRentMinSearchParam = (props = this.props) => this.getIntSearchParam(props.search, "rentMin");
	getRentMaxSearchParam = (props = this.props) => this.getIntSearchParam(props.search, "rentMax");
	getUrlSortBy = (props = this.props) => this.getStringSearchParam(props.search, "sortBy");
	getSortOrderSearchParam = (props = this.props) => this.getStringSearchParam(props.search, "sortOrder");

	getIntSearchParam = (search, key) => {
		const value = Number.parseInt(decodeQuery(search)[key]);
		return isNaN(value) ? undefined : value;
	};

	getStringSearchParam = (search, key) => {
		const value = decodeQuery(search)[key];
		return value === undefined || value.length === 0 ? undefined : value;
	};

	getUrl = (path, extraParams = {}) => {
		if (!("cityTitle" in extraParams)) extraParams.cityTitle = this.getCityTitleSearchParam();
		if (!("housingTypes" in extraParams)) extraParams.housingTypes = this.getHousingTypesSearchParam();
		if (!("qualityLabels" in extraParams)) extraParams.qualityLabels = this.getQualityLabelsSearchParam();
		if (!("bedroomCountMin" in extraParams)) extraParams.bedroomCountMin = this.getBedroomCountMinSearchParam();
		if (!("bedroomCountMax" in extraParams)) extraParams.bedroomCountMax = this.getBedroomCountMaxSearchParam();
		if (!("totalSizeMin" in extraParams)) extraParams.totalSizeMin = this.getTotalSizeMinSearchParam();
		if (!("totalSizeMax" in extraParams)) extraParams.totalSizeMax = this.getTotalSizeMaxSearchParam();
		if (!("rentMin" in extraParams)) extraParams.rentMin = this.getRentMinSearchParam();
		if (!("rentMax" in extraParams)) extraParams.rentMax = this.getRentMaxSearchParam();
		if (!("sortBy" in extraParams)) extraParams.sortBy = this.getUrlSortBy();
		if (!("sortOrder" in extraParams)) extraParams.sortOrder = this.getSortOrderSearchParam();
		return this.props.getUrl(path, extraParams);
	};

	loadMore = () => {
		this.loadAccommodationInventory(true);
	};

	hasMore = () => this.state.inventory.listings.length < this.state.inventory.pagination.totalListings;

	setExitPanelItem = item => this.setState({ exitPanelItem: item, mapPanelItem: undefined });

	setMapPanelItem = item => this.setState({ exitPanelItem: undefined, mapPanelItem: item });

	renderCustomHeader = () => {
		const { t } = this.props;
		return (
			<div className="app-subheader">
				<div className="title">
					<h1>{t("Accommodation.title")}</h1>
				</div>
				<div className="stream-controls">
					<NavLink
						to={this.getUrl("/accommodation/booking-process")}
						className="button booking-process"
						aria-live="polite"
					>
						{t("Accommodation.intro.bookingProcess")}
					</NavLink>
					<NavLink
						to={this.getUrl("/accommodation/quality-labels")}
						className="button quality-labels"
						aria-live="polite"
					>
						{t("Accommodation.QualityLabels.title")}
					</NavLink>
					<NavLink to={this.getUrl("/accommodation/filters")} className="button filters" aria-live="polite">
						{t("JourneyNavigator.filters")}
						{this.filtersEffective() ? (
							<span className="effective">{t("JourneyNavigator.filters_effective")}</span>
						) : (
							<span>{t("JourneyNavigator.filters_not_effective")}</span>
						)}
					</NavLink>
				</div>
			</div>
		);
	};

	renderFilters = () => {
		const { t, url } = this.props;
		const { qualityLabels, inventory } = this.state;
		return (
			<SlidingPanel
				t={t}
				isVisible={url.includes("/accommodation/filters")}
				title={t("Filters.title")}
				extraCssClass="FiltersPanel"
				close={this.getUrl("/accommodation")}
				back={false}
			>
				<AccommodationFilters
					t={t}
					getUrl={this.getUrl}
					url={url}
					cityTitleSearchParam={this.getCityTitleSearchParam()}
					housingTypesSearchParam={this.getHousingTypesSearchParam()}
					housingTypes={housingTypes}
					qualityLabelsSearchParam={this.getQualityLabelsSearchParam()}
					qualityLabels={qualityLabels}
					bedroomCountMinSearchParam={this.getBedroomCountMinSearchParam()}
					bedroomCountMaxSearchParam={this.getBedroomCountMaxSearchParam()}
					totalSizeMinSearchParam={this.getTotalSizeMinSearchParam()}
					totalSizeMaxSearchParam={this.getTotalSizeMaxSearchParam()}
					rentMinSearchParam={this.getRentMinSearchParam()}
					rentMaxSearchParam={this.getRentMaxSearchParam()}
					sortBySearchParam={this.getUrlSortBy()}
					sortOrderSearchParam={this.getSortOrderSearchParam()}
					resultsSize={inventory != null ? inventory.pagination.totalListings : 0}
				/>
			</SlidingPanel>
		);
	};

	renderQualityLabelsPanel = () => {
		const { t, url } = this.props;
		const { qualityLabels } = this.state;
		return (
			<SlidingPanel
				t={t}
				isVisible={url === "/accommodation/quality-labels"}
				title={t("Accommodation.QualityLabels.title")}
				extraCssClass="QualityLabelsPanel"
				close={this.getUrl("/accommodation")}
				back={false}
			>
				<QualityLabels qualityLabels={qualityLabels} />
			</SlidingPanel>
		);
	};

	renderItemExitPanel() {
		const { t } = this.props;
		const { exitPanelItem } = this.state;
		let content = null;
		if (exitPanelItem !== undefined) {
			content = (
				<div className="message warning">
					<p>
						{t.map(
							"Accommodation.AccommodationExitPanel.text",
							{},
							{
								_LINK_: (
									<a
										href={exitPanelItem.listingUrl}
										onClick={() => trackAccommodationProviderOpen(exitPanelItem.providerName)}
										target="_blank"
										rel="external noopener noreferrer"
									>
										<strong>{t("Accommodation.AccommodationExitPanel.link")}</strong>
									</a>
								)
							}
						)}
					</p>
				</div>
			);
		}

		return (
			<SlidingPanel
				t={t}
				isVisible={exitPanelItem !== undefined}
				title={t("Accommodation.AccommodationExitPanel.title")}
				extraCssClass="AccommodationExitPanel"
				close={() => {
					this.setExitPanelItem(undefined);
					// Manually restore title since this SlidingPanel does not alter the URL.
					this.setTitle();
				}}
				back={false}
			>
				{content}
			</SlidingPanel>
		);
	}

	renderItemMapPanel() {
		const { t } = this.props;
		const { mapPanelItem } = this.state;
		let content = null;
		if (mapPanelItem !== undefined) {
			content = <AccommodationMap t={t} location={[mapPanelItem.latitude, mapPanelItem.longitude]} />;
		}

		return (
			<SlidingPanel
				t={t}
				isVisible={mapPanelItem !== undefined}
				title={mapPanelItem ? mapPanelItem.title : undefined}
				extraCssClass="MapPanel"
				close={() => {
					this.setExitPanelItem(undefined);
					// Manually restore title since this SlidingPanel does not alter the URL.
					this.setTitle();
				}}
				back={false}
			>
				{content}
			</SlidingPanel>
		);
	}

	renderContent() {
		const { t, getUrl } = this.props;
		const { loadingStatus, inventory } = this.state;

		const items =
			inventory && inventory.listings
				? inventory.listings.filter(item => housingTypes.includes(item.housingType))
				: [];
		return (
			<div className="Stream">
				<h2>{t("Accommodation.title")}</h2>

				{this.getCityTitleSearchParam() === undefined && (
					<Fragment>
						<p className="no-city-filter">
							{t.map(
								"Accommodation.intro.mandatoryCityFilterInfo",
								{},
								{
									_FILTERS_: (
										<Link to={getUrl("/accommodation/filters/city")}>
											<strong>{t("JourneyNavigator.filters")}</strong>
										</Link>
									)
								}
							)}
							<br />
							{t.map(
								"Accommodation.intro.bookingProcessInfo",
								{},
								{
									_HERE_: (
										<Link to={getUrl("/accommodation/booking-process")}>
											<strong>{t("Accommodation.intro.here")}</strong>
										</Link>
									)
								}
							)}
							<br />
							{t("Accommodation.intro.bookingProcessNote")}
						</p>
						<p className="data-provider">
							<span>{t("Accommodation.intro.dataProvidedBy")}</span>
							<span>{t("Accommodation.intro.home2")}</span>
						</p>
					</Fragment>
				)}

				{this.getCityTitleSearchParam() !== undefined && !loadingStatus && items.length === 0 && (
					<p className="empty">{t("Accommodation.empty")}</p>
				)}

				{loadingStatus && (
					<Loading
						t={t}
						status={loadingStatus}
						retry={() => this.loadAccommodationInventory(false)}
						close={() => {
							this.setState({ loadingStatus: undefined });
							history.replace(this.getUrl("/accommodation"));
						}}
					/>
				)}

				{items.length > 0 && (
					<Fragment>
						<div className="feed" role="feed">
							<MagicGridWrapper static animate={false} gutter={0}>
								{items.map(item => (
									<article key={`${item.id}-${item.lastUpdated}`} className="accommodation-item">
										<div>
											<AccommodationItem
												t={t}
												getUrl={getUrl}
												setExitPanelItem={this.setExitPanelItem}
												setMapPanelItem={this.setMapPanelItem}
												item={item}
											/>
										</div>
									</article>
								))}
								<article>
									<WeShareWeCare t={t} />
								</article>
							</MagicGridWrapper>
						</div>
						<button
							className="button"
							onClick={this.loadMore}
							disabled={loadingStatus === "loading"}
							/* hide using CSS instead of removing from dom, so this.moreButtonDomNode is always valid */
							style={this.hasMore() ? undefined : { display: "none" }}
							ref={node => (this.moreButtonDomNode = node)}
						>
							{t(loadingStatus === "loading" ? "Loading.loading" : "more")}
						</button>
					</Fragment>
				)}
				<ECSupportFooter t={t} />
			</div>
		);
	}

	render = () => {
		const { t } = this.props;

		// Metadata must be loaded prior to anything else.
		if (!this.isMetadataStateLoaded(this.state)) {
			return <Loading t={t} />;
		}
		return (
			<div className="Accommodation">
				{this.renderCustomHeader()}
				{this.renderFilters()}
				{this.renderQualityLabelsPanel()}
				{this.renderItemExitPanel()}
				{this.renderItemMapPanel()}
				{this.renderContent()}
			</div>
		);
	};
}
