import { PureComponent } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { renderUrl } from "@/common/utils.jsx";
import "./Languages.scss";

export const shouldRender = languages => languages && languages.length > 1;

export default class Languages extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		languages: PropTypes.arrayOf(PropTypes.string).isRequired,
		language: PropTypes.string.isRequired,
		alias: PropTypes.string.isRequired
	};

	render() {
		const { t, getUrl, languages, language, alias } = this.props;
		if (!shouldRender(languages)) return null;
		return (
			<nav role="navigation" aria-label={t("Languages.aria")} className="Languages">
				<ul>
					{languages.map((lang, index) => (
						<li key={index}>
							<NavLink
								className="button"
								isActive={() => lang === language}
								to={getUrl(renderUrl(alias, lang))}
							>
								{lang}
							</NavLink>
						</li>
					))}
				</ul>
			</nav>
		);
	}
}
