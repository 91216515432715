import { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { termPropTypes } from "@/common/Term.js";
import { Link } from "react-router-dom";
import "./JourneyMenu.scss";

export const journeyMenuPropTypes = PropTypes.exact({
	title: PropTypes.string.isRequired,
	submenus: PropTypes.arrayOf(
		PropTypes.exact({
			title: PropTypes.string.isRequired,
			body: PropTypes.string,
			phase: termPropTypes.isRequired,
			links: PropTypes.arrayOf(
				PropTypes.exact({
					title: PropTypes.string.isRequired,
					url: PropTypes.string.isRequired,
					importance: PropTypes.oneOf(["MANDATORY", "HIGH"]),
					visited: PropTypes.bool
				})
			).isRequired,
			actionTitle: PropTypes.string
		})
	).isRequired
});

export default class JourneyMenu extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		journeyMenu: journeyMenuPropTypes
	};

	render() {
		const { t, getUrl, journeyMenu } = this.props;
		return (
			<nav role="navigation" aria-label={t("JourneyMenu.aria")} className="JourneyMenu">
				{journeyMenu.submenus.map((submenu, index) => (
					<Fragment key={index}>
						<h2>{submenu.title}</h2>
						<ul>
							{submenu.links.map((link, index) => (
								<li key={index}>
									<Link to={getUrl(link.url)}>{link.title}</Link>
								</li>
							))}
						</ul>
					</Fragment>
				))}
			</nav>
		);
	}
}
