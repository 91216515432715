import { PureComponent } from "react";
import PropTypes from "prop-types";

const areAllCostsIncluded = costs => {
	let allIncluded = true;
	Object.keys(costs).forEach(key => {
		if (costs[key] !== "included_in_rent") {
			allIncluded = false;
		}
	});

	return allIncluded;
};

const getCosts = costs => {
	if (areAllCostsIncluded(costs)) {
		return "(all included)";
	} else {
		return "";
	}
};

export class RentCost extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		rent: PropTypes.object,
		costs: PropTypes.object,
		currencyCode: PropTypes.string
	};

	render() {
		const { t, rent, costs, currencyCode } = this.props;
		const rentAmount = rent ? Number(rent.amount / 100) : 0;

		return (
			<p className="RentCost">
				<strong>
					{currencyCode} {rentAmount}
				</strong>
				<span data-tip data-for="cost-details">
					{t("Accommodation.RentCost.perMonth")} {getCosts(costs)}
				</span>
			</p>
		);
	}
}
