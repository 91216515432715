// Contains rendering functions extracted from ProfilePanel in order to reduce its size.
// Each function accepts the ProfilePanel component instance as an argument in
// order to extract props, state, member functions etc. This is most probably
// and anti-pattern, but it's the easiest way in order to declutter ProfilePanel.

import { Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import Autosuggest from "react-autosuggest";
import { loadStudyFields } from "@/skeleton/DataAccess.js";
import { hasHomeOrganisationWhichAllowsOutgoingApplications } from "@/common/user.js";

export const renderAcademicProfilePanel = parent => {
	const { t, getUrl, user } = parent.props;

	if (!hasHomeOrganisationWhichAllowsOutgoingApplications(user)) return <Redirect to={getUrl("/profile")} />;

	return (
		<Fragment>
			<p>{t("ProfilePanel.academicProfile.description")}</p>
			{renderHomeFaculty(parent)}
			{renderStudyLevel(parent)}
			{renderStudyField(parent)}
			{renderEctsCreditsCompleted(parent)}
			{renderLanguageSkills(parent)}
		</Fragment>
	);
};

const renderHomeFaculty = parent => {
	const { t, getUrl, url, user, setUserProfileFieldValue } = parent.props;
	return (
		<div className="editableField">
			<h2>{t("ProfilePanel.academicProfile.homeFaculty.title", { home: user.homeOrganisation.title })}</h2>
			<div>
				{url === "/profile/academic/faculty" ? (
					<Fragment>
						<select
							aria-label={t("ProfilePanel.academicProfile.homeFaculty.title", {
								home: user.homeOrganisation.title
							})}
							value={user.homeFaculty ? user.homeFaculty.title : undefined}
							onChange={e => setUserProfileFieldValue("homeFaculty", e.target.value || undefined)}
						>
							<option value="">{t("ProfilePanel.field_empty")}</option>
							{user.homeOrganisation.faculties &&
								user.homeOrganisation.faculties.map((faculty, index) => (
									<option key={index} value={faculty}>
										{faculty}
									</option>
								))}
							<option value="*">{t("ProfilePanel.academicProfile.homeFaculty.other")}</option>
						</select>
						<p>{t("ProfilePanel.academicProfile.homeFaculty.description")}</p>
						<Link to={getUrl("/profile/academic")} role="button" className="cancel">
							{t("cancel")}
						</Link>
					</Fragment>
				) : (
					<Fragment>
						<p>
							{user.homeFaculty
								? user.homeFaculty.title === "*"
									? t("ProfilePanel.academicProfile.homeFaculty.other")
									: user.homeFaculty.title
								: t("ProfilePanel.field_empty")}
						</p>
						<Link to={getUrl("/profile/academic/faculty")} role="button" className="edit">
							{t("edit")}
						</Link>
					</Fragment>
				)}
			</div>
		</div>
	);
};

const renderEctsCreditsCompleted = parent => {
	const { t, getUrl, url, user, setUserProfileFieldValue } = parent.props;
	const { ectsCreditsCompleted } = parent.state;
	return (
		<div className="editableField">
			<h2>{t("ProfilePanel.academicProfile.ectsCreditsCompleted")}</h2>
			<div>
				{url === "/profile/academic/ects" ? (
					<form
						onSubmit={e => {
							e.preventDefault();
							setUserProfileFieldValue(
								"ectsCreditsCompleted",
								ectsCreditsCompleted === undefined ||
									Number.isNaN(Number(ectsCreditsCompleted)) ||
									ectsCreditsCompleted.trim() === ""
									? undefined
									: Number(ectsCreditsCompleted)
							);
						}}
					>
						<input
							type="number"
							value={ectsCreditsCompleted}
							onChange={e => parent.setState({ ectsCreditsCompleted: e.target.value })}
							min="0"
							max="400"
						/>
						<button type="submit" className="apply">
							{t("apply")}
						</button>
						<Link to={getUrl("/profile/academic")} role="button" className="cancel">
							{t("cancel")}
						</Link>
					</form>
				) : (
					<Fragment>
						<p>
							{user.ectsCreditsCompleted === undefined
								? t("ProfilePanel.field_empty")
								: user.ectsCreditsCompleted}
						</p>
						<Link to={getUrl("/profile/academic/ects")} role="button" className="edit">
							{t("edit")}
						</Link>
					</Fragment>
				)}
			</div>
		</div>
	);
};

const renderStudyLevel = parent => {
	const { t, getUrl, url, user, studyLevels, setUserProfileFieldValue } = parent.props;
	return (
		<div className="editableField">
			<h2>{t("ProfilePanel.academicProfile.studyLevel")}</h2>
			<div>
				{url === "/profile/academic/study-level" ? (
					<Fragment>
						{studyLevels && studyLevels.length > 0 && (
							<select
								aria-label={t("ProfilePanel.academicProfile.studyLevel")}
								value={user.studyLevel ? user.studyLevel.id : undefined}
								onChange={e =>
									setUserProfileFieldValue(
										"studyLevel",
										Number.isNaN(Number(e.target.value)) || Number(e.target.value) === 0
											? undefined
											: Number(e.target.value)
									)
								}
							>
								<option value="">{t("ProfilePanel.field_empty")}</option>
								{studyLevels.map(studyLevel => (
									<option key={studyLevel.id} value={studyLevel.id}>
										{studyLevel.title}
									</option>
								))}
							</select>
						)}
						<Link to={getUrl("/profile/academic")} role="button" className="cancel">
							{t("cancel")}
						</Link>
					</Fragment>
				) : (
					<Fragment>
						<p>{user.studyLevel === undefined ? t("ProfilePanel.field_empty") : user.studyLevel.title}</p>
						<Link to={getUrl("/profile/academic/study-level")} role="button" className="edit">
							{t("edit")}
						</Link>
					</Fragment>
				)}
			</div>
		</div>
	);
};

const renderStudyField = parent => {
	const { t, getUrl, url, user, setUserProfileFieldValue } = parent.props;
	const { studyField, studyFields } = parent.state;
	return (
		<div className="editableField">
			<h2>{t("ProfilePanel.academicProfile.studyField")}</h2>
			<div>
				{url === "/profile/academic/study-field" ? (
					<Fragment>
						<Autosuggest
							suggestions={studyFields}
							onSuggestionsFetchRequested={({ value }) =>
								loadStudyFields(value).then(values => parent.setState({ studyFields: values }))
							}
							onSuggestionsClearRequested={() => parent.setState({ studyFields: [] })}
							onSuggestionSelected={(event, { suggestion }) =>
								setUserProfileFieldValue("studyField", suggestion.id)
							}
							getSuggestionValue={term => term.title}
							renderSuggestion={term => term.title}
							inputProps={{
								placeholder: t("ProfilePanel.academicProfile.studyField_prompt"),
								value: studyField,
								onChange: (event, { newValue }) => parent.setState({ studyField: newValue }),
								onBlur: () => studyField === "" && setUserProfileFieldValue("studyField", undefined),
								onKeyDown: e =>
									e.key === "Enter" &&
									studyField === "" &&
									setUserProfileFieldValue("studyField", undefined),
								maxLength: 32
							}}
						/>
						<Link to={getUrl("/profile/academic")} role="button" className="cancel">
							{t("cancel")}
						</Link>
					</Fragment>
				) : (
					<Fragment>
						<p>{user.studyField === undefined ? t("ProfilePanel.field_empty") : user.studyField.title}</p>
						<Link to={getUrl("/profile/academic/study-field")} role="button" className="edit">
							{t("edit")}
						</Link>
					</Fragment>
				)}
			</div>
		</div>
	);
};

const renderLanguageSkills = parent => {
	const { t, getUrl, url, user, setUserProfileFieldValue, worldLanguages, languageLevels } = parent.props;
	const { languageSkillLanguage, languageSkillLevel } = parent.state;

	const renderOptgroup = (label, filter) => (
		<optgroup label={label}>
			{worldLanguages
				.filter(filter)
				.sort(language => language.title)
				.sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0))
				.map(language => (
					<option
						key={language.id}
						value={language.id}
						disabled={
							user.languageSkills &&
							user.languageSkills.find(skill => skill.language === language.id) !== undefined
						}
					>
						{language.title}
					</option>
				))}
		</optgroup>
	);

	return (
		<div className="editableField languageSkills">
			<h2>{t("ProfilePanel.academicProfile.languageSkills")}</h2>
			<div>
				{url === "/profile/academic/language-skills" ? (
					<Fragment>
						{user.languageSkills === undefined && (
							<p>{t("ProfilePanel.academicProfile.languageSkills_empty")}</p>
						)}
						{user.languageSkills !== undefined && (
							<ul>
								{user.languageSkills.map((languageSkill, index) => {
									const language = worldLanguages.find(
										worldLanguage => worldLanguage.id === languageSkill.language
									);
									const level = languageLevels.find(
										languageLevel => languageLevel.id === languageSkill.level
									);
									return (
										<li key={index}>
											{language ? language.title : t("unknown")}:{" "}
											{level ? level.code + " - " + level.title : t("unknown")}
											<button
												className="delete"
												onClick={() =>
													setUserProfileFieldValue(
														"languageSkills",
														user.languageSkills.filter(
															entry =>
																!(
																	entry.language === languageSkill.language &&
																	entry.level === languageSkill.level
																)
														)
													)
												}
											>
												{t("delete")}
											</button>
										</li>
									);
								})}
							</ul>
						)}
						<form
							onSubmit={e => {
								e.preventDefault();
								if (Number(languageSkillLanguage) && Number(languageSkillLevel)) {
									setUserProfileFieldValue(
										"languageSkills",
										[
											...(user.languageSkills || []),
											{ language: languageSkillLanguage, level: languageSkillLevel }
										],
										() => parent.setState({ languageSkillLanguage: "", languageSkillLevel: "" })
									);
								}
							}}
						>
							<fieldset>
								<legend>{t("ProfilePanel.academicProfile.languageSkills_prompt")}</legend>
								<label>
									{t("ProfilePanel.academicProfile.languageSkills_language")}
									<select
										value={languageSkillLanguage}
										onChange={e =>
											parent.setState({ languageSkillLanguage: Number(e.target.value) })
										}
										required
									>
										<option value="">{t("ProfilePanel.field_empty")}</option>
										{renderOptgroup(
											t("ProfilePanel.academicProfile.languageSkills_language_european"),
											language => language.european
										)}
										{renderOptgroup(
											t("ProfilePanel.academicProfile.languageSkills_language_other"),
											language => !language.european
										)}
									</select>
								</label>

								<label>
									{t("ProfilePanel.academicProfile.languageSkills_level")}
									<select
										value={languageSkillLevel}
										onChange={e => parent.setState({ languageSkillLevel: Number(e.target.value) })}
										required
									>
										<option value="">{t("ProfilePanel.field_empty")}</option>
										{languageLevels.map(languageLevel => (
											<option key={languageLevel.id} value={languageLevel.id}>
												{languageLevel.code} - {languageLevel.title}
											</option>
										))}
									</select>
								</label>
							</fieldset>
							<button type="submit" className="apply">
								{t("add")}
							</button>
							<Link to={getUrl("/profile/academic")} role="button" className="cancel">
								{t("cancel")}
							</Link>
						</form>
					</Fragment>
				) : (
					<Fragment>
						{user.languageSkills === undefined ? (
							<p>{t("ProfilePanel.field_empty")}</p>
						) : (
							<ul>
								{user.languageSkills.map((languageSkill, index) => {
									const language = worldLanguages.find(
										worldLanguage => worldLanguage.id === languageSkill.language
									);
									const level = languageLevels.find(
										languageLevel => languageLevel.id === languageSkill.level
									);
									return (
										<li key={index}>
											{language ? language.title : t("unknown")}:{" "}
											{level ? level.code + " - " + level.title : t("unknown")}
										</li>
									);
								})}
							</ul>
						)}
						<Link to={getUrl("/profile/academic/language-skills")} role="button" className="edit">
							{t("edit")}
						</Link>
					</Fragment>
				)}
			</div>
		</div>
	);
};
