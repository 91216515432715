import { PureComponent } from "react";
import PropTypes from "prop-types";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export default class AccommodationMedia extends PureComponent {
	static propTypes = {
		media: PropTypes.arrayOf(PropTypes.object).isRequired
	};

	render() {
		const { media } = this.props;
		return media.length > 1 ? (
			<Carousel showStatus={false} showThumbs={false} infiniteLoop={true}>
				{media.slice(0, 5).map((image, i) => (
					<picture key={i} className="Image">
						<img
							src={image.url}
							alt={image.title}
							width="452"
							height="300"
							loading="lazy"
							decoding="async"
						/>
					</picture>
				))}
			</Carousel>
		) : (
			<picture className="Image">
				<img src={media[0].url} alt={media[0].title} width="452" height="300" loading="lazy" decoding="async" />
			</picture>
		);
	}
}
