import { PureComponent } from "react";
import PropTypes from "prop-types";
import "./ECSupportFooter.scss";

export default class ECSupportFooter extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired
	};

	render() {
		const { t } = this.props;
		return (
			<footer className="ECSupportFooter">
				<p>{t("ECSupportFooter.text")}</p>
			</footer>
		);
	}
}
