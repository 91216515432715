import { PureComponent } from "react";
import PropTypes from "prop-types";
import { userPropTypes, canSelectDestinations, getLinkToLoginPage } from "@/common/user.js";
import Wrapper from "@/common/Wrapper.jsx";
import Ago from "@/common/Ago.jsx";
import { Link, Redirect } from "react-router-dom";
import {
	handleError,
	updateApplication as updateApplicationInServer,
	deleteDraftApplication as deleteDraftApplicationFromServer
} from "@/skeleton/DataAccess.js";
import ClickableDrupalContent from "@/common/ClickableDrupalContent.jsx";
import { renderDateTime } from "@/common/utils.jsx";
import "./Applications.scss";

const applicationStatusMoveActionsPerStatus = {
	draft: ["submitted"],
	submitted: ["deleted_by_student"],
	waiting_list: ["deleted_by_student"],
	accepted_by_sending: ["accepted_by_student", "rejected_by_student"],
	accepted_by_student: ["cancelled"],
	accepted_by_receiving: ["cancelled"],
	rejected_by_sending: ["deleted_by_student"],
	rejected_by_student: ["deleted_by_student"],
	rejected_by_receiving: ["deleted_by_student"],
	cancelled: [],
	deleted_by_sending: [],
	deleted_by_student: []
};

export const applicationActionsPerStatus = {
	draft: ["discard", "resume"],
	submitted: ["view"],
	waiting_list: ["view"],
	accepted_by_sending: ["view"],
	accepted_by_student: ["view"],
	accepted_by_receiving: ["view"],
	rejected_by_sending: [],
	rejected_by_student: [],
	rejected_by_receiving: [],
	cancelled: [],
	deleted_by_sending: [],
	deleted_by_student: []
};

export default class Applications extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		user: userPropTypes,
		reloadUser: PropTypes.func.isRequired
	};

	componentDidMount() {
		// Fully reload user whenever opening the applications because even though
		// "lastApplicationUpdate" already exists on user, it could be stale.
		this.props.reloadUser();
	}

	updateApplication = (id, status) => {
		const { t, reloadUser } = this.props;
		updateApplicationInServer(id, status)
			.then(() => {
				reloadUser();
			})
			.catch(error => {
				handleError(t, error);
			});
	};

	deleteDraftApplication = () => {
		const { t, reloadUser } = this.props;
		deleteDraftApplicationFromServer()
			.then(() => {
				reloadUser();
			})
			.catch(error => {
				handleError(t, error);
			});
	};

	renderStatusChangeActionButton = targetStatus => {
		const { t, user } = this.props;
		const application = user.lastApplicationUpdate;
		if (!applicationStatusMoveActionsPerStatus[application.status].includes(targetStatus)) return null;
		return (
			<button
				className={`button ${targetStatus}`}
				onClick={e => {
					e.preventDefault();
					if (!window.confirm(t("Applications.confirmActionForStatus." + targetStatus))) return;
					this.updateApplication(application.id, targetStatus);
				}}
			>
				{t("Applications.actionForStatus." + targetStatus)}
			</button>
		);
	};

	renderActionButton = (action, callback) => {
		const { t, user } = this.props;
		const application = user.lastApplicationUpdate;
		if (!applicationActionsPerStatus[application.status].includes(action)) return null;
		return (
			<button
				className={`button ${action}`}
				onClick={e => {
					e.preventDefault();
					const confirmMessage = t("Applications.confirmAction." + action);
					if (confirmMessage.length > 0 && !window.confirm(confirmMessage)) return;
					callback();
				}}
			>
				{t("Applications.action." + action)}
			</button>
		);
	};

	renderActionLink = (action, link) => {
		const { t, getUrl, user } = this.props;
		const application = user.lastApplicationUpdate;
		if (!applicationActionsPerStatus[application.status].includes(action)) return null;
		return (
			<Link to={getUrl(link)} className={`button ${action}`}>
				{t("Applications.action." + action)}
			</Link>
		);
	};

	render() {
		const { t, getUrl, user } = this.props;

		// Only available to logged in users. Otherwise redirect to login page.
		if (!user) return <Redirect to={getLinkToLoginPage()} />;

		if (!canSelectDestinations(user)) return <Redirect to={getUrl("/profile")} />;

		const application = user.lastApplicationUpdate;

		if (!application)
			return (
				<div className="Applications">
					<p className="empty">{t("Applications.empty")}</p>
					<div className="actions">
						<Link to={getUrl("/profile/destinations")} className="button">
							{t("Applications.link")}
						</Link>
					</div>
				</div>
			);

		return (
			<div className="Applications">
				<div className={`message info ${application.status}`}>
					<div>
						<p>
							{application.message
								? application.message
								: t("Applications.defaultMessage." + application.status)}
						</p>
						{application.data && application.data.academicYearTitle && (
							<p>
								<strong>{t("ProfilePanel.application.period.academicYear")}: </strong>
								{application.data.academicYearTitle}
							</p>
						)}
						{application.data && application.data.academicTerm && (
							<p>
								<strong>{t("ProfilePanel.application.period.academicTerm")}: </strong>
								{application.data.academicTerm}
							</p>
						)}
						{application.data && application.data.hostName && application.data.hostOrgUnit && (
							<div>
								<p>
									<strong>{t("Applications.destinations")}: </strong>
								</p>
								<ol>
									{application.data.hostName.map((item, index) => (
										<li key={index}>
											{application.data.hostName[index]}, {application.data.hostOrgUnit[index]}
										</li>
									))}
								</ol>
							</div>
						)}
					</div>
				</div>
				{application.metadata && <ClickableDrupalContent content={application.metadata} />}
				<p>
					{t("Applications.date")}: <Ago t={t} timestamp={application.date} />
				</p>
				{application.statusHistory && (
					<details className="statusHistory">
						<summary>{t("Applications.statusHistory")}</summary>
						<ol>
							{application.statusHistory.map((dateStatus, index) => (
								<li key={index}>
									{renderDateTime(dateStatus.date, t("locale.full"))} &rarr;{" "}
									<code>{t("Applications.status." + dateStatus.status)}</code>
								</li>
							))}
						</ol>
					</details>
				)}
				<Wrapper className="actions">
					{this.renderActionButton("discard", this.deleteDraftApplication)}
					{this.renderActionLink("resume", "/profile/application/form/resume")}
					{this.renderActionLink("view", "/profile/application/form/view")}
					{this.renderStatusChangeActionButton("accepted_by_student")}
					{this.renderStatusChangeActionButton("cancelled")}
					{this.renderStatusChangeActionButton("deleted_by_student")}
					{this.renderStatusChangeActionButton("rejected_by_student")}
				</Wrapper>
			</div>
		);
	}
}
