import { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "@/common/ClickableDrupalContent.jsx";
import { userPropTypes, userTeaserPropTypes } from "@/common/user.js";
import { termPropTypes } from "@/common/Term.js";
import Image, { imagePropTypes } from "@/common/Image.jsx";
import { latLonPropTypes } from "@/common/utils.jsx";
import EventReferences, { eventsReferencesPropTypes } from "@/common/EventReferences.jsx";
import { websitePropTypes } from "@/common/Website.jsx";
import ApplicationRequirements, { applicationRequirementsPropTypes } from "@/common/ApplicationRequirements.jsx";
import ServiceReferences, { servicesReferencesPropTypes } from "@/common/ServiceReferences.jsx";
import UsefulInformationReferences, {
	usefulInformationReferencesPropTypes
} from "@/common/UsefulInformationReferences.jsx";
import NodeTopMeta from "@/common/NodeTopMeta.jsx";
import Meta from "@/common/Meta.jsx";
import NodePublishingMeta from "@/common/NodePublishingMeta.jsx";
import { Link } from "react-router-dom";
import { renderNodeUrl } from "@/common/utils.jsx";

export const organisationPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["organisation"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	body: PropTypes.string,
	author: userTeaserPropTypes,
	created: PropTypes.number.isRequired,
	summary: PropTypes.string,
	miniSummary: PropTypes.string,
	image: imagePropTypes,
	likes: PropTypes.number,
	liked: PropTypes.bool,
	visited: PropTypes.bool, // undefined for no ability to mark/unmark done, true for done, false for not done.
	country: PropTypes.arrayOf(termPropTypes).isRequired,
	city: PropTypes.arrayOf(termPropTypes).isRequired,
	address: PropTypes.arrayOf(PropTypes.string),
	location: PropTypes.arrayOf(latLonPropTypes),
	website: websitePropTypes,
	events: eventsReferencesPropTypes,
	services: servicesReferencesPropTypes,
	usefulInformation: usefulInformationReferencesPropTypes,
	applicationRequirements: applicationRequirementsPropTypes
});

export const organisationStreamPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["organisation"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	author: userTeaserPropTypes,
	created: PropTypes.number.isRequired,
	image: imagePropTypes,
	likes: PropTypes.number,
	liked: PropTypes.bool
});

export const organisationMapPropTypes = PropTypes.exact({
	id: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["organisation"]).isRequired,
	title: PropTypes.string.isRequired,
	alias: PropTypes.string.isRequired,
	location: PropTypes.arrayOf(latLonPropTypes)
});

export default class Organisation extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		user: userPropTypes,
		node: organisationPropTypes,
		markNodeElement: PropTypes.element
	};

	render() {
		const { t, getUrl, node, markNodeElement } = this.props;
		return (
			<Fragment>
				<Image image={node.image} type="full" />
				{node.body && <ClickableDrupalContent content={node.body} />}
				<NodeTopMeta t={t} getUrl={getUrl} node={node} />
				<EventReferences t={t} getUrl={getUrl} events={node.events} />
				<UsefulInformationReferences t={t} usefulInformation={node.usefulInformation} />
				<ApplicationRequirements t={t} applicationRequirements={node.applicationRequirements} />
				<ServiceReferences t={t} services={node.services} />
				{markNodeElement}
			</Fragment>
		);
	}
}

export class OrganisationTeaser extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		user: userPropTypes,
		node: organisationStreamPropTypes,
		likesElement: PropTypes.element.isRequired
	};

	render() {
		const { t, getUrl, user, node, likesElement } = this.props;
		return (
			<Fragment>
				<NodePublishingMeta t={t} node={node} />
				<div className="content">
					{node.image && <Image image={node.image} type="teaser" />}
					{!node.image && <span className="no-image"></span>}
					<h3>
						<Link to={getUrl(renderNodeUrl(node, user))}>{node.title}</Link>
					</h3>
				</div>
				<footer>
					<Meta t={t} item={node} />
					{likesElement}
				</footer>
			</Fragment>
		);
	}
}
