import axios from "axios";
import removeFbclid from "remove-fbclid";
import { trackError } from "@/skeleton/DataAccess.js";

/**
 * On dev (web) and production (web) this will be empty. But this app can also
 * be rendered through a react native app which launces a web server and serves
 * the app at http://localhost/. For those cases we need to rewrite XHR and
 * asset paths to using an absolute path pointing to the actual backend server.
 * See ERA-124 for more info.
 */
export const BACKEND_DOMAIN = import.meta.env.VITE_BACKEND_DOMAIN;
export const RUNS_THROUGH_REACT_NATIVE_APP = import.meta.env.VITE_RUNS_THROUGH_REACT_NATIVE_APP !== "false";
export const BACKEND_PATH = "/cms/";
export const BACKEND_LOGIN_PATH = BACKEND_PATH + "user/login";

export const DEFAULT_LANGUAGE = "en";

export const MAP_PREVIEW_MIN_ZOOM = 6;
export const MAP_PREVIEW_DEFAULT_ZOOM = 11;
export const MAP_PREVIEW_MAX_ZOOM = 18;
export const MAP_DEFAULT_CENTER = [50.8503, 4.3517]; // Brussels.
export const MAP_OSM_TILES_SERVER =
	"https://gisco-services.ec.europa.eu/maps/tiles/OSMCartoComposite/EPSG3857/{z}/{x}/{y}.png";

export const MENU_IDS = ["erasmus-about", "erasmus-services"];

export const USER_PICTURE_TAKE_ACCEPT_ATTRIBUTE = "image/jpeg";
export const USER_PICTURE_TAKE_MIME_TYPES = ["image/jpeg"];
export const USER_PICTURE_UPLOAD_ACCEPT_ATTRIBUTE = ".jpg,.png";
export const USER_PICTURE_UPLOAD_MIME_TYPES = ["image/jpeg", "image/png"];
export const AVATAR_UPLOAD_ACCEPT_ATTRIBUTE = ".jpg,.png";
export const AVATAR_UPLOAD_MIME_TYPES = ["image/jpeg", "image/png"];
export const DOCUMENT_UPLOAD_ACCEPT_ATTRIBUTE = ".pdf";

export const WEB_PUSH_PUBLIC_KEY = import.meta.env.VITE_WEB_PUSH_PUBLIC_KEY;

export const MAX_UPLOAD_SIZE = 52428800; // Should not be higher than PHP's upload_max_filesize or nginx's client_max_body_size.

export const SKILLS_LOGIN_URL = import.meta.env.VITE_SKILLS_URL + "/user/login";
export const SKILLS_REGISTER_URL = import.meta.env.VITE_SKILLS_URL + "/user/register";
export const SKILLS_PRIVACY_URL = import.meta.env.VITE_SKILLS_URL + "/privacy";

export const PRIVACY_STATEMENT_URL = "/privacy-statement";
export const TERMS_AND_CONDITIONS_URL = "/terms-conditions";
export const LIVING_COST_URL = "/living-cost";

export const ABOUT_URL = "/about";

// https://stackoverflow.com/a/57920600/72478
// Assumes any of those display values in manifest.json.
const isPwa = () =>
	["fullscreen", "standalone", "minimal-ui"].some(
		displayMode => window.matchMedia("(display-mode: " + displayMode + ")").matches
	);

/**
 * Calculates and stores the application version.
 * Note: A corner case exists on desktops where when the application is first
 * installed and opened as a PWA, the app does not reload so this variable still
 * points to "web". This is not important from an analytics point of view.
 */
export const APPLICATION_VERSION =
	(RUNS_THROUGH_REACT_NATIVE_APP ? "app" : isPwa() ? "pwa" : "web") + // "app" for the RN packaged app, "pwa" for pwa, "web" otherwise.
	"-" +
	import.meta.env.VITE_APPLICATION_VERSION;

export default function configureApp() {
	removeFbclid();
	configureAxios();
	if (!RUNS_THROUGH_REACT_NATIVE_APP) registerGlobalErrorHandler(); // Do not register for the RN due to ERA-281.
	if (!RUNS_THROUGH_REACT_NATIVE_APP) registerServiceWorker();
}

function configureAxios() {
	console.log("Configuring axios");
	axios.defaults.timeout = Number(import.meta.env.VITE_AXIOS_TIMEOUT);
	axios.defaults.baseURL = BACKEND_DOMAIN + BACKEND_PATH;

	// ERA-219 All XHRs should contain a parameter to identify client type and version
	axios.interceptors.request.use(
		config => {
			if (config.params === undefined) config.params = {};
			config.params._v = APPLICATION_VERSION;
			return config;
		},
		error => {
			return Promise.reject(error);
		}
	);

	if (RUNS_THROUGH_REACT_NATIVE_APP) axios.defaults.withCredentials = true; // To allow http://localhost/ to send session cookie which is stored on the actual erasmus domain.
}

function registerServiceWorker() {
	if (!window.navigator.serviceWorker) {
		console.warn("Service workers are not supported by this browser");
		return;
	}
	console.log("Registering service worker");
	window.navigator.serviceWorker
		.register("/service-worker.js", {
			scope: "/"
		})
		.then(
			() => {
				console.log("Service worker has been registered");
			},
			e => {
				console.error("Service worker registration failed", e);
			}
		);
}

function registerGlobalErrorHandler() {
	window.onerror = function (message, script, line, column, error) {
		trackError(message, script, line, column, error);
		return false;
	};
}
