import { PureComponent } from "react";
import PropTypes from "prop-types";
import Loading from "@/common/Loading.jsx";
import { userPropTypes } from "@/common/user.js";
import { uploadRealPicture } from "@/skeleton/DataAccess.js";
import {
	USER_PICTURE_TAKE_ACCEPT_ATTRIBUTE,
	USER_PICTURE_TAKE_MIME_TYPES,
	USER_PICTURE_UPLOAD_ACCEPT_ATTRIBUTE,
	USER_PICTURE_UPLOAD_MIME_TYPES
} from "@/config.js";
import "./RealPictureUploader.scss";

export default class RealPictureUploader extends PureComponent {
	state = {
		loadingStatus: undefined // [undefined, "loading", "error-5xx", "error-network"]
	};

	static propTypes = {
		t: PropTypes.func.isRequired,
		user: userPropTypes,
		reloadUser: PropTypes.func.isRequired
	};

	handleXHRError = error => {
		if (error.code === "ECONNABORTED" || !window.navigator.onLine) {
			this.setState({ loadingStatus: "error-network" });
		} else {
			this.setState({ loadingStatus: "error-5xx" });
		}
	};

	handlePictureFieldUpdate = (inputField, mimeTypes) => {
		const { reloadUser } = this.props;

		// Get file.
		const file = inputField.files[0];

		// Ensure there is a file.
		if (file === undefined) return;

		// Do nothing if user forcefully uploaded a non allowed mime type.
		if (!mimeTypes.includes(file.type)) return;

		// Reset the file upload input.
		inputField.value = "";

		this.setState({ loadingStatus: "loading" });
		uploadRealPicture(file)
			.then(() => {
				reloadUser();
				this.setState({ loadingStatus: undefined });
			})
			.catch(this.handleXHRError);
	};

	closeLoading = () => this.setState({ loadingStatus: undefined });

	// https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/capture
	// This allows us to start up the camera immediately. This is not currently
	// implemented on desktops, thus we need to feature detect.
	isCapturePossible = () => "capture" in document.createElement("input");

	render() {
		const { t } = this.props;
		const { loadingStatus } = this.state;

		return (
			<div className="RealPictureUploader">
				{loadingStatus && <Loading t={t} status={loadingStatus} close={this.closeLoading} />}
				{this.isCapturePossible() && (
					<label className="button take">
						{t("RealPictureUploader.take")}
						<input
							name="upload" // ERA-255
							type="file"
							accept={USER_PICTURE_TAKE_ACCEPT_ATTRIBUTE} // needs a mime type in order to immediately launch the camera
							capture="user"
							onChange={e => this.handlePictureFieldUpdate(e.target, USER_PICTURE_TAKE_MIME_TYPES)}
						/>
					</label>
				)}
				<label className="button upload">
					{t("RealPictureUploader.upload")}
					<input
						name="upload" // ERA-255
						type="file"
						accept={USER_PICTURE_UPLOAD_ACCEPT_ATTRIBUTE}
						onChange={e => this.handlePictureFieldUpdate(e.target, USER_PICTURE_UPLOAD_MIME_TYPES)}
					/>
				</label>
			</div>
		);
	}
}
