import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import history from "@/skeleton/history.js";
import { setTitle } from "@/common/DocumentTitle.js";
import ECSupportFooter from "@/common/ECSupportFooter.jsx";
import "./SlidingPanel.scss";

const closingTransitionDuration = 150; // In sync with $modal-closing-transition-duration of _variables.scss.

let nextId = 0;

let previouslyFocusedElement;
let previouslyFocusedPanelId;

export default class SlidingPanel extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		isVisible: PropTypes.bool.isRequired,
		title: PropTypes.string,
		extraCssClass: PropTypes.string.isRequired,
		close: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
		back: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
		extraFooterElement: PropTypes.element,
		children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
		contentRef: PropTypes.object,
		language: PropTypes.string,
		backdrop: PropTypes.bool
	};

	// A unique id across instances of the SlidingPanel. Used to track focus.
	id = nextId++;

	state = {
		cssTransitionClass: undefined
	};

	rootRef = React.createRef();
	// Parent component may need to be able to control the sliding panel's scroll.
	// Either use a prop ref or create one now.
	contentRef = this.props.contentRef || React.createRef();

	/**
	 * Accessibility improvement for keyboard navigation.
	 * Will focus on the content area of the sliding panel.
	 */
	focus = () => {
		// Globally keep track of the panel id that stole focus.
		previouslyFocusedPanelId = this.id;
		// Globally keep track of the active element before this panel steals focus.
		previouslyFocusedElement = window.document.activeElement;
		// Focus on this panel's content.
		this.contentRef.current.focus();
	};

	/**
	 * Accessibility improvement for keyboard navigation.
	 * Return focus to the previously focused element.
	 */
	blur = () => {
		// If this panel was the last one to steal focus, then focus to the last tracked active element.
		if (previouslyFocusedPanelId === this.id) previouslyFocusedElement.focus();
	};

	componentDidUpdate(prevProps) {
		if (prevProps.isVisible === false && this.props.isVisible === true) {
			this.setState({ cssTransitionClass: "opening" });
			this.focus();
		}
		if (prevProps.isVisible === true && this.props.isVisible === false) {
			this.setState({ cssTransitionClass: "closing" });
			this.blur();
			this.delayedCssTransitionClassReset();
		}
	}

	delayedCssTransitionClassReset = () => {
		this.delayedCssTransitionClassResetTimeout = window.setTimeout(() => {
			// Do not execute if the timeout ID is not there anymore.
			if (!this.delayedCssTransitionClassResetTimeout) return;
			this.setState({ cssTransitionClass: undefined });
			this.clearDelayedCssTransitionClassResetTimeout();
		}, closingTransitionDuration);
	};

	clearDelayedCssTransitionClassResetTimeout = () => {
		if (!this.delayedCssTransitionClassResetTimeout) return;
		window.clearTimeout(this.delayedCssTransitionClassResetTimeout);
		delete this.delayedCssTransitionClassResetTimeout;
	};

	componentDidMount() {
		document.addEventListener("mousedown", this.handlePossibleOutsideClick, false);
		document.addEventListener("keydown", this.handlePossibleEscapeKeyPress, false);
		if (this.props.isVisible) this.focus();
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handlePossibleOutsideClick, false);
		document.removeEventListener("keydown", this.handlePossibleEscapeKeyPress, false);
	}

	handlePossibleOutsideClick = e => {
		// Panel is closed. Do nothing.
		if (!this.props.isVisible) return;

		// Click was within the panel. Do nothing.
		if (this.rootRef.current.contains(e.target)) return;

		// Click was within the backdrop. Do nothing.
		if (this.props.backdrop && e.target.classList.contains("SlidingPanelBackdrop")) return;

		// Click was within a button. Close modal.
		if (e.target.tagName === "BUTTON") this.close();

		// Click was within an anchor. Do nothing.
		if (e.target.closest("a")) return;

		// Reaching here means that user clicked outside panel but not on an anchor. Close panel.
		this.close();
	};

	handlePossibleEscapeKeyPress = e => {
		// Escape should not work when there is a backdrop.
		if (this.props.backdrop) return;

		// On escape, close.
		if (
			e.key === "Escape" && // https://stackoverflow.com/a/3369624/72478
			this.props.isVisible
		) {
			this.close();
		}
	};

	close = () => {
		if (typeof this.props.close === "string") history.push(this.props.close);
		if (typeof this.props.close === "function") this.props.close();
	};

	goBack = () => {
		if (typeof this.props.back === "string") history.push(this.props.back);
		if (typeof this.props.back === "boolean" && this.props.back) history.goBack();
	};

	render() {
		const { t, isVisible, title, children, extraCssClass, back, extraFooterElement, language, backdrop } =
			this.props;
		const { cssTransitionClass } = this.state;
		const className = ["SlidingPanel", extraCssClass];
		if (cssTransitionClass) {
			className.push("visible");
			className.push(cssTransitionClass);
		}
		if (isVisible && !cssTransitionClass) {
			className.push("visible");
		}
		if (isVisible && title) {
			setTitle([title, t("title")]);
		}
		const renderContent = isVisible || cssTransitionClass !== undefined;
		const slidingPanelContent = (
			<div
				className={className.join(" ")}
				role="dialog"
				aria-modal={isVisible ? true : null}
				ref={this.rootRef}
				aria-label={title}
				lang={language}
			>
				<header>
					<h1>{title}</h1>
				</header>

				<section className="content" tabIndex="0" ref={this.contentRef}>
					{renderContent && children}
					<ECSupportFooter t={t} />
				</section>
				<footer>
					{back && (
						<button onClick={this.goBack} className="button secondary small back">
							{t("back")}
						</button>
					)}
					<button onClick={this.close} className="button secondary small close">
						{t("close")}
					</button>
					{extraFooterElement}
				</footer>
			</div>
		);
		return (
			<Fragment>
				{backdrop && renderContent && <div className="SlidingPanelBackdrop"></div>}
				{slidingPanelContent}
			</Fragment>
		);
	}
}
