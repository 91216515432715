import { PureComponent } from "react";
import PropTypes from "prop-types";
import "./Website.scss";

export const websitePropTypes = PropTypes.arrayOf(
	PropTypes.exact({
		url: PropTypes.string.isRequired,
		title: PropTypes.string
	})
);

export default class Website extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		website: websitePropTypes
	};
	render() {
		const { t, website } = this.props;
		if (!website || !website.length) return null;
		return (
			<div className="Website">
				<label>{t("Website.label")}</label>
				<ul>
					{website.map((link, index) => (
						<li key={index}>
							<a href={link.url} rel="external noopener noreferrer">
								{link.title ? link.title : link.url}
							</a>
						</li>
					))}
				</ul>
			</div>
		);
	}
}
