import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import SlidingPanel from "@/common/SlidingPanel.jsx";
import {
	userPropTypes,
	getAcademicProfilePercentageCompletion,
	canSelectDestinations,
	hasHomeOrganisationWhichAllowsOutgoingApplications,
	getLinkToLoginPage,
	isLastApplicationUpdateReadOnly
} from "@/common/user.js";
import { termPropTypes, programmePropTypes, worldLanguagePropTypes, languageLevelPropTypes } from "@/common/Term.js";
import Avatar from "@/common/Avatar.jsx";
import AvatarUploader from "@/common/AvatarUploader.jsx";
import "./ProfilePanel.scss";
import history from "@/skeleton/history.js";
import Autosuggest from "react-autosuggest";
import { loadOrganisations } from "@/skeleton/DataAccess.js";
import { renderAcademicProfilePanel } from "@/skeleton/ProfilePanel.academic.jsx";
import { renderDestinationsPanel } from "@/skeleton/ProfilePanel.destinations.jsx";
import { renderApplicationPanel, enhanceApplicationForm } from "@/skeleton/ProfilePanel.application.jsx";
import { getParentPath } from "@/common/utils.jsx";
import { applicationActionsPerStatus } from "@/common/Applications.jsx";

export default class ProfilePanel extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		open: PropTypes.bool.isRequired,
		close: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired,
		user: userPropTypes,
		logout: PropTypes.func.isRequired,
		localeSwitcher: PropTypes.element.isRequired,
		programmes: PropTypes.arrayOf(programmePropTypes),
		languages: PropTypes.arrayOf(
			PropTypes.exact({
				code: PropTypes.string.isRequired,
				title: PropTypes.string.isRequired
			})
		).isRequired,
		reloadUser: PropTypes.func.isRequired,
		notificationCategories: PropTypes.arrayOf(termPropTypes).isRequired,
		setUserProfileFieldValue: PropTypes.func.isRequired,
		studyLevels: PropTypes.arrayOf(termPropTypes).isRequired,
		worldLanguages: PropTypes.arrayOf(worldLanguagePropTypes).isRequired,
		languageLevels: PropTypes.arrayOf(languageLevelPropTypes).isRequired,
		academicYears: PropTypes.arrayOf(termPropTypes).isRequired,
		academicTerms: PropTypes.arrayOf(PropTypes.string).isRequired
	};

	getInitialStateWhichResetsOnUrlChange = () => {
		const { user } = this.props;
		if (!user) return {};
		return {
			homeOrganisation: user.homeOrganisation ? user.homeOrganisation.title : "",
			homeOrganisations: [],
			hostOrganisation: user.hostOrganisation ? user.hostOrganisation.title : "",
			hostOrganisations: [],
			studyField: user.studyField ? user.studyField.title : "",
			studyFields: [],
			subscribedNotificationCategories: user.subscribedNotificationCategories || [],
			ectsCreditsCompleted: user.ectsCreditsCompleted ? user.ectsCreditsCompleted.toString() : "",
			languageSkillLanguage: "",
			languageSkillLevel: "",
			preferredHostFaculty: "",
			preferredHostFaculties: [],
			applicationFormSubmissionStatus: undefined, // ['warning-uploadMaxSize', 'pending', 'success', 'error']
			selectedFileSizes: {} // The file sizes of the files which have been selected on inputs (but not yet uploaded)
		};
	};

	getInitialState = () => ({
		previewHostFaculty: undefined,
		selectedPreferredHostFacultyIds: [],
		selectedAcademicYearId: undefined,
		selectedAcademicTerm: undefined,
		orderedPreferredHostFacultyIds: [],
		prepopulateForm: false
	});

	state = { ...this.getInitialStateWhichResetsOnUrlChange(), ...this.getInitialState() };

	componentDidMount() {
		enhanceApplicationForm();
	}

	componentDidUpdate(prevProps) {
		const oldUrl = prevProps.url;
		const newUrl = this.props.url;
		if (oldUrl !== newUrl) {
			this.setState({ ...this.getInitialStateWhichResetsOnUrlChange() });
			enhanceApplicationForm();
			// ERA-380 scroll to top for some URL changes. Otherwise the existing scroll is retained.
			if (
				newUrl === "/profile/academic" ||
				newUrl.startsWith("/profile/destinations") ||
				newUrl.startsWith("/profile/application/")
			) {
				this.scrollToTop();
			}
			if (oldUrl === "/profile/academic" && newUrl === "/profile") {
				this.scrollToTop();
			}
			if (oldUrl.startsWith("/profile/destinations") && newUrl === "/profile") {
				this.scrollToTop();
			}
		}
	}

	selectPreferredHostFaculty = hostFacultyId =>
		this.setState({
			selectedPreferredHostFacultyIds: this.state.selectedPreferredHostFacultyIds.concat(hostFacultyId),
			orderedPreferredHostFacultyIds: []
		});

	unselectPreferredHostFaculty = hostFacultyId =>
		this.setState({
			selectedPreferredHostFacultyIds: this.state.selectedPreferredHostFacultyIds.filter(
				selectedPreferredHostFacultyId => selectedPreferredHostFacultyId !== hostFacultyId
			),
			orderedPreferredHostFacultyIds: []
		});

	setAcademicYearId = academicYearId => this.setState({ selectedAcademicYearId: academicYearId });

	setAcademicTerm = academicTerm => this.setState({ selectedAcademicTerm: academicTerm });

	setOrderedPreferredHostFacultyIds = orderedPreferredHostFacultyIds =>
		this.setState({ orderedPreferredHostFacultyIds });

	setApplicationFormSubmissionStatus = status => this.setState({ applicationFormSubmissionStatus: status });

	onApplicationFormSubmissionSuccess = onSuccess => {
		this.setState({ ...this.getInitialState(), applicationFormSubmissionStatus: "success" });
		this.props.reloadUser(onSuccess);
	};

	canUseAcademicProfile = () => {
		const { user, programmes } = this.props;
		if (!user) return false;
		const userProgramme = programmes.find(programme => programme.id === (user.programme && user.programme.id));
		if (!userProgramme) return false;
		return userProgramme.hasAcademicProfile === true;
	};

	contentRef = React.createRef(); // To be able to control the sliding panel's scroll position.

	scrollToTop = () => this.contentRef.current && this.contentRef.current.scrollTo(0, 0);

	renderMainInfo = () => {
		const { t, getUrl, url, user, logout, reloadUser } = this.props;
		return (
			<div className="main-info">
				<div>
					<Avatar user={user} />
					{url === "/profile/avatar" ? (
						<Fragment>
							<AvatarUploader
								t={t}
								user={user}
								reloadUser={() => reloadUser(() => history.push(getUrl("/profile")))}
							/>
							<Link to={getUrl("/profile")} role="button" className="button cancel">
								{t("cancel")}
							</Link>
						</Fragment>
					) : (
						<Fragment>
							<Link to={getUrl("/profile/avatar")} className="edit">
								{t("ProfilePanel.pictureEdit")}
							</Link>
						</Fragment>
					)}
				</div>
				<div>
					<h2>
						{user.name} {user.surname}
					</h2>
					<div className="email">
						<label>{t("email")}:</label> {user.email}
					</div>
					{url !== "/profile/avatar" && (
						<button className="button logout" onClick={logout}>
							{t("AppHeader.logout")}
						</button>
					)}
				</div>
			</div>
		);
	};

	renderLanguage = () => {
		const { t, getUrl, url, user, localeSwitcher, languages } = this.props;
		const userLanguage = languages.find(language => language.code === user.language);
		return (
			<div className="editableField">
				<h3>{t("ProfilePanel.language")}</h3>
				<div>
					{url === "/profile/language" ? (
						<Fragment>
							{localeSwitcher}
							<Link to={getUrl("/profile")} role="button" className="cancel">
								{t("cancel")}
							</Link>
						</Fragment>
					) : (
						<Fragment>
							<p>{userLanguage ? userLanguage.title : user.language}</p>
							<Link to={getUrl("/profile/language")} role="button" className="edit">
								{t("edit")}
							</Link>
						</Fragment>
					)}
				</div>
			</div>
		);
	};

	renderProgramme = () => {
		const { t, getUrl, url, user, programmes, setUserProfileFieldValue } = this.props;
		return (
			<div className="editableField">
				<h3>{t("ProfilePanel.programme")}</h3>
				<div>
					{url === "/profile/programme" ? (
						<Fragment>
							{programmes && programmes.length > 0 && (
								<select
									aria-label={t("ProfilePanel.programme")}
									value={user.programme ? user.programme.id : undefined}
									onChange={e =>
										window.confirm(t("ProfilePanel.programme_change_confirm")) &&
										setUserProfileFieldValue(
											"programme",
											Number.isNaN(Number(e.target.value)) || Number(e.target.value) === 0
												? undefined
												: Number(e.target.value)
										)
									}
								>
									<option value="">{t("ProfilePanel.field_empty")}</option>
									{programmes.map(programme => (
										<option key={programme.id} value={programme.id}>
											{programme.title}
										</option>
									))}
								</select>
							)}
							<Link to={getUrl("/profile")} role="button" className="cancel">
								{t("cancel")}
							</Link>
						</Fragment>
					) : (
						<Fragment>
							<p>{user.programme ? user.programme.title : t("ProfilePanel.field_empty")}</p>
							<Link to={getUrl("/profile/programme")} role="button" className="edit">
								{t("edit")}
							</Link>
						</Fragment>
					)}
				</div>
			</div>
		);
	};

	/**
	 * Organisation type can be "home" or "host". It's not an actual organisation
	 * type, but maps to the 2 corresponding fields on the user.
	 */
	renderOrganisation = organisationType => {
		const { t, getUrl, url, user, setUserProfileFieldValue, programmes } = this.props;
		const autosuggestValueStateKey = organisationType + "Organisation";
		const autosuggestItemsStateKey = organisationType + "Organisations";
		const autosuggestValue = this.state[autosuggestValueStateKey];
		const autosuggestItems = this.state[autosuggestItemsStateKey];
		const field = organisationType + "Organisation";
		const userOrganisation = user[field];
		const userProgramme = programmes.find(programme => programme.id === (user.programme && user.programme.id));
		const userProgrammeField = organisationType === "home" ? "hasHomeOrganisation" : "hasHostOrganisation";
		if (userProgramme && !userProgramme[userProgrammeField]) return null;

		return (
			<div className="editableField organisation">
				<h3>{t("ProfilePanel." + organisationType)}</h3>
				{url === "/profile/" + organisationType ? (
					<div>
						<Autosuggest
							suggestions={autosuggestItems}
							onSuggestionsFetchRequested={({ value }) =>
								loadOrganisations(value).then(values =>
									this.setState({ [autosuggestItemsStateKey]: values })
								)
							}
							onSuggestionsClearRequested={() => this.setState({ [autosuggestItemsStateKey]: [] })}
							onSuggestionSelected={(event, { suggestion }) =>
								setUserProfileFieldValue(field, suggestion.id)
							}
							getSuggestionValue={organisation => organisation.title}
							renderSuggestion={organisation => organisation.title}
							inputProps={{
								placeholder: t("ProfilePanel.organisation_prompt"),
								value: autosuggestValue,
								onChange: (event, { newValue }) =>
									this.setState({ [autosuggestValueStateKey]: newValue }),
								onBlur: () => autosuggestValue === "" && setUserProfileFieldValue(field, undefined),
								onKeyDown: e =>
									e.key === "Enter" &&
									autosuggestValue === "" &&
									setUserProfileFieldValue(field, undefined),
								maxLength: 32
							}}
						/>
						<Link to={getUrl("/profile")} role="button" className="cancel">
							{t("cancel")}
						</Link>
					</div>
				) : (
					<div>
						<p>
							{userOrganisation && (
								<Fragment>
									{userOrganisation.title}
									<br />
									<span className="country">
										{userOrganisation.country && userOrganisation.country.title}
									</span>
									<span className="city">{userOrganisation.city && userOrganisation.city.title}</span>
								</Fragment>
							)}
							{!userOrganisation && t("ProfilePanel.field_empty")}
						</p>
						<Link to={getUrl("/profile/" + organisationType)} role="button" className="edit">
							{t("edit")}
						</Link>
					</div>
				)}
			</div>
		);
	};

	renderHomeOrganisation = () => this.renderOrganisation("home", "homeOrganisation");
	renderHostOrganisation = () => this.renderOrganisation("host", "hostOrganisation");

	renderAcademicProfileLink = () => {
		const { t, getUrl, user } = this.props;
		if (!this.canUseAcademicProfile()) return null;
		return (
			<div
				className={`categoryField${
					hasHomeOrganisationWhichAllowsOutgoingApplications(user) ? " withProgressBar" : ""
				}`}
			>
				<h3>{t("ProfilePanel.academicProfile.title")}</h3>
				{!user.homeOrganisation ? (
					<p>{t("ProfilePanel.requirements.homeMissing")}</p>
				) : !hasHomeOrganisationWhichAllowsOutgoingApplications(user) ? (
					<p>{t("ProfilePanel.requirements.homeDoesNotAllowOutgoingApplications")}</p>
				) : (
					<div>
						<p>{t("ProfilePanel.academicProfile.prompt")}</p>
						<p>
							{t("ProfilePanel.academicProfile.completion", {
								percent: getAcademicProfilePercentageCompletion(user)
							})}
							<progress max="100" value={getAcademicProfilePercentageCompletion(user)}>
								{t("ProfilePanel.academicProfile.completion", {
									percent: getAcademicProfilePercentageCompletion(user)
								})}
							</progress>
						</p>
						<Link to={getUrl("/profile/academic")} role="button" className="edit">
							{t("edit")}
						</Link>
					</div>
				)}
			</div>
		);
	};

	onNotificationCategoryToggle = id => {
		const { subscribedNotificationCategories } = this.state;
		this.setState({
			subscribedNotificationCategories: subscribedNotificationCategories.includes(id)
				? subscribedNotificationCategories.filter(
						subscribedNotificationCategory => subscribedNotificationCategory !== id
					)
				: subscribedNotificationCategories.concat(id)
		});
	};

	renderNotifications = () => {
		const { t, getUrl, url, notificationCategories, setUserProfileFieldValue } = this.props;
		const { subscribedNotificationCategories } = this.state;
		return (
			<div className="editableField">
				<h3>{t("ProfilePanel.notifications")}</h3>
				<div>
					{url === "/profile/notifications" ? (
						<Fragment>
							<ul>
								{notificationCategories.map(notificationCategory => (
									<li key={notificationCategory.id}>
										<label
											className={`switch${
												subscribedNotificationCategories.includes(notificationCategory.id)
													? " active"
													: ""
											}`}
										>
											<input
												type="checkbox"
												checked={subscribedNotificationCategories.includes(
													notificationCategory.id
												)}
												onChange={() =>
													this.onNotificationCategoryToggle(notificationCategory.id)
												}
											/>
											{notificationCategory.title}
										</label>
									</li>
								))}
							</ul>
							<button
								className="apply"
								onClick={() =>
									setUserProfileFieldValue(
										"subscribedNotificationCategories",
										this.state.subscribedNotificationCategories
									)
								}
							>
								{t("apply")}
							</button>
							<Link to={getUrl("/profile")} role="button" className="cancel">
								{t("cancel")}
							</Link>
						</Fragment>
					) : (
						<Fragment>
							<p>
								{notificationCategories
									.filter(notificationCategory =>
										subscribedNotificationCategories.includes(notificationCategory.id)
									)
									.map(notificationCategory => notificationCategory.title)
									.join(", ")}
								{subscribedNotificationCategories.length === 0 && t("ProfilePanel.notifications_empty")}
							</p>
							<Link to={getUrl("/profile/notifications")} role="button" className="edit">
								{t("edit")}
							</Link>
						</Fragment>
					)}
				</div>
			</div>
		);
	};

	renderPreferredHostFacultiesLink = () => {
		const { t, getUrl, user } = this.props;
		if (!this.canUseAcademicProfile()) return null;
		return (
			<div className="categoryField">
				<h3>{t("ProfilePanel.preferredHostFaculties.title")}</h3>
				{!user.homeOrganisation ? (
					<p>{t("ProfilePanel.requirements.homeMissing")}</p>
				) : !hasHomeOrganisationWhichAllowsOutgoingApplications(user) ? (
					<p>{t("ProfilePanel.requirements.homeDoesNotAllowOutgoingApplications")}</p>
				) : !canSelectDestinations(user) ? (
					<p>{t("ProfilePanel.requirements.academicProfileNotComplete")}</p>
				) : (
					<div>
						{user.preferredHostFaculties && (
							<p>{t("ProfilePanel.preferredHostFaculties.count", user.preferredHostFaculties.length)}</p>
						)}
						{!user.preferredHostFaculties && <p>{t("ProfilePanel.field_empty")}</p>}
						<Link to={getUrl("/profile/destinations")} role="button" className="edit">
							{t("edit")}
						</Link>
					</div>
				)}
			</div>
		);
	};

	renderApplicationsLink = () => {
		const { t, getUrl, user } = this.props;
		if (!this.canUseAcademicProfile()) return null;
		return (
			<div className="categoryField">
				<h3>{t("ProfilePanel.applications")}</h3>
				{!user.homeOrganisation ? (
					<p>{t("ProfilePanel.requirements.homeMissing")}</p>
				) : !hasHomeOrganisationWhichAllowsOutgoingApplications(user) ? (
					<p>{t("ProfilePanel.requirements.homeDoesNotAllowOutgoingApplications")}</p>
				) : !canSelectDestinations(user) ? (
					<p>{t("ProfilePanel.requirements.academicProfileNotComplete")}</p>
				) : (
					<div>
						<p>{t("ProfilePanel.applications_description")}</p>
						<Link to={getUrl("/applications")} role="button" className="edit">
							{t("edit")}
						</Link>
					</div>
				)}
			</div>
		);
	};

	renderSkillsAssessmentLink = () => {
		const { t, getUrl } = this.props;
		return (
			<div className="categoryField">
				<h3>{t("ProfilePanel.skillsAssessment")}</h3>
				<div>
					<p>{t("ProfilePanel.skillsAssessment_description")}</p>
					<Link to={getUrl("/skills-assessment")} role="button" className="edit">
						{t("edit")}
					</Link>
				</div>
			</div>
		);
	};

	render() {
		const { t, getUrl, open, close, url, user } = this.props;

		// Only available to logged in users. Otherwise redirect to login page.
		if (open && !user) return <Redirect to={getLinkToLoginPage()} />;

		const isInAcademicProfile = url.startsWith("/profile/academic");
		const isInPreferredHostFaculties = url.startsWith("/profile/destinations");
		const isInApplication = url.startsWith("/profile/application");
		const isInProfile = !isInAcademicProfile && !isInPreferredHostFaculties && !isInApplication;

		let content = undefined;
		let title = undefined;
		let back = false;
		if (open && user && isInProfile) {
			title = t("ProfilePanel.title");
			content = (
				<Fragment>
					{this.renderMainInfo()}
					{this.renderLanguage()}
					{this.renderProgramme()}
					{this.renderHomeOrganisation()}
					{this.renderHostOrganisation()}
					{this.renderAcademicProfileLink()}
					{this.renderPreferredHostFacultiesLink()}
					{this.renderApplicationsLink()}
					{this.renderSkillsAssessmentLink()}
					{this.renderNotifications()}
				</Fragment>
			);
		}
		if (open && user && isInAcademicProfile) {
			title = t("ProfilePanel.academicProfile.title");
			content = renderAcademicProfilePanel(this);
			back = getUrl(getParentPath(url));
		}
		if (open && user && isInPreferredHostFaculties) {
			title = t("ProfilePanel.preferredHostFaculties.title");
			content = renderDestinationsPanel(this);
			back = getUrl(getParentPath(url));
		}
		if (open && user && isInApplication) {
			const subPanelsMetadata = {
				"/profile/application/period": {
					title: t("ProfilePanel.application.period.title"),
					requirements: () => this.state.selectedPreferredHostFacultyIds.length > 0,
					back: "/profile/destinations/preferred",
					next: "/profile/application/choices"
				},
				"/profile/application/choices": {
					title: t("ProfilePanel.application.choices.title"),
					requirements: () => this.state.selectedAcademicYearId && this.state.selectedAcademicTerm,
					back: "/profile/application/period",
					next: "/profile/application/info"
				},
				"/profile/application/info": {
					title: t("ProfilePanel.application.info.title"),
					requirements: () => this.state.orderedPreferredHostFacultyIds[0],
					back: "/profile/application/choices",
					next: "/profile/application/form"
				},
				"/profile/application/form": {
					title: t(
						!isLastApplicationUpdateReadOnly(user)
							? "ProfilePanel.application.form.title"
							: "ProfilePanel.application.view.title"
					),
					requirements: () => this.state.orderedPreferredHostFacultyIds[0],
					back: !isLastApplicationUpdateReadOnly(user) ? "/profile/application/info" : "/applications",
					next: !isLastApplicationUpdateReadOnly(user) ? "/profile/application/review" : undefined
				},
				"/profile/application/form/resume": {
					requirements: () =>
						user.lastApplicationUpdate &&
						applicationActionsPerStatus[user.lastApplicationUpdate.status] &&
						applicationActionsPerStatus[user.lastApplicationUpdate.status].includes("resume") &&
						user.lastApplicationUpdate.data,
					back: "/profile/application/form"
				},
				"/profile/application/form/view": {
					title: t("ProfilePanel.application.view.title"),
					requirements: () =>
						user.lastApplicationUpdate &&
						applicationActionsPerStatus[user.lastApplicationUpdate.status] &&
						applicationActionsPerStatus[user.lastApplicationUpdate.status].includes("view") &&
						user.lastApplicationUpdate.data,
					back: "/applications"
				},
				"/profile/application/review": {
					title:
						this.state.applicationFormSubmissionStatus === "success"
							? t("ProfilePanel.application.review.success.title")
							: t("ProfilePanel.application.review.title"),
					requirements: () =>
						this.state.orderedPreferredHostFacultyIds[0] ||
						this.state.applicationFormSubmissionStatus === "success",
					back: "/profile/application/form"
				}
			};
			const subPanelMetadata = subPanelsMetadata[url];
			if (!subPanelMetadata.requirements()) {
				console.log("Subpanel requirements not met. Redirecting to previous subpanel " + subPanelMetadata.back);
				return <Redirect to={getUrl(subPanelMetadata.back)} />;
			}
			title = subPanelMetadata.title;
			content = renderApplicationPanel(this, subPanelMetadata.next);
			back = getUrl(subPanelMetadata.back);
		}
		return (
			<SlidingPanel
				t={t}
				isVisible={open}
				title={title}
				extraCssClass="ProfilePanel"
				close={close}
				back={back}
				contentRef={this.contentRef}
			>
				{content}
			</SlidingPanel>
		);
	}
}
