import { PureComponent } from "react";
import PropTypes from "prop-types";
import Loading from "@/common/Loading.jsx";
import { userPropTypes } from "@/common/user.js";
import { uploadUserAvatar, removeUserAvatar } from "@/skeleton/DataAccess.js";
import { AVATAR_UPLOAD_ACCEPT_ATTRIBUTE, AVATAR_UPLOAD_MIME_TYPES } from "@/config.js";
import "./AvatarUploader.scss";

export default class AvatarUploader extends PureComponent {
	state = {
		loadingStatus: undefined // [undefined, "loading", "error-5xx", "error-network"]
	};

	static propTypes = {
		t: PropTypes.func.isRequired,
		user: userPropTypes,
		reloadUser: PropTypes.func.isRequired
	};

	handleXHRError = error => {
		if (error.code === "ECONNABORTED" || !window.navigator.onLine) {
			this.setState({ loadingStatus: "error-network" });
		} else {
			this.setState({ loadingStatus: "error-5xx" });
		}
	};

	onFileChanged = e => {
		const { reloadUser } = this.props;
		e.preventDefault();
		const file = e.target.files[0];
		if (file === undefined) return;

		// Do nothing if user forcefully uploaded a non allowed mime type.
		if (!AVATAR_UPLOAD_MIME_TYPES.includes(file.type)) return;

		this.fileUploadInput.value = ""; // reset the file upload input value using js
		this.setState({ loadingStatus: "loading" });
		uploadUserAvatar(file)
			.then(() => {
				reloadUser();
				this.setState({ loadingStatus: undefined });
			})
			.catch(this.handleXHRError);
	};

	onRemoveClicked = e => {
		const { t, reloadUser } = this.props;
		e.preventDefault();
		if (!window.confirm(t("AvatarUploader.remove.confirm"))) return;
		this.setState({ loadingStatus: "loading" });
		removeUserAvatar()
			.then(() => {
				reloadUser();
				this.setState({ loadingStatus: undefined });
			})
			.catch(this.handleXHRError);
	};

	closeLoading = () => this.setState({ loadingStatus: undefined });

	render() {
		const { t, user } = this.props;
		const { loadingStatus } = this.state;
		return (
			<div className="AvatarUploader">
				{loadingStatus && <Loading t={t} status={loadingStatus} close={this.closeLoading} />}

				<label className="edit">
					{t("AvatarUploader.update")}
					<input
						className="upload"
						name="upload" // ERA-255
						type="file"
						accept={AVATAR_UPLOAD_ACCEPT_ATTRIBUTE}
						onChange={this.onFileChanged}
						ref={node => (this.fileUploadInput = node)}
					/>
				</label>

				{user.picture && (
					<button className="remove" onClick={this.onRemoveClicked}>
						{t("AvatarUploader.remove")}
					</button>
				)}
			</div>
		);
	}
}
