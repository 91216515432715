import PropTypes from "prop-types";
import { dealPropTypes, dealStreamPropTypes, dealMapPropTypes } from "@/nodes/Deal.jsx";
import { eventPropTypes, eventStreamPropTypes, eventMapPropTypes } from "@/nodes/Event.jsx";
import { faqPropTypes, faqStreamPropTypes } from "@/nodes/Faq.jsx";
import { organisationPropTypes, organisationStreamPropTypes, organisationMapPropTypes } from "@/nodes/Organisation.jsx";
import { pagePropTypes, pageStreamPropTypes } from "@/nodes/Page.jsx";
import { servicePropTypes, serviceStreamPropTypes } from "@/nodes/Service.jsx";
import { storyPropTypes, storyStreamPropTypes, storyMapPropTypes } from "@/nodes/Story.jsx";
import { tipPropTypes, tipStreamPropTypes, tipMapPropTypes } from "@/nodes/Tip.jsx";
import { usefulInformationPropTypes, usefulInformationStreamPropTypes } from "@/nodes/UsefulInformation.jsx";

export const nodePropTypes = PropTypes.oneOfType([
	dealPropTypes,
	eventPropTypes,
	faqPropTypes,
	organisationPropTypes,
	pagePropTypes,
	servicePropTypes,
	storyPropTypes,
	tipPropTypes,
	usefulInformationPropTypes
]);

export const streamNodePropTypes = PropTypes.oneOfType([
	dealStreamPropTypes,
	eventStreamPropTypes,
	faqStreamPropTypes,
	organisationStreamPropTypes,
	pageStreamPropTypes,
	serviceStreamPropTypes,
	storyStreamPropTypes,
	tipStreamPropTypes,
	usefulInformationStreamPropTypes
]);

export const mapNodePropTypes = PropTypes.oneOfType([
	dealMapPropTypes,
	eventMapPropTypes,
	organisationMapPropTypes,
	storyMapPropTypes,
	tipMapPropTypes
]);
