import { PureComponent } from "react";
import PropTypes from "prop-types";
import history from "@/skeleton/history.js";
import {
	isExternalLink,
	isCmsLink,
	isStaticAssetLink,
	isAnchorLink,
	isMailLink,
	isTelLink,
	applyExpandableHtmlLogic,
	getClickedAnchor
} from "@/common/utils.jsx";
import "./ClickableDrupalContent.scss";
import { BACKEND_DOMAIN, RUNS_THROUGH_REACT_NATIVE_APP } from "@/config.js";

/**
 * This component renders rich text (HTML) which comes directly from Drupal.
 * This is obviously unsafe (hence the usage of dangerouslySetInnerHTML), bypasses
 * react and depends on the Drupal content managers doing a good job.
 *
 * We need to be doing some JS enhancements/operations on that rich text, and since
 * we do not parse the contents of that rich text in order to "translate" it to
 * react components, we do all of them using JS on top of the already rendered HTML.
 * We currently:
 * - Handle clicks to external or internal content.
 */
export default class ClickableDrupalContent extends PureComponent {
	static propTypes = {
		content: PropTypes.string.isRequired,
		className: PropTypes.string
	};

	handleClick = e => {
		const anchor = getClickedAnchor(e.target);
		// Only handle clicks on "a" tags.
		if (!anchor) return;

		// Handle various types of links.
		const href = anchor.getAttribute("href");

		// These should be handled by browser.
		if (isExternalLink(href)) return;
		if (isCmsLink(href)) return;
		if (isStaticAssetLink(href)) return;
		if (isAnchorLink(href)) return;
		if (isMailLink(href)) return;
		if (isTelLink(href)) return;

		// Link to react which should be handled by react.
		e.preventDefault();
		history.push(href);
	};

	componentDidMount() {
		if (RUNS_THROUGH_REACT_NATIVE_APP) this.rewriteCmsLinks();
		this.enhanceExpandablesContent();
	}

	componentDidUpdate() {
		if (RUNS_THROUGH_REACT_NATIVE_APP) this.rewriteCmsLinks();
		this.enhanceExpandablesContent();
	}

	/**
	 * Mutates the rendered HTML content and adapts all anchors and images pointing
	 * to the CMS path from using a root relative path to an absolute one. This
	 * is needed for when serving this app through the react native shell which
	 * serves it through http://localhost/.
	 */
	rewriteCmsLinks = () => {
		// Adapt a href.
		this.domNode
			.querySelectorAll("a")
			.forEach(
				link =>
					isCmsLink(link.getAttribute("href")) &&
					link.setAttribute("href", BACKEND_DOMAIN + link.getAttribute("href"))
			);
		// Adapt image src.
		this.domNode
			.querySelectorAll("img")
			.forEach(
				img =>
					isCmsLink(img.getAttribute("src")) &&
					img.setAttribute("src", BACKEND_DOMAIN + img.getAttribute("src"))
			);
		// Adapt picture source srcSet.
		this.domNode.querySelectorAll("source[srcSet]").forEach(source =>
			source.setAttribute(
				"srcSet",
				source
					.getAttribute("srcSet")
					.split(", ")
					.map(srcSetElement => BACKEND_DOMAIN + srcSetElement)
					.join(", ")
			)
		);
	};

	/**
	 * Mutates the rendered HTML content and enhances all expandables, adding
	 * appropriate classes and aria attributes.
	 */
	enhanceExpandablesContent = () => {
		this.domNode.querySelectorAll(".expandable").forEach(expandable => applyExpandableHtmlLogic(expandable));
	};

	render() {
		const { content, className } = this.props;
		return (
			<div
				className={"ClickableDrupalContent" + (className ? " " + className : "")}
				onClick={this.handleClick}
				ref={node => (this.domNode = node)}
				dangerouslySetInnerHTML={{ __html: content }}
			/>
		);
	}
}
