import { PureComponent } from "react";
import PropTypes from "prop-types";
import ClickableDrupalContent from "@/common/ClickableDrupalContent.jsx";
import "./UsefulInformationReferences.scss";

export const usefulInformationReferencesPropTypes = PropTypes.arrayOf(
	PropTypes.exact({
		id: PropTypes.number.isRequired,
		type: PropTypes.oneOf(["useful_information"]).isRequired,
		title: PropTypes.string.isRequired,
		alias: PropTypes.string.isRequired,
		body: PropTypes.string.isRequired
	})
);

export default class UsefulInformationReferences extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		usefulInformation: usefulInformationReferencesPropTypes
	};

	render() {
		const { t, usefulInformation } = this.props;
		if (!usefulInformation || !usefulInformation.length) return null;
		return (
			<div className="UsefulInformationReferences">
				<h2>{t("UsefulInformationReferences.label")}</h2>
				<ul>
					{usefulInformation.map((node, index) => (
						<li key={index}>
							<h3>{node.title}</h3>
							<ClickableDrupalContent content={node.body} />
						</li>
					))}
				</ul>
			</div>
		);
	}
}
