import axios from "axios";

export const loadAccommodationInventory = (
	city,
	housingTypes,
	qualityLabels,
	bedroomCountMin,
	bedroomCountMax,
	totalSizeMin,
	totalSizeMax,
	rentMin,
	rentMax,
	sortBy,
	sortOrder,
	page
) => {
	return axios
		.get("api/accommodation/inventory", {
			params: {
				city,
				housingType: housingTypes,
				qualityLabels,
				bedroomCountFrom: bedroomCountMin,
				bedroomCountTo: bedroomCountMax,
				totalSizeFrom: totalSizeMin,
				totalSizeTo: totalSizeMax,
				rentFrom: rentMin !== undefined ? rentMin * 100 : undefined, // UI is in euros, but backend uses eurocents.
				rentTo: rentMax !== undefined ? rentMax * 100 : undefined, // UI is in euros, but backend uses eurocents.
				sortBy,
				sortOrder,
				page
			}
		})
		.then(result => result.data);
};

export const loadAccommodationQualityLabels = () => {
	return axios.get("api/accommodation/quality-labels").then(result => result.data);
};

export const loadAccommodationProviders = () => {
	return axios.get("api/accommodation/providers").then(result => result.data);
};

export const trackAccommodationModuleOpen = () => {
	return axios.post("api/accommodation/metrics/module/open").then(result => result.data);
};

export const trackAccommodationProviderOpen = providerName => {
	return axios
		.post("api/accommodation/metrics/provider/open/" + encodeURIComponent(providerName))
		.then(result => result.data);
};
