import { PureComponent } from "react";
import PropTypes from "prop-types";

export default class WeShareWeCare extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired
	};

	render() {
		const { t } = this.props;

		return (
			<div className="WeShareWeCare">
				<div className="content">
					<h2>
						<a href="https://wesharewecare.eu/" rel="external noopener noreferrer">
							{t("Accommodation.WeShareWeCare.title")}
						</a>
					</h2>
					<p>
						{t.map(
							"Accommodation.WeShareWeCare.info",
							{},
							{
								_LABEL_: <strong>{t("Accommodation.WeShareWeCare.label")}</strong>
							}
						)}
					</p>
				</div>
			</div>
		);
	}
}
