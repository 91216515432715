import { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import DateRange from "@/common/DateRange.jsx";

export const eventsReferencesPropTypes = PropTypes.arrayOf(
	PropTypes.exact({
		id: PropTypes.number.isRequired,
		type: PropTypes.oneOf(["event"]).isRequired,
		title: PropTypes.string.isRequired,
		alias: PropTypes.string.isRequired,
		startDate: PropTypes.number.isRequired,
		endDate: PropTypes.number.isRequired
	})
);

export default class EventReferences extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		events: eventsReferencesPropTypes
	};

	render() {
		const { t, getUrl, events } = this.props;
		if (!events || !events.length) return null;
		return (
			<div className="EventReferences">
				<h2>{t("EventReferences.current.label")}</h2>
				<ul>
					{events.map((node, index) => (
						<li key={index} className="mini-teaser">
							<h3>
								<Link to={getUrl(node.alias)}>{node.title}</Link>
							</h3>
							<span aria-hidden="true" className={"ct_" + node.type}>
								{t("NodeType." + node.type)}
							</span>
							<DateRange t={t} startDate={node.startDate} endDate={node.endDate} />
						</li>
					))}
				</ul>
			</div>
		);
	}
}
