import { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import SlidingPanel from "@/common/SlidingPanel.jsx";
import DocumentMeta from "@/common/DocumentMeta.js";
import { userPropTypes } from "@/common/user.js";
import Likes from "@/common/Likes.jsx";
import ShareButton from "@/common/ShareButton.jsx";
import Deal from "@/nodes/Deal.jsx";
import Event from "@/nodes/Event.jsx";
import Faq from "@/nodes/Faq.jsx";
import Organisation from "@/nodes/Organisation.jsx";
import Page from "@/nodes/Page.jsx";
import Service from "@/nodes/Service.jsx";
import Story from "@/nodes/Story.jsx";
import Tip from "@/nodes/Tip.jsx";
import UsefulInformation from "@/nodes/UsefulInformation.jsx";
import { nodePropTypes } from "@/nodes/PropTypes.jsx";
import "./NodePanel.scss";
import { markNodeVisited, unmarkNodeVisited, handleError, deleteNode } from "@/skeleton/DataAccess.js";
import history from "@/skeleton/history.js";

const shouldShowBackButton = node => {
	if (node && node.type === "page" && node.skillsAssessment) return false;
	return true;
};

export default class NodePanel extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		user: userPropTypes,
		open: PropTypes.bool.isRequired,
		node: nodePropTypes,
		close: PropTypes.string.isRequired,
		onNodeLikesChange: PropTypes.func.isRequired,
		onNodeVisitedChange: PropTypes.func.isRequired,
		onNodeDeleted: PropTypes.func.isRequired
	};

	state = {
		markStatus: undefined // [undefined, 'pending']
	};

	onMarkVisitedClicked = () => {
		const { t, node, onNodeVisitedChange } = this.props;
		this.setState({ markStatus: "pending" });
		markNodeVisited(node.alias)
			.then(() => {
				this.setState({ markStatus: undefined });
				onNodeVisitedChange({ visited: true });
			})
			.catch(error => {
				this.setState({ markStatus: undefined });
				handleError(t, error);
			});
	};

	onUnmarkVisitedClicked = () => {
		const { t, node, onNodeVisitedChange } = this.props;
		this.setState({ markStatus: "pending" });
		unmarkNodeVisited(node.alias)
			.then(() => {
				this.setState({ markStatus: undefined });
				onNodeVisitedChange({ visited: false });
			})
			.catch(error => {
				this.setState({ markStatus: undefined });
				handleError(t, error);
			});
	};

	getMarkNodeElement = () => {
		const { t, user, node } = this.props;
		const { markStatus } = this.state;

		if (!user) return null; // Anonymous.
		if (node.visited === undefined) return; // No ability to mark/unmark visited.
		return (
			<div className="visited">
				{node.visited ? (
					<button
						className="button unmark"
						onClick={this.onUnmarkVisitedClicked}
						disabled={markStatus === "pending"}
					>
						{t("NodePanel.visited.unmark")}
					</button>
				) : (
					<button
						className="button mark"
						onClick={this.onMarkVisitedClicked}
						disabled={markStatus === "pending"}
					>
						{t("NodePanel.visited.mark")}
					</button>
				)}
			</div>
		);
	};

	onDeleteClicked = () => {
		const { t, node, close, onNodeDeleted } = this.props;
		if (!window.confirm(t("delete.prompt." + node.type))) return;
		deleteNode(node.id)
			.then(() => {
				onNodeDeleted();
				history.push(close);
			})
			.catch(error => {
				handleError(t, error);
			});
	};

	getDeleteNodeElement = () => {
		const { t, user, node } = this.props;
		// debugger;
		if (!user) return null; // Anonymous.
		if (!node.author) return; // Content doesn't have an author (may have been deleted).
		if (node.author.id !== user.id) return; // Not own content. Will be checked on backend as well for security.
		return (
			<div className="delete">
				<button className="button" onClick={this.onDeleteClicked}>
					{t("delete")}
				</button>
			</div>
		);
	};

	render() {
		const { t, getUrl, user, open, node, close, onNodeLikesChange } = this.props;

		let content;
		let extraCssClass = "NodePanel";
		if (open && node) {
			extraCssClass += ` ct_${node.type}`;
			const mark = this.getMarkNodeElement();
			const deleteNodeElement = this.getDeleteNodeElement();
			// Use key={node.id} to prevent changing node content of same type within the same component.
			// This prevents displaying a stale HTML img within the new node.
			content = (
				<Fragment key={node.id}>
					<DocumentMeta description={node.summary} />
					{node.type === "deal" && (
						<Deal t={t} getUrl={getUrl} user={user} node={node} markNodeElement={mark} />
					)}
					{node.type === "event" && (
						<Event t={t} getUrl={getUrl} user={user} node={node} markNodeElement={mark} />
					)}
					{node.type === "faq" && (
						<Faq t={t} getUrl={getUrl} user={user} node={node} markNodeElement={mark} />
					)}
					{node.type === "organisation" && (
						<Organisation t={t} getUrl={getUrl} user={user} node={node} markNodeElement={mark} />
					)}
					{node.type === "page" && (
						<Page t={t} getUrl={getUrl} user={user} node={node} markNodeElement={mark} />
					)}
					{node.type === "service" && (
						<Service t={t} getUrl={getUrl} user={user} node={node} markNodeElement={mark} />
					)}
					{node.type === "story" && (
						<Story
							t={t}
							getUrl={getUrl}
							user={user}
							node={node}
							markNodeElement={mark}
							deleteNodeElement={deleteNodeElement}
						/>
					)}
					{node.type === "tip" && (
						<Tip
							t={t}
							getUrl={getUrl}
							user={user}
							node={node}
							markNodeElement={mark}
							deleteNodeElement={deleteNodeElement}
						/>
					)}
					{node.type === "useful_information" && (
						<UsefulInformation t={t} getUrl={getUrl} user={user} node={node} markNodeElement={mark} />
					)}
				</Fragment>
			);
		}
		return (
			<SlidingPanel
				t={t}
				isVisible={open && node !== undefined}
				title={node ? node.title : undefined}
				extraCssClass={extraCssClass}
				close={close}
				back={shouldShowBackButton(node)}
				extraFooterElement={
					node ? (
						<Fragment>
							<Likes t={t} user={user} node={node} onNodeLikesChange={onNodeLikesChange} />
							<ShareButton t={t} />
						</Fragment>
					) : undefined
				}
				language={node ? node.language : undefined}
			>
				{content}
			</SlidingPanel>
		);
	}
}
