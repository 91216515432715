import { PureComponent } from "react";
import PropTypes from "prop-types";
import Term from "@/common/Term.js";
import "./Meta.scss";

export default class Meta extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		item: PropTypes.object.isRequired // Can be node or notification. // old comment: Should have used nodePropTypes from nodes/PropTypes.js but: Uncaught ReferenceError: Cannot access 'dealPropTypes' before initialization
	};

	render() {
		const { t, item } = this.props;
		if (!item.tags && !item.faqTypes && !item.serviceCategory && !item.category) return null;
		return (
			<div className="Meta">
				<h2>{t("Meta.heading")}</h2>
				<ul>
					{item.tags &&
						item.tags.map((term, index) => (
							<li key={index}>
								<span className="label">{t("Meta.label.tag")}</span>
								<span className="tag">
									<Term term={term} />
								</span>
							</li>
						))}
					{item.faqTypes &&
						item.faqTypes.map((term, index) => (
							<li key={index}>
								<span className="label">{t("Meta.label.faq")}</span>
								<span className="faq">
									<Term term={term} />
								</span>
							</li>
						))}
					{item.serviceCategory && (
						<li>
							<span className="label">{t("Meta.label.service")}</span>
							<span className="service">
								<Term term={item.serviceCategory} />
							</span>
						</li>
					)}
					{item.category && (
						<li>
							<span className="label">{t("Meta.label.category")}</span>
							<span className="category">
								<Term term={item.category} />
							</span>
						</li>
					)}
				</ul>
			</div>
		);
	}
}
