import { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { isExternalLink } from "@/common/utils.jsx";
import { Link } from "react-router-dom";

const renderMenu = (elem, getUrl) =>
	Array.isArray(elem) ? (
		<ul>
			{elem.map((menuItem, index) => (
				<li key={index}>{renderMenu(menuItem, getUrl)}</li>
			))}
		</ul>
	) : (
		<Fragment>
			{isExternalLink(elem.url) ? (
				<a href={elem.url} rel="external noopener noreferrer">
					{elem.name}
				</a>
			) : elem.url ? (
				<Link to={getUrl(elem.url)}>{elem.name}</Link>
			) : (
				<span>{elem.name}</span>
			)}
			{elem.children && elem.children.length > 0 && renderMenu(elem.children, getUrl)}
		</Fragment>
	);

export const menuPropTypes = PropTypes.arrayOf(
	PropTypes.exact({
		name: PropTypes.string.isRequired,
		url: PropTypes.string,
		children: PropTypes.arrayOf(PropTypes.object) // Should have been PropTypes.arrayOf(menuPropTypes) actually.
	})
);

export default class Menu extends PureComponent {
	static propTypes = {
		menu: menuPropTypes,
		getUrl: PropTypes.func.isRequired
	};

	render() {
		const { menu, getUrl } = this.props;

		// Do not render component if there is no menu at all.
		if (!menu || !menu.length) return null;

		return (
			<nav role="navigation" className="Menu">
				{renderMenu(menu, getUrl)}
			</nav>
		);
	}
}
