import { PureComponent } from "react";
import PropTypes from "prop-types";
import "./Stream.scss";
import { DealTeaser } from "@/nodes/Deal.jsx";
import { EventTeaser } from "@/nodes/Event.jsx";
import { FaqTeaser } from "@/nodes/Faq.jsx";
import { OrganisationTeaser } from "@/nodes/Organisation.jsx";
import { PageTeaser } from "@/nodes/Page.jsx";
import { ServiceTeaser } from "@/nodes/Service.jsx";
import { StoryTeaser } from "@/nodes/Story.jsx";
import { TipTeaser } from "@/nodes/Tip.jsx";
import { UsefulInformationTeaser } from "@/nodes/UsefulInformation.jsx";
import { userPropTypes } from "@/common/user.js";
import Likes from "@/common/Likes.jsx";
import Meta from "@/common/Meta.jsx";
import NotificationPublishingMeta from "@/common/NotificationPublishingMeta.jsx";
import Loading, { statusPropTypes as loadingStatusPropTypes } from "@/common/Loading.jsx";
import { streamNodePropTypes } from "@/nodes/PropTypes.jsx";
import MagicGridWrapper from "@/common/MagicGridWrapper.jsx";
import { Link } from "react-router-dom";
import { termPropTypes } from "@/common/Term.js";
import ECSupportFooter from "@/common/ECSupportFooter.jsx";

export default class Stream extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		user: userPropTypes,
		stream: PropTypes.exact({
			ids: PropTypes.arrayOf(PropTypes.number).isRequired,
			nodes: PropTypes.arrayOf(streamNodePropTypes).isRequired,
			page: PropTypes.number.isRequired,
			nodesPerPage: PropTypes.number.isRequired,
			loadingStatus: loadingStatusPropTypes,
			filters: PropTypes.exact({
				city: termPropTypes
			}).isRequired,
			type: PropTypes.oneOf(["stream", "liked", "my-content", "search"]).isRequired
		}).isRequired,
		loadMore: PropTypes.func.isRequired,
		closeLoadMore: PropTypes.func.isRequired,
		onNodeLikesChange: PropTypes.func.isRequired,
		notificationCategories: PropTypes.arrayOf(termPropTypes)
	};

	hasMore = () =>
		this.props.stream.page * this.props.stream.nodesPerPage + this.props.stream.nodesPerPage <
		this.props.stream.ids.length;

	componentDidMount() {
		// Infinite scroll (auto load as soon as "more button" is visible).
		new IntersectionObserver(entries =>
			entries.filter(entry => entry.isIntersecting).forEach(() => this.moreButtonDomNode.click())
		).observe(this.moreButtonDomNode);
	}

	render() {
		const { t, getUrl, user, stream, loadMore, closeLoadMore, onNodeLikesChange, notificationCategories } =
			this.props;

		const pendingNotificationsForRendering =
			user && stream.type === "stream" // Only if there is a user and we are on stream (e.g the "liked" stream should not display notifications).
				? [...user.notifications] // Clone array so we can safely start popping them from the start for rendering.
				: [];

		// The following 4 functions allow rendering of notifications within the nodes stream.
		// This, of course, requires some acrobatics, and also affects the amount of items per page we display.
		const renderNextNotification = () => {
			const notification = pendingNotificationsForRendering.shift();

			// The notification we have doesn't contain the full category. Resolve it from terms.
			notification.category = notificationCategories.find(category => category.id === notification.categoryId);
			return (
				<article
					className={`notification${notification.read ? "" : " unread"}`}
					key={`notification-${notification.id}`}
				>
					<div>
						<NotificationPublishingMeta t={t} notification={notification} />
						<div className="content">
							<h3>
								<Link to={getUrl("/notification/" + notification.id)}>{notification.title}</Link>
							</h3>
						</div>
						<footer>
							<Meta t={t} item={notification} />
						</footer>
					</div>
				</article>
			);
		};

		const shouldRenderNextNotificationBeforeNode = nextNode => {
			const nextNotification = pendingNotificationsForRendering[0];
			// No more notifications.
			if (nextNotification === undefined) return false;
			// Do not render before a stick node.
			if (nextNode.sticky) return false;
			// Do not render before a more recent node.
			if (nextNotification.created < nextNode.created) {
				return false;
			}
			// Render.
			return true;
		};

		const renderNextNotificationsBeforeNode = nextNode => {
			const result = [];
			while (shouldRenderNextNotificationBeforeNode(nextNode)) result.push(renderNextNotification());
			return result;
		};

		const renderRemainingNotifications = () => {
			const result = [];
			while (pendingNotificationsForRendering.length) result.push(renderNextNotification());
			return result;
		};

		const renderItems = () =>
			stream.nodes.map(node => {
				const likes = <Likes t={t} user={user} node={node} onNodeLikesChange={onNodeLikesChange} />;
				return [
					...renderNextNotificationsBeforeNode(node),
					<article key={`node-${node.id}`} className={`ct_${node.type}${node.sticky ? " pinned" : ""}`}>
						<div>
							{node.type === "deal" && (
								<DealTeaser t={t} getUrl={getUrl} user={user} node={node} likesElement={likes} />
							)}
							{node.type === "event" && (
								<EventTeaser t={t} getUrl={getUrl} user={user} node={node} likesElement={likes} />
							)}
							{node.type === "faq" && (
								<FaqTeaser t={t} getUrl={getUrl} user={user} node={node} likesElement={likes} />
							)}
							{node.type === "organisation" && (
								<OrganisationTeaser
									t={t}
									getUrl={getUrl}
									user={user}
									node={node}
									likesElement={likes}
								/>
							)}
							{node.type === "page" && (
								<PageTeaser t={t} getUrl={getUrl} user={user} node={node} likesElement={likes} />
							)}
							{node.type === "service" && (
								<ServiceTeaser t={t} getUrl={getUrl} user={user} node={node} likesElement={likes} />
							)}
							{node.type === "story" && (
								<StoryTeaser t={t} getUrl={getUrl} user={user} node={node} likesElement={likes} />
							)}
							{node.type === "tip" && (
								<TipTeaser t={t} getUrl={getUrl} user={user} node={node} likesElement={likes} />
							)}
							{node.type === "useful_information" && (
								<UsefulInformationTeaser
									t={t}
									getUrl={getUrl}
									user={user}
									node={node}
									likesElement={likes}
								/>
							)}
						</div>
					</article>
				];
			});

		return (
			<div className="Stream">
				<h2>{t("Stream.heading")}</h2>
				{(stream.nodes.length > 0 || pendingNotificationsForRendering.length > 0) && (
					<div className="feed" role="feed">
						<MagicGridWrapper static animate={false} gutter={0}>
							{renderItems()}
							{(stream.nodes.length === 0 || (stream.nodes.length > 0 && !this.hasMore())) &&
								renderRemainingNotifications()}
						</MagicGridWrapper>
					</div>
				)}
				<button
					className="button"
					onClick={loadMore}
					disabled={stream.loadingStatus === "loading"}
					/* hide using CSS instead of removing from dom, so this.moreButtonDomNode is always valid */
					style={this.hasMore() ? undefined : { display: "none" }}
					ref={node => (this.moreButtonDomNode = node)}
				>
					{t(stream.loadingStatus === "loading" ? "Loading.loading" : "more")}
				</button>
				{stream.nodes.length === 0 && <p className="empty">{t("Stream.stream.empty")}</p>}
				{stream.type === "stream" && stream.nodes.length > 0 && !this.hasMore() && (
					<p className="ending">
						{t("Stream.stream.ending.header")}
						<strong>{t("Stream.stream.ending.subheader")}</strong>
					</p>
				)}
				{stream.loadingStatus && (
					<Loading t={t} status={stream.loadingStatus} retry={loadMore} close={closeLoadMore} />
				)}
				<ECSupportFooter t={t} />
			</div>
		);
	}
}
