import { PureComponent } from "react";
import PropTypes from "prop-types";

export default class QualityLabels extends PureComponent {
	static propTypes = {
		qualityLabels: PropTypes.array.isRequired
	};

	render() {
		const { qualityLabels } = this.props;
		return (
			<div className="QualityLabels">
				<ul>
					{qualityLabels.map((item, index) => (
						<li key={item.name + "-" + index}>
							{item.icon !== null && <img alt={item.name} src={item.icon} />}
							<div>
								<h2>{item.name}</h2>
								<p>{item.criteria}</p>
							</div>
						</li>
					))}
				</ul>
			</div>
		);
	}
}
