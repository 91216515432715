import { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import "./LivingCostVisualisation.scss";
import Autosuggest from "react-autosuggest";
import { loadCities } from "@/skeleton/DataAccess.js";
import history from "@/skeleton/history.js";
import { termPropTypes } from "@/common/Term.js";
import { Link } from "react-router-dom";

export const livingCostDataPropTypes = PropTypes.oneOfType([
	PropTypes.bool, // false for 404
	PropTypes.exact({
		city: termPropTypes.isRequired,
		cityLivingCost: PropTypes.exact({
			monthlyLivingCosts: PropTypes.number.isRequired,
			accommodationCosts: PropTypes.number.isRequired,
			count: PropTypes.number.isRequired,
			months: PropTypes.number.isRequired
		}),
		cheaperCities: PropTypes.arrayOf(termPropTypes),
		globalLivingCost: PropTypes.exact({
			monthlyLivingCosts: PropTypes.number.isRequired,
			accommodationCosts: PropTypes.number.isRequired
		})
	})
]);

const getCssWidth = (values, index) => Math.round((values[index] / Math.max(...values)) * 100) + "%";

export default class LivingCostVisualisation extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		data: livingCostDataPropTypes
	};

	state = {
		autosuggestValue: "",
		autosuggestItems: []
	};

	componentDidMount() {
		this.fixBarValuePlacement();
		window.addEventListener("resize", this.fixBarValuePlacement);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.fixBarValuePlacement);
	}

	fixBarValuePlacement = () => {
		this.domNode.querySelectorAll(".bar").forEach(bar => {
			const barWidth = bar.offsetWidth;
			const valueWidth = bar.querySelector(".value").offsetWidth;
			if (barWidth < valueWidth) {
				bar.classList.add("value-out");
			} else {
				bar.classList.remove("value-out");
			}
		});
	};

	setCitySearchParameter = city => {
		history.replace(this.props.getUrl(undefined, { c: city ? city.id : undefined }));
	};

	componentDidUpdate(prevProps) {
		this.fixBarValuePlacement();
		if (prevProps.data !== this.props.data) this.setState({ autosuggestValue: "" });
	}

	render() {
		const { t, getUrl, data } = this.props;
		const { autosuggestValue, autosuggestItems } = this.state;

		return (
			<Fragment>
				{!data && <p className="message">{t("LivingCostVisualisation.intro")}</p>}
				{data && data.city && <h3>{data.city.title}</h3>}
				{data && data.city && !data.cityLivingCost && (
					<p className="message warning">{t("LivingCostVisualisation.empty")}</p>
				)}
				<div className="LivingCostVisualisation" ref={node => (this.domNode = node)}>
					{data && data.city && data.cityLivingCost && (
						<div className="cityLivingCost">
							<h4>{t("LivingCostVisualisation.monthlyLivingCosts")}</h4>
							<div className="comparison">
								<div className="name">
									<span>{data.city.title}</span>
								</div>
								<div
									className="bar"
									style={{
										width: getCssWidth(
											[
												data.cityLivingCost.monthlyLivingCosts,
												data.globalLivingCost.monthlyLivingCosts
											],
											0
										)
									}}
								>
									<span className="value">&euro;{data.cityLivingCost.monthlyLivingCosts}</span>
								</div>
								<div className="name average">{t("LivingCostVisualisation.average")}</div>
								<div
									className="bar average"
									style={{
										width: getCssWidth(
											[
												data.cityLivingCost.monthlyLivingCosts,
												data.globalLivingCost.monthlyLivingCosts
											],
											1
										)
									}}
								>
									<span className="value">&euro;{data.globalLivingCost.monthlyLivingCosts}</span>
								</div>
							</div>

							<h4>{t("LivingCostVisualisation.accommodationCosts")}</h4>
							<div className="comparison accommodation">
								<div className="name">
									<span>{data.city.title}</span>
								</div>
								<div
									className="bar"
									style={{
										width: getCssWidth(
											[
												data.cityLivingCost.accommodationCosts,
												data.globalLivingCost.accommodationCosts
											],
											0
										)
									}}
								>
									<span className="value">&euro;{data.cityLivingCost.accommodationCosts}</span>
								</div>
								<div className="name average">{t("LivingCostVisualisation.average")}</div>
								<div
									className="bar average"
									style={{
										width: getCssWidth(
											[
												data.cityLivingCost.accommodationCosts,
												data.globalLivingCost.accommodationCosts
											],
											1
										)
									}}
								>
									<span className="value">&euro;{data.globalLivingCost.accommodationCosts}</span>
								</div>
							</div>

							<div className="summary">
								<div className="count">
									<h4>{t("LivingCostVisualisation.count")}</h4>
									<span>{data.cityLivingCost.count}</span>
								</div>

								<div className="months">
									<h4>{t("LivingCostVisualisation.months_label")}</h4>
									<span>
										{t("LivingCostVisualisation.months", { months: data.cityLivingCost.months })}
									</span>
								</div>
							</div>
						</div>
					)}
					<div className="autosuggestWrapper">
						<h3>{t("Filters.city_prompt")}</h3>
						<div>
							<Autosuggest
								suggestions={autosuggestItems}
								onSuggestionsFetchRequested={({ value }) =>
									loadCities(value).then(cities => this.setState({ autosuggestItems: cities }))
								}
								onSuggestionsClearRequested={() => this.setState({ autosuggestItems: [] })}
								onSuggestionSelected={(event, { suggestion }) => {
									this.setState({ autosuggestValue: suggestion.title });
									this.setCitySearchParameter(suggestion);
								}}
								getSuggestionValue={city => city.title}
								renderSuggestion={city => city.title}
								inputProps={{
									placeholder: t("Filters.city_placeholder"),
									value: autosuggestValue,
									onChange: (event, { newValue }) => this.setState({ autosuggestValue: newValue }),
									maxLength: 32
								}}
							/>
						</div>
					</div>
					{data && data.cheaperCities && data.cheaperCities.length > 0 && (
						<div className="cheaperCities">
							<h3>{t("LivingCostVisualisation.cheaperCities")}</h3>
							<ul>
								{data.cheaperCities.map((city, index) => (
									<li key={index}>
										<Link to={getUrl(undefined, { c: city.id })} className="city">
											{city.title}
										</Link>
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			</Fragment>
		);
	}
}
