import { PureComponent } from "react";
import PropTypes from "prop-types";

export default class LocaleSwitcher extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		locale: PropTypes.string.isRequired,
		setLocale: PropTypes.func.isRequired,
		languages: PropTypes.arrayOf(
			PropTypes.exact({
				code: PropTypes.string.isRequired,
				title: PropTypes.string.isRequired
			})
		).isRequired
	};

	render() {
		const { t, locale, setLocale, languages } = this.props;
		return (
			<div className="LocaleSwitcher">
				<select aria-label={t("LocaleSwitcher")} value={locale} onChange={e => setLocale(e.target.value)}>
					{languages.map(language => (
						<option key={language.code} value={language.code}>
							{language.title}
						</option>
					))}
				</select>
			</div>
		);
	}
}
