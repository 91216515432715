import { Fragment } from "react";
import { BACKEND_PATH, DEFAULT_LANGUAGE } from "@/config.js";

/**
 * Returns a human readable representation of the specified bytes file size.
 * Only supports byte, KB, MB and GB units since this is a webapp. To be 100%
 * correct we'd need to output byte, KiB, MiB and GiB, but the average web user
 * is not yet familiar with those units.
 */
export function getHumanReadableFileSize(bytes) {
	if (typeof bytes !== "number" || isNaN(bytes)) return "";
	if (bytes < 1024) return `${bytes} bytes`;
	if (bytes < 1024 * 1024) return `${parseFloat((bytes / 1024).toFixed(1))} KB`;
	if (bytes < 1024 * 1024 * 1024) return `${parseFloat((bytes / 1024 / 1024).toFixed(1))} MB`;
	return `${parseFloat((bytes / 1024 / 1024 / 1024).toFixed(1))} GB`;
}

/**
 * Ease-in-out parametric as shown in https://stackoverflow.com/a/25730573/72478
 * For specified value between 0 and 1 the result will be between 0 and 1.
 */
export const easeInOutParametric = value => {
	const squareValue = value * value;
	return squareValue / (2 * (squareValue - value) + 1);
};

/**
 * Will toggle animate the height of an element.
 * Animating an element's height from 0 to auto is not fully possible via CSS.
 *
 * Demo: https://codesandbox.io/s/css-height-auto-animation-8jgbz
 */
export const toggleHeight = (element, duration = 300, easing = easeInOutParametric) => {
	const now = Date.now();

	// An existing animation is in place.
	// Change params and let it finish the job.
	if (element.dataset.start) {
		const start = Number(element.dataset.start);
		const end = Number(element.dataset.end);
		const direction = Number(element.dataset.direction);
		element.dataset.end = now - start + now;
		element.dataset.start = now - (end - now);
		element.dataset.direction = -direction;
		return;
	}

	// Fresh animation.
	element.dataset.end = now + duration;
	element.dataset.start = now;
	element.dataset.direction = element.clientHeight === 0 ? +1 : -1;
	const calculateAndApplyNextFrame = () => {
		const now = Date.now();
		const start = Number(element.dataset.start);
		const end = Number(element.dataset.end);
		const direction = Number(element.dataset.direction);
		// Animation is done.
		if (now > end) {
			if (direction === 1) {
				expandToFinalHeight(element);
			} else {
				collapseToFinalHeight(element);
			}
			delete element.dataset.end;
			delete element.dataset.start;
			delete element.dataset.direction;
			return;
		}
		const frame =
			direction === 1 ? easing((now - start) / (end - start)) : 1 - easing((now - start) / (end - start));
		element.style.display = "block";
		element.style.height = element.scrollHeight * frame + "px";
		element.style.overflow = "hidden";
		window.requestAnimationFrame(calculateAndApplyNextFrame);
	};
	window.requestAnimationFrame(calculateAndApplyNextFrame);
};

/**
 * See #toggleHeight().
 */
const expandToFinalHeight = element => {
	element.style.display = "block";
	element.style.height = "auto";
	element.style.overflow = "visible";
};

/**
 * See #toggleHeight().
 */
const collapseToFinalHeight = element => {
	element.style.display = "none"; // Display none so links/buttons within are not focusable via tab.
	element.style.height = "0px";
	element.style.overflow = "hidden";
};

export const applyExpandableHtmlLogic = expandable => {
	const button = expandable.querySelector("button");
	const content = expandable.querySelector(".expandable-content");
	if (!button || !content) return;
	if (expandable.dataset.expandableApplied) return;
	expandable.dataset.expandableApplied = 1;
	const isExpanded = expandable.classList.contains("expanded");
	content.setAttribute("role", "region");
	button.setAttribute("aria-expanded", isExpanded);
	button.addEventListener(
		"click",
		() => {
			toggleHeight(content, 300);
			const expanded = expandable.classList.toggle("expanded");
			button.setAttribute("aria-expanded", expanded);
		},
		false
	);
};

/**
 * Converts the given ISO date string to a Unix timestamp in seconds.
 *
 * @param {string} isoDate - The ISO date string to convert (format: "YYYY-MM-DDTHH:mm:ssZ").
 * @returns {number} The Unix timestamp in seconds.
 */
export const isoDateToTimestamp = isoDate => Math.floor(new Date(isoDate).getTime() / 1000);

/**
 * Returns the date of the specified timestamp.
 *
 * @param {number} timestamp - Unix timestamp in seconds.
 * @param {string} locale - The locale (in IETF BCP 47 language tag format).
 */
export const renderDate = (timestamp, locale, timeZone) =>
	new Date(timestamp * 1000)
		.toLocaleString(locale, {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
			timeZone // User speficied or browser default.
		})
		.toUpperCase();

/**
 * Returns the date and time of the specified timestamp.
 *
 * @param {number} timestamp - Unix timestamp in seconds.
 * @param {string} locale - The locale (in IETF BCP 47 language tag format).
 */
export const renderDateTime = (timestamp, locale, timeZone) =>
	new Date(timestamp * 1000)
		.toLocaleString(locale, {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
			hour: "numeric",
			minute: "numeric",
			timeZone // User speficied or browser default.
		})
		.toUpperCase();

/**
 * Returns the date of the specified timestamp in ISO 8601 format.
 *
 * @param {number} timestamp - Unix timestamp in seconds.
 */
export const renderDateIso = timestamp => new Date(timestamp * 1000).toISOString().substring(0, 10);

/**
 * Returns the URL of a node using the specified alias and language.
 */
export function renderUrl(alias, language) {
	if (!language || language === DEFAULT_LANGUAGE) return alias;
	return `${alias}:${language}`;
}

/**
 * Returns the URL of a node based on the user's language and node's available
 * translations.
 */
export function renderNodeUrl(node, user) {
	if (
		!user || // No user.
		!node.languages || // Node doesn't have translations.
		!node.languages.includes(user.language) // Node doesn't have a translation matching user's language.
	) {
		// Render default.
		return renderUrl(node.alias);
	}

	// Render node's url in the user's language.
	return renderUrl(node.alias, user.language);
}

/**
 * Returns the URL parameters representation of an HTML form.
 * Currently only supports what is necessary (e.g no multiple values for same name).
 * e.g:
 * - foo=bar
 * - foo=bar&example=123
 */
export const serializeForm = (formDomNode, trim = true) => {
	const result = [];
	formDomNode.querySelectorAll("[name]").forEach(field => {
		const value = trim ? field.value.trim() : field.value;
		if (!value) return; // Continue.
		result.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(value));
	});
	return result.join("&");
};

/**
 * Sets the field values of a form based on a URL parameters string.
 */
export const unserializeForm = (formDomNode, search) => {
	const urlParams = new URLSearchParams(search);
	formDomNode.querySelectorAll("[name]").forEach(field => {
		field.value = urlParams.get(field.name);
	});
};

/**
 * Returns the FormData object for an HTML form.
 */
export const serializeFormToFormData = formDomNode => {
	const formData = new FormData();
	formDomNode.querySelectorAll("[name]:not([disabled])").forEach(field => {
		if (field.type === "file" && field.files[0]) {
			formData.append(field.name, field.files[0], field.files[0].name);
		} else {
			const value = field.value.trim();
			if (!value) return; // Continue.
			formData.append(field.name, value);
		}
	});
	return formData;
};

/**
 * Returns the FormData object for an HTML form. Uses the data-draft-id
 * attribute instead of the field name. This will be used later to prepopulate
 * the form. The reason for not using the field name is that the form is
 * assembled in a way that the backend expects it, but the field names may
 * not convey enough information to correctly prepopulate the form. In addition
 * we do not need all form fields to be stored in the draft application.
 */
export const serializeDraftFormToFormData = (formDomNode, includeRegularFormContent = false) => {
	const formData = new FormData();
	if (includeRegularFormContent) {
		formDomNode.querySelectorAll("[name]:not([disabled])").forEach(field => {
			if (field.type === "file" && field.files[0]) {
				// Do not deal with files here, already done that previously.
			} else {
				const value = field.value.trim();
				if (!value) return; // Continue.
				formData.append(field.name, value);
			}
		});
	}
	formDomNode.querySelectorAll("[name][data-draft-id]:not([disabled])").forEach(field => {
		// New file upload.
		if (["file"].includes(field.type) && field.files[0]) {
			formData.append(field.dataset.draftId, field.files[0], field.files[0].name);
			// Add info for files which are just being uploaded.
			formData.append("uploaded-file-name-attr[]", field.name);
			formData.append("uploaded-file-draft-id[]", field.dataset.draftId);
			formData.append("uploaded-file-file-id[]", "not available yet");
		}
		// Select, textarea or input (hidden, text, url).
		if (
			(["SELECT", "TEXTAREA"].includes(field.tagName) ||
				("INPUT" === field.tagName && ["hidden", "text", "url"].includes(field.type))) &&
			field.value.trim()
		) {
			formData.append(field.dataset.draftId, field.value.trim());
		}
	});
	// Existing files, uploaded previously.
	formDomNode.querySelectorAll("span.file:not([data-deleted])").forEach(fileSpan => {
		formData.append("uploaded-file-name-attr[]", fileSpan.dataset.nameAttr);
		formData.append("uploaded-file-draft-id[]", fileSpan.dataset.draftId);
		formData.append("uploaded-file-file-id[]", fileSpan.dataset.fileId);
	});
	return formData;
};

/**
 * Decodes query parameters from the specified URL query string (for the form
 * "foo=bar&test=123").
 *
 */
export const decodeQuery = query => {
	if (typeof query !== "string") return {};
	return query.split("&").reduce((result, param) => {
		const tokens = param.split("=");
		if (tokens.length !== 2) return result; // continue
		try {
			result[decodeURIComponent(tokens[0]).trim()] = decodeURIComponent(tokens[1] || "").trim();
		} catch (_error) {
			// Ignore URIError and skip parameter.
		}
		return result;
	}, {});
};

/**
 * Encodes a query parameter object into a URL query string (into the form
 * "foo=bar&test=123").)
 */
export const encodeQuery = params =>
	Object.entries(params)
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
		.join("&");

/**
 * Escapes HTML.
 *
 * https://stackoverflow.com/a/6234804/72478
 */
export const escapeHtml = html =>
	html
		.replace(/&/g, "&amp;")
		.replace(/</g, "&lt;")
		.replace(/>/g, "&gt;")
		.replace(/"/g, "&quot;")
		.replace(/'/g, "&#039;");

export function isExternalLink(href) {
	if (!href || typeof href !== "string") return false;
	return href.match(/^(?:[a-z+]+:)?\/\/[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+/) !== null;
}

// https://stackoverflow.com/a/3561711/72478
const escapeForRegExp = s => s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
const cmsLinkRegExp = new RegExp("^" + escapeForRegExp(BACKEND_PATH));
export function isCmsLink(href) {
	if (!href || typeof href !== "string") return false;
	return cmsLinkRegExp.test(href);
}

const staticAssetLinkRegExp = new RegExp(/^[^?]+\.[^/?]{2,5}(\?.*)?$/);
export function isStaticAssetLink(href) {
	// ^/.+\.[^/]{2,5}$
	if (!href || typeof href !== "string") return false;
	return staticAssetLinkRegExp.test(href);
}

export function isAnchorLink(href) {
	if (!href || typeof href !== "string") return false;
	return href.match(/^#/) !== null;
}

export function isMailLink(href) {
	if (!href || typeof href !== "string") return false;
	return href.match(/^mailto:/) !== null;
}

export function isTelLink(href) {
	if (!href || typeof href !== "string") return false;
	return href.match(/^tel:/) !== null;
}

// https://github.com/facebook/react/issues/9125#issuecomment-285531461
const createLatLonPropTypes = isRequired => (props, propName, componentName) => {
	if (!isRequired && props[propName] === undefined) return;
	if (
		!Array.isArray(props[propName]) ||
		props[propName].length !== 2 ||
		!props[propName].every(value => typeof value === "number")
	) {
		return new Error(`Invalid prop \`${propName}\` supplied to \`${componentName}\`.`);
	}
};

export const latLonPropTypes = createLatLonPropTypes(false);
latLonPropTypes.isRequired = createLatLonPropTypes(true);

export const getClickedAnchor = target => {
	if (!target || !target.tagName) return undefined;
	if (target.tagName === "BODY") return undefined;
	if (target.tagName === "A") return target;
	return getClickedAnchor(target.parentElement);
};

/**
 * Converts the specified text to a react Fragment with all newlines replaces with <br/>.
 */
export const renderFragmentWithNewlines = text =>
	text.split("\n").map((text, index) => (
		<Fragment key={index}>
			{index > 0 && <br />}
			{text}
		</Fragment>
	));

/**
 * Generates a new t function which can map and translate strings to element
 * arrays so translations which change the order of words are possible.
 *
 * Example (en): "_USER_ shared a _NODE_" to [<User />, " ", "shared", " ", "a", " ", <NodeDescription />]
 * Example (el): "Ena _NODE_ mirastike apo ton _USER_" to ["Ena", " ", <NodeDescription />, " ", "mirastike", " ", "apo", " ", "ton", " ", <User />]
 */
export const tMap = t => (key, values, elements) =>
	t(key, values)
		.split(/\b/)
		.map((word, index) => (elements[word] ? <Fragment key={index}>{elements[word]}</Fragment> : word));

// https://stackoverflow.com/a/38750895/72478
export const filterObject = (object, validKeys) =>
	Object.keys(object)
		.filter(key => validKeys.includes(key))
		.reduce((obj, key) => ({ ...obj, [key]: object[key] }), {});

/**
 * Returns the parent path for the specified path.
 *
 * Example: Returns /        for /foo/
 * Example: Returns /foo     for /foo/bar
 * Example: Returns /foo/bar for /foo/bar/test
 */
export const getParentPath = path => {
	if (typeof path !== "string") return;
	if (path[0] !== "/") return;
	if (path === "/") return "/";
	if (path.lastIndexOf("/") === 0) return "/";
	return path.substring(0, path.lastIndexOf("/"));
};

/**
 * Returns the ordinal for the specified number.
 * e.g:
 * - returns "1st" for 1.
 * - returns "2nd" for 2.
 * - returns "3rd" for 3.
 *
 * Solution copy/pasted and adapted from https://stackoverflow.com/a/13627586/72478
 */
export const getOrdinal = (t, number) => {
	return t(
		"ordinal." +
			(number % 10 === 1 && number % 100 !== 11
				? "one"
				: number % 10 === 2 && number % 100 !== 12
					? "two"
					: number % 10 === 3 && number % 100 !== 13
						? "three"
						: "any"),
		{ number }
	);
};

/**
 * Converts plain text with specific formatting rules to basic HTML.
 *
 * Only supports line breaks, unordered lists (starting with "- "), and ordered
 * lists (starting with "1. " or "1) "). Each line in the input text is treated
 * as a separate paragraph separated using line breaks.
 *
 * Example:
 * Input text:
 * ```
 * This is a sample text.
 * - Item 1
 * - Item 2
 * 1. First ordered item
 * 2) Second ordered item
 * This is another paragraph.
 * ```
 *
 * Output HTML:
 * ```
 * This is a sample text.<br/>
 * <ul>
 * <li>Item 1</li>
 * <li>Item 2</li>
 * </ul>
 * <ol>
 * <li>First ordered item</li>
 * <li>Second ordered item</li>
 * </ol>
 * This is another paragraph.
 * ```
 *
 * @param {string} text - The plain text to convert to HTML.
 * @returns {string} The HTML representation of the input text.
 */
export const getBasicHTML = text => {
	const lines = text.trim().split("\n");
	const html = [];
	let lastLine = undefined;
	const opentags = [];
	const ulMatch = /^- /;
	const olMatch = /^\d+[.|)] /;
	lines.forEach((line, index, array) => {
		if (line.match(ulMatch) && (lastLine === undefined || !lastLine.match(ulMatch))) {
			html.push("<ul>\n");
			opentags.unshift("ul");
		}
		if (!line.match(ulMatch) && lastLine !== undefined && lastLine.match(ulMatch)) {
			html.push("</ul>\n");
			opentags.pop();
		}
		if (line.match(olMatch) && (lastLine === undefined || !lastLine.match(olMatch))) {
			html.push("<ol>\n");
			opentags.unshift("ol");
		}
		if (!line.match(olMatch) && lastLine !== undefined && lastLine.match(olMatch)) {
			html.push("</ol>\n");
			opentags.pop();
		}
		if (line.match(ulMatch)) {
			html.push(`<li>${line.replace(ulMatch, "")}</li>\n`);
		} else if (line.match(olMatch)) {
			html.push(`<li>${line.replace(olMatch, "")}</li>\n`);
		} else {
			html.push(line);
			if (index < array.length - 1) {
				html.push("<br/>\n");
			}
		}
		lastLine = line;
	});
	opentags.forEach(opentag => {
		html.push(`</${opentag}>`);
	});
	return html.join("");
};
