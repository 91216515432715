import { WEB_PUSH_PUBLIC_KEY } from "@/config.js";
import { postPushSubscription } from "@/skeleton/DataAccess.js";

const sendSubscriptionToServerDelay = 1000;

function urlBase64ToUint8Array(base64String) {
	const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);
	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}

export function subscribe() {
	if (!window.navigator.serviceWorker) return;
	window.navigator.serviceWorker.ready
		.then(serviceWorkerRegistration =>
			serviceWorkerRegistration.pushManager.subscribe({
				userVisibleOnly: true,
				applicationServerKey: urlBase64ToUint8Array(WEB_PUSH_PUBLIC_KEY)
			})
		)
		.then(subscription => {
			const key = subscription.getKey("p256dh");
			const token = subscription.getKey("auth");
			if (key === null || token === null) {
				throw new Error("Subscription key or token was null.");
			}
			// Send it to server a bit later so it doesn't affect the page load.
			console.log("Scheduling a delayed push subscription send to the server.");
			window.setTimeout(
				() =>
					postPushSubscription({
						type: "web",
						endpoint: subscription.endpoint,
						key: btoa(String.fromCharCode.apply(null, new Uint8Array(key))),
						token: btoa(String.fromCharCode.apply(null, new Uint8Array(token)))
					}),
				sendSubscriptionToServerDelay
			);
		})
		.catch(error => console.log("Push subscription failed, probably due to user denying it:", error));
}

export function unsubscribe() {
	if (!window.navigator.serviceWorker) return;
	window.navigator.serviceWorker.ready
		.then(serviceWorkerRegistration => serviceWorkerRegistration.pushManager.getSubscription())
		.then(subscription => {
			if (!subscription) {
				// Not subscribed.
				return;
			}
			subscription.unsubscribe();
		});
}
