import { PureComponent } from "react";
import PropTypes from "prop-types";
import { userPropTypes, myAidActionUrl } from "@/common/user.js";
import { programmePropTypes } from "@/common/Term.js";
import { Link } from "react-router-dom";
import "./CardPanel.scss";
import RealPictureUploader from "@/common/RealPictureUploader.jsx";
import Image from "@/common/Image.jsx";
import ECSupportFooter from "@/common/ECSupportFooter.jsx";
import { getBasicHTML } from "@/common/utils.jsx";
import { BACKEND_DOMAIN, BACKEND_PATH } from "@/config.js";
import { renderDateTime, renderDateIso } from "@/common/utils.jsx";

export default class Card extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		user: userPropTypes,
		programmes: PropTypes.arrayOf(programmePropTypes),
		reloadUser: PropTypes.func.isRequired
	};

	state = {
		confirmedDetails: false,
		replacePhoto: false
	};

	render() {
		const { t, getUrl, user, programmes, reloadUser } = this.props;
		const { confirmedDetails, replacePhoto } = this.state;

		// Anonymous.
		if (!user)
			return (
				<div className="error">
					<div className="message">
						<p>{t("Card.errors.anonymous")}</p>
					</div>
					<div className="actions">
						<form action={myAidActionUrl("/card")} method="post">
							<button name="myaid" type="submit" className="button">
								{t("Card.edugain.button")}
							</button>
							<input type="hidden" name="form_id" value="openid_connect_login_form" />
						</form>
					</div>
					<ECSupportFooter t={t} />
				</div>
			);

		// User without programme.
		const userProgramme = programmes.find(programme => programme.id === (user.programme && user.programme.id));
		if (!userProgramme)
			return (
				<div className="error">
					<div className="message">
						<p>{t("Card.errors.no_programme")}</p>
					</div>
					<div className="actions">
						<Link to={getUrl("/profile/programme")} className="button">
							{t("ProfilePanel.programme")}
						</Link>
					</div>
					<ECSupportFooter t={t} />
				</div>
			);

		// User with a programme which does not provide a card.
		if (!userProgramme.providesCard)
			return (
				<div className="error">
					<div className="message">
						<p>{t("Card.errors.wrong_programme")}</p>
					</div>
					<ECSupportFooter t={t} />
				</div>
			);

		// User missing the required data on their profile.
		if (!user.esi || !user.edugainHomeOrganisation)
			return (
				<div className="error">
					<div className="message">
						<p>{t("Card.errors.missing_data")}</p>
					</div>
					<ECSupportFooter t={t} />
				</div>
			);

		// Preview details.
		if (!user.realPicture && !confirmedDetails)
			return (
				<div className="previewDetails">
					<h2>{t("Card.preview.title")}</h2>
					<dl>
						<div>
							<dt>{t("name")}</dt>
							<dd>{user.name}</dd>
						</div>
						<div>
							<dt>{t("surname")}</dt>
							<dd>{user.surname}</dd>
						</div>
						<div>
							<dt>{t("email")}</dt>
							<dd>{user.email}</dd>
						</div>
						<div>
							<dt>{t("Card.organisation")}</dt>
							<dd>{user.edugainHomeOrganisation}</dd>
						</div>
					</dl>
					<div className="message">
						<h3>{t("Card.preview.confirm")}</h3>
						<button className="button" onClick={() => this.setState({ confirmedDetails: true })}>
							{t("Card.preview.yes")}
						</button>
						<p>{t("Card.preview.no")}</p>
					</div>
					<ECSupportFooter t={t} />
				</div>
			);

		// Upload photo.
		if (replacePhoto || !user.realPicture)
			return (
				<div className="pictureUpload">
					<h2>{t("Card.picture.title")}</h2>
					<div dangerouslySetInnerHTML={{ __html: getBasicHTML(t("Card.picture.help")) }} />
					<RealPictureUploader
						t={t}
						user={user}
						reloadUser={() => {
							reloadUser(
								// In case this upload is through a replace.
								() => this.setState({ replacePhoto: false })
							);
						}}
					/>
					<ECSupportFooter t={t} />
				</div>
			);

		// Card is ready.
		const now = Date.now() / 1000;
		return (
			<div className="cardWrapper">
				<div>
					<p className="erasmusLogo">{t("title")}</p>
					<time dateTime={renderDateIso(now)}>{renderDateTime(now, t("locale.full"))}</time>
				</div>
				<div className="Card">
					<h2>
						{user.name} {user.surname}
					</h2>
					<div className="info">
						<Image image={user.realPicture} type="avatar" />
						<div>
							<label>{t("Card.organisation")}</label>
							<h3>{user.edugainHomeOrganisation}</h3>
						</div>
					</div>
					<div className="decoration">
						<p className="erasmusLogo">{t("title")}</p>
					</div>
				</div>
				<div className="validate">
					<img
						src={BACKEND_DOMAIN + BACKEND_PATH + "api/user/card-validation-qr-code-image"}
						alt={t("Card.card.validate")}
					/>
				</div>
				<p className="created">
					{t("created_at", { date: renderDateTime(user.realPictureCreated, t("locale.full")) })}
				</p>
				<button className="button" onClick={() => this.setState({ replacePhoto: true })}>
					{t("Card.card.replace")}
				</button>
				<ECSupportFooter t={t} />
			</div>
		);
	}
}
