import { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import Image, { imagePropTypes } from "@/common/Image.jsx";
import { Link } from "react-router-dom";
import "./OrganisationReference.scss";

export const organisationReferencePropTypes = PropTypes.arrayOf(
	PropTypes.exact({
		id: PropTypes.number.isRequired,
		type: PropTypes.oneOf(["organisation"]).isRequired,
		title: PropTypes.string.isRequired,
		alias: PropTypes.string.isRequired,
		summary: PropTypes.string,
		image: imagePropTypes
	})
);

export default class OrganisationReference extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		organisations: organisationReferencePropTypes,
		type: PropTypes.oneOf(["full", "links"]).isRequired
	};

	render() {
		const { t, getUrl, organisations, type } = this.props;
		if (!organisations || !organisations.length) return null;
		return (
			<div className="OrganisationReference">
				{type === "full" &&
					organisations.map((node, index) => (
						<article key={index} className="Organisation Full">
							<h2>{t("OrganisationReference.about", { organisation: node.title })}</h2>
							<Image image={node.image} type="teaser" />
							{node.summary && <p>{node.summary}</p>}
							<Link className="button" to={getUrl(node.alias)}>
								{t("readmore")}
							</Link>
						</article>
					))}
				{type === "links" && (
					<Fragment>
						<label>{t("OrganisationReference.label")}</label>
						<ul>
							{organisations.map((node, index) => (
								<li key={index} className="Organisation">
									<Link to={getUrl(node.alias)}>{node.title}</Link>
								</li>
							))}
						</ul>
					</Fragment>
				)}
			</div>
		);
	}
}
