// Contains rendering functions extracted from ProfilePanel in order to reduce its size.
// Each function accepts the ProfilePanel component instance as an argument in
// order to extract props, state, member functions etc. This is most probably
// and anti-pattern, but it's the easiest way in order to declutter ProfilePanel.

import { Fragment } from "react";
import { Link } from "react-router-dom";
import {
	getOrdinal,
	serializeDraftFormToFormData,
	renderFragmentWithNewlines as newlines,
	getHumanReadableFileSize
} from "@/common/utils.jsx";
import { BACKEND_DOMAIN, DOCUMENT_UPLOAD_ACCEPT_ATTRIBUTE, MAX_UPLOAD_SIZE } from "@/config.js";
import autosize from "autosize";
import {
	saveApplication as saveApplicationToServer,
	saveAndSubmitApplication as saveAndSubmitApplicationToServer,
	deleteDraftApplication as deleteDraftApplicationFromServer
} from "@/skeleton/DataAccess.js";
import Wrapper from "@/common/Wrapper.jsx";
import history from "@/skeleton/history.js";
import { isLastApplicationUpdateReadOnly } from "@/common/user.js";

export const renderApplicationPanel = (parent, nextUrl) => {
	const { url } = parent.props;
	if (url === "/profile/application/period") return renderApplicationPeriod(parent, nextUrl);
	if (url === "/profile/application/choices") return renderApplicationChoices(parent, nextUrl);
	if (url === "/profile/application/info") return renderApplicationInfo(parent, nextUrl);
	if (url === "/profile/application/form") return renderApplicationFormWithReviewStep(parent, nextUrl);
	if (url === "/profile/application/form/resume") return renderApplicationFormResumePrepare(parent);
	if (url === "/profile/application/form/view") return renderApplicationFormViewPrepare(parent);
	if (url === "/profile/application/review") return renderApplicationFormWithReviewStep(parent, nextUrl);
};

const DEFAULT_MAX_DESTINATIONS_AMOUNT = 3;

export const getMaxDestinationsAmount = user =>
	(user &&
		user.homeFaculty &&
		user.homeFaculty.outgoingApplicationRequirements &&
		user.homeFaculty.outgoingApplicationRequirements.destinationsAmount) ||
	DEFAULT_MAX_DESTINATIONS_AMOUNT;

// Stores HTML form DOM element so we can submit and manipulate (bypassing react).
let applicationForm = undefined;

export const enhanceApplicationForm = () => {
	if (!applicationForm) return;

	// Apply autosize to auto grow textareas.
	applicationForm.querySelectorAll("textarea[name]").forEach(textarea => autosize(textarea));

	// Conditionaly disable files or urls.
	applicationForm.querySelectorAll(".fileOrUrl:not([data-enhanced]):not([data-disabled])").forEach(fileOrUrl => {
		// Mark them accordingly so we don't re-apply logic on rerenders.
		fileOrUrl.setAttribute("data-enhanced", true);
		const fileInput = fileOrUrl.querySelector("input[type='file']");
		const fileSpan = fileOrUrl.querySelector("span.file"); // The draft file, in case it exists.
		const urlInput = fileOrUrl.querySelector("input[type='url']");
		const updateDisabledStates = () => {
			// Start with everything enabled.
			fileInput.removeAttribute("disabled");
			fileInput.parentElement.classList.remove("disabled");
			fileInput.style.display = ""; // auto
			fileInput.parentElement.classList.remove("disabled");
			urlInput.removeAttribute("disabled");
			urlInput.parentElement.classList.remove("disabled");
			// Disable url if there is a file selected.
			if (fileInput.value.length) {
				urlInput.setAttribute("disabled", "disabled");
				urlInput.parentElement.classList.add("disabled");
			}
			// Disable file and url if a draft exists.
			if (fileSpan && fileSpan.style.display !== "none") {
				fileInput.setAttribute("disabled", "disabled");
				fileInput.parentElement.classList.add("disabled");
				fileInput.style.display = "none";
				urlInput.setAttribute("disabled", "disabled");
				urlInput.parentElement.classList.add("disabled");
			}
			// Disable file if the input has a value.
			if (urlInput.value.length) {
				fileInput.setAttribute("disabled", "disabled");
				fileInput.parentElement.classList.add("disabled");
			}
		};
		// Apply logic on input change.
		fileInput.addEventListener("input", updateDisabledStates);
		urlInput.addEventListener("input", updateDisabledStates);
		// Apply logic on initial form rendering.
		updateDisabledStates();
	});
};

export const isApplicationFormValid = () => applicationForm && applicationForm.checkValidity();

const saveApplication = parent => {
	const { getUrl } = parent.props;
	const formData = serializeDraftFormToFormData(applicationForm);
	parent.setApplicationFormSubmissionStatus("pending");
	saveApplicationToServer(formData)
		.then(() => {
			// Move to /applications. That component will reload user to display the current state.
			history.push(getUrl("/applications"));
		})
		.catch(() => {
			parent.setApplicationFormSubmissionStatus("error");
		});
};

const saveAndSubmitApplication = (e, parent) => {
	e.preventDefault();
	const formData = serializeDraftFormToFormData(applicationForm, true);
	parent.setApplicationFormSubmissionStatus("pending");
	saveAndSubmitApplicationToServer(formData)
		.then(() => {
			parent.onApplicationFormSubmissionSuccess();
		})
		.catch(() => {
			parent.setApplicationFormSubmissionStatus("error");
		});
};

const deleteDraftApplication = parent => {
	const { getUrl } = parent.props;
	parent.setApplicationFormSubmissionStatus("pending");
	deleteDraftApplicationFromServer()
		.then(() => {
			// Move to /applications. That component will reload user to display the current state.
			history.push(getUrl("/applications"));
		})
		.catch(() => {
			parent.setApplicationFormSubmissionStatus("error");
		});
};

export const renderHostFacultyOverview = (t, hostFaculty) =>
	(hostFaculty.coopConditionInfo || hostFaculty.incomingApplicationRequirements || hostFaculty.languageLevels) && (
		<Wrapper className="facultyInfo">
			<Wrapper
				className="overview"
				header={<h3>{t("ProfilePanel.preferredHostFaculties.overview")}</h3>}
				htmlTag="ul"
			>
				{hostFaculty.coopConditionInfo && (
					<li>
						{t("ProfilePanel.preferredHostFaculties.yearMobilities")}:{" "}
						{hostFaculty.coopConditionInfo.yearMobilities}
					</li>
				)}
				{hostFaculty.coopConditionInfo && (
					<li>
						{t("ProfilePanel.preferredHostFaculties.totalMonths")}:{" "}
						{hostFaculty.coopConditionInfo.totalMonths}
					</li>
				)}
				{hostFaculty.coopConditionInfo && hostFaculty.coopConditionInfo.usefulInformation && (
					<li>
						{t("ProfilePanel.preferredHostFaculties.usefulInformation")}:<br />
						{newlines(hostFaculty.coopConditionInfo.usefulInformation)}
					</li>
				)}
				{hostFaculty.coopConditionInfo && hostFaculty.coopConditionInfo.studyLevels && (
					<li>
						{/* TODO: externalize those i18n literals for the next translation round ERA-437. */}
						Study cycle:{" "}
						{hostFaculty.coopConditionInfo.studyLevels.map(studyLevel => studyLevel.title).join(", ")}
					</li>
				)}
			</Wrapper>
			<Wrapper
				className="requirements"
				header={<h3>{t("ProfilePanel.application.info.requirements")}</h3>}
				htmlTag="ul"
			>
				{hostFaculty.languageLevels &&
					hostFaculty.languageLevels.map((languageLevel, index) => (
						<li key={index}>
							{languageLevel.language.title} - {languageLevel.level.title}
						</li>
					))}
				{hostFaculty.incomingApplicationRequirements &&
					hostFaculty.incomingApplicationRequirements.cvRequired && (
						<li>{t("ProfilePanel.application.info.cv")}</li>
					)}
				{hostFaculty.incomingApplicationRequirements &&
					hostFaculty.incomingApplicationRequirements.motivationLetterRequired && (
						<li>{t("ProfilePanel.application.info.motivationLetter")}</li>
					)}
				{hostFaculty.incomingApplicationRequirements &&
					hostFaculty.incomingApplicationRequirements.otherRequirements &&
					hostFaculty.incomingApplicationRequirements.otherRequirements.map((otherRequirement, index) => (
						<li key={index}>{otherRequirement.description}</li>
					))}
			</Wrapper>
		</Wrapper>
	);

const renderApplicationPeriod = (parent, nextUrl) => {
	const { t, getUrl, academicYears, academicTerms } = parent.props;
	const { selectedAcademicYearId, selectedAcademicTerm } = parent.state;

	return (
		<Fragment>
			<p>{t("ProfilePanel.application.period.description")}</p>
			<div className="editableField">
				<h2>{t("ProfilePanel.application.period.academicYear")}</h2>
				<div>
					{academicYears && academicYears.length > 0 && (
						<select
							aria-label={t("ProfilePanel.application.period.academicYear")}
							value={selectedAcademicYearId}
							onChange={e => parent.setAcademicYearId(Number(e.target.value))}
						>
							<option value="">{t("ProfilePanel.field_empty")}</option>
							{academicYears.map(academicYear => (
								<option key={academicYear.id} value={academicYear.id}>
									{academicYear.title}
								</option>
							))}
						</select>
					)}
				</div>
			</div>
			<div className="editableField">
				<h2>{t("ProfilePanel.application.period.academicTerm")}</h2>
				<div>
					{academicTerms && academicTerms.length > 0 && (
						<select
							aria-label={t("ProfilePanel.application.period.academicTerm")}
							value={selectedAcademicTerm}
							onChange={e => parent.setAcademicTerm(e.target.value)}
						>
							<option value="">{t("ProfilePanel.field_empty")}</option>
							{academicTerms.map((academicTerm, index) => (
								<option key={index} value={academicTerm}>
									{academicTerm}
								</option>
							))}
						</select>
					)}
				</div>
			</div>
			<div className="actions">
				<Link
					to={getUrl(nextUrl)}
					role="button"
					className={selectedAcademicYearId && selectedAcademicTerm ? "button" : "button disabled"}
				>
					{t("next")}
				</Link>
			</div>
		</Fragment>
	);
};

const renderApplicationChoices = (parent, nextUrl) => {
	const { t, getUrl, user } = parent.props;
	const { selectedPreferredHostFacultyIds, orderedPreferredHostFacultyIds } = parent.state;
	return (
		<Fragment>
			<p>{t("ProfilePanel.application.choices.description")}</p>
			{Array(getMaxDestinationsAmount(user))
				.fill()
				.map((ignoredValue, index) => (
					<div key={index} className="editableField">
						<h2>{t("ProfilePanel.application.choices.choice", { ordinal: getOrdinal(t, index + 1) })}</h2>

						<select
							aria-label={t("ProfilePanel.application.choices.choice", {
								ordinal: getOrdinal(t, index + 1)
							})}
							value={orderedPreferredHostFacultyIds[index]}
							onChange={e => {
								const temp = orderedPreferredHostFacultyIds.slice(); // clone
								temp[index] = Number(e.target.value) || undefined;
								parent.setOrderedPreferredHostFacultyIds(temp);
							}}
							disabled={index > 0 && orderedPreferredHostFacultyIds[index - 1] === undefined}
						>
							<option value="" disabled={orderedPreferredHostFacultyIds[index + 1] !== undefined}>
								{t("ProfilePanel.field_empty")}
							</option>
							{user.preferredHostFaculties
								.filter(preferredHostFaculty =>
									selectedPreferredHostFacultyIds.includes(preferredHostFaculty.id)
								)
								.map(preferredHostFaculty => (
									<option
										key={preferredHostFaculty.id}
										value={preferredHostFaculty.id}
										disabled={
											orderedPreferredHostFacultyIds.includes(preferredHostFaculty.id) &&
											orderedPreferredHostFacultyIds[index] !== preferredHostFaculty.id
										}
									>
										{preferredHostFaculty.title} ({preferredHostFaculty.organisation}),{" "}
										{preferredHostFaculty.city}, {preferredHostFaculty.country}
									</option>
								))}
						</select>
					</div>
				))}
			<div className="actions">
				<Link
					to={getUrl(nextUrl)}
					role="button"
					className={orderedPreferredHostFacultyIds[0] ? "button" : "button disabled"}
				>
					{t("next")}
				</Link>
			</div>
		</Fragment>
	);
};

const getOrderedPreferredHostFaculties = parent =>
	parent.state.orderedPreferredHostFacultyIds
		.map(orderedPreferredHostFacultyId =>
			parent.props.user.preferredHostFaculties.find(faculty => faculty.id === orderedPreferredHostFacultyId)
		)
		.filter(Boolean); // Filter out non found faculties. Cannot really happen.

const renderApplicationInfo = (parent, nextUrl) => {
	const { t, getUrl, user } = parent.props;
	return (
		<Fragment>
			<p>{t("ProfilePanel.application.info.description")}</p>
			{user.homeFaculty.outgoingApplicationRequirements && (
				<div className="facultyRequirements">
					<div className="faculty">
						<div>
							<h2>{user.homeOrganisation.title}</h2>
							<p>
								{user.homeOrganisation.city.title}, {user.homeOrganisation.country.title}
								{user.homeFaculty.title !== "*" && ", " + user.homeFaculty.title}
							</p>
						</div>
					</div>
					<div className="facultyInfo">
						<h3>{t("ProfilePanel.application.info.requirements")}</h3>
						<ul>
							{user.homeFaculty.outgoingApplicationRequirements.cvRequired && (
								<li>{t("ProfilePanel.application.info.cv")}</li>
							)}
							{user.homeFaculty.outgoingApplicationRequirements.motivationLetterRequired && (
								<li>{t("ProfilePanel.application.info.motivationLetter")}</li>
							)}
							{user.homeFaculty.outgoingApplicationRequirements.transcriptRequired && (
								<li>{t("ProfilePanel.application.info.transcript")}</li>
							)}
							{user.homeFaculty.outgoingApplicationRequirements.recommendationLetterRequired && (
								<li>{t("ProfilePanel.application.info.recommendationLetter")}</li>
							)}
							{user.homeFaculty.outgoingApplicationRequirements.externalCertificationRequired && (
								<li>{t("ProfilePanel.application.info.externalCertification")}</li>
							)}
							{user.homeFaculty.outgoingApplicationRequirements.referenceContactRequired && (
								<li>{t("ProfilePanel.application.info.referenceContact")}</li>
							)}
							{user.homeFaculty.outgoingApplicationRequirements.portfolioRequired && (
								<li>{t("ProfilePanel.application.info.portfolio")}</li>
							)}
							{user.homeFaculty.outgoingApplicationRequirements.otherRequirements &&
								user.homeFaculty.outgoingApplicationRequirements.otherRequirements.map(
									(otherRequirement, index) => <li key={index}>{otherRequirement.description}</li>
								)}
							{user.homeFaculty.outgoingApplicationRequirements.otherDocuments &&
								user.homeFaculty.outgoingApplicationRequirements.otherDocuments.map(
									(otherDocument, index) => <li key={index}>{otherDocument.description}</li>
								)}
							{user.homeFaculty.outgoingApplicationRequirements.languageLevels &&
								user.homeFaculty.outgoingApplicationRequirements.languageLevels.map(
									(languageLevel, index) => (
										<li key={index}>
											{languageLevel.language.title} - {languageLevel.level.title}
										</li>
									)
								)}
						</ul>
					</div>
				</div>
			)}
			{getOrderedPreferredHostFaculties(parent).map((preferredHostFaculty, index) => (
				<div key={index} className="facultyRequirements">
					<div className="faculty">
						<div className="ordinal">
							{t("ProfilePanel.application.choices.choice", {
								ordinal: getOrdinal(t, index + 1)
							})}
						</div>
						<div>
							<h2>{preferredHostFaculty.organisation}</h2>
							<p>
								{preferredHostFaculty.city}, {preferredHostFaculty.country},{" "}
								{preferredHostFaculty.title}
							</p>
						</div>
					</div>
					{renderHostFacultyOverview(t, preferredHostFaculty)}
				</div>
			))}
			<div className="actions">
				<Link to={getUrl(nextUrl)} role="button" className="button">
					{t("next")}
				</Link>
			</div>
		</Fragment>
	);
};

export const mergeRequirements = (...requirements) => {
	const result = {};
	requirements
		.filter(requirement => typeof requirement === "object" && requirement !== null && !Array.isArray(requirement))
		.forEach(requirement => {
			for (const [key, value] of Object.entries(requirement)) {
				if (Array.isArray(value)) {
					result[key] = (result[key] || []).concat(value);
				} else {
					result[key] = value;
				}
			}
		});
	return result;
};

/**
 * Merges the specified language levels by language. e.g for input:
 * [
 *   { language: { id: 1, title: "English" }, level: { id: 10, title: "Junior" } },
 *   { language: { id: 2, title: "German" }, level: { id: 10, title: "Junior" } },
 *   { language: { id: 1, title: "English" }, level: { id: 11, title: "Expert" } }
 * ]
 * it returns:
 * [
 *   { language: { id: 1, title: "English" }, levels: [ { id: 10, title: "Junior" }, { id: 11, title: "Expert" } ] },
 *   { language: { id: 2, title: "German" }, levels: [ { id: 10, title: "Junior" } ] }
 * ]
 */
export const mergeLanguageLevels = languageLevels => {
	if (!Array.isArray(languageLevels)) return [];
	const result = [];
	languageLevels
		.filter(
			languageLevel =>
				typeof languageLevel === "object" &&
				languageLevel !== null &&
				!Array.isArray(languageLevel) &&
				languageLevel.language &&
				languageLevel.language.id &&
				languageLevel.level &&
				languageLevel.level.id
		)
		.forEach(languageLevel => {
			const existingLanguageLevel = result.find(
				existingLanguageLevel => existingLanguageLevel.language.id === languageLevel.language.id
			);
			if (!existingLanguageLevel) {
				result.push({
					language: languageLevel.language,
					levels: [languageLevel.level]
				});
			} else {
				if (!existingLanguageLevel.levels.find(level => level.id === languageLevel.level.id)) {
					existingLanguageLevel.levels.push(languageLevel.level);
				}
			}
		});
	return result;
};

const renderApplicationFormWithReviewStep = (parent, nextUrl) => {
	const { url } = parent.props;
	return (
		<Fragment>
			{/* Hide the form in the next step, instead of removing it, so the data is still there and can be submitted. */}
			<div
				className="applicationForm"
				style={{ display: url === "/profile/application/form" ? "block" : "none" }}
			>
				{renderApplicationForm(parent, nextUrl)}
			</div>
			<div
				className="applicationReview"
				style={{ display: url === "/profile/application/review" ? "block" : "none" }}
			>
				{renderApplicationReviewAndSubmit(parent)}
			</div>
		</Fragment>
	);
};

/**
 * Calculates total post size and warns user if it has exceeded what the server
 * is wiling to accept.
 */
const checkFormPostSize = parent => {
	const { applicationFormSubmissionStatus: status } = parent.state;
	let totalSize = 0;
	const selectedFileSizes = {};
	// Sum the size of the selected files (not uploaded yet).
	applicationForm.querySelectorAll("input[name][type=file]").forEach(field => {
		let fileSize = 0;
		Array.from(field.files).forEach(file => {
			totalSize += file.size;
			fileSize += file.size;
		});
		selectedFileSizes[field.dataset.draftId] = fileSize > 0 ? fileSize : undefined;
	});
	// Set the file sizes on the state for rendering.
	parent.setState({ selectedFileSizes: { ...parent.state.selectedFileSizes, ...selectedFileSizes } });
	// Also, add any draft file sizes (already uploaded) to the total.
	applicationForm.querySelectorAll(".file[data-size]").forEach(file => (totalSize += Number(file.dataset.size)));

	if (totalSize >= MAX_UPLOAD_SIZE) {
		parent.setApplicationFormSubmissionStatus("warning-uploadMaxSize");
	} else {
		// Reset status only if previous status was the uploadMaxSize warning.
		if (status === "warning-uploadMaxSize") {
			parent.setApplicationFormSubmissionStatus(undefined);
		}
	}
};

const renderApplicationForm = (parent, nextUrl) => {
	const { t, getUrl, user, academicYears } = parent.props;
	const {
		applicationFormSubmissionStatus: status,
		selectedPreferredHostFacultyIds,
		selectedAcademicYearId,
		selectedAcademicTerm,
		orderedPreferredHostFacultyIds,
		prepopulateForm
	} = parent.state;

	// Merge outgoing requirements of the home faculty with incoming requirements of all preferred host faculties.
	const commonRequirements = mergeRequirements(
		user.homeFaculty.outgoingApplicationRequirements,
		...getOrderedPreferredHostFaculties(parent).map(
			preferredHostFaculty => preferredHostFaculty.incomingApplicationRequirements
		)
	);

	// Count how many times each requirement has been required (by host and destinations).
	const commonRequirementCounts = [
		// The home faculty may hold outgoing application requirements.
		user.homeFaculty.outgoingApplicationRequirements,
		// The preferred host facilties may hold incoming application requirements.
		...getOrderedPreferredHostFaculties(parent).map(
			// The incoming application requirements are optional.
			preferredHostFaculty => preferredHostFaculty.incomingApplicationRequirements || {}
		)
	]
		.filter(Boolean) // Filter out possibly missing application requirements from home organisation or host faculties.
		.reduce((counts, applicationRequirements) => {
			Object.keys(applicationRequirements).forEach(key => {
				const value = applicationRequirements[key];
				// Boolean value, e.g { cvRequired: true }
				if (typeof value === "boolean" && value === true) {
					counts[key] = (counts[key] || 0) + 1;
				}
				// Array value, e.g [ { description: "A custom CV"}, { description: "A cover letter" } ]
				if (Array.isArray(value)) {
					value.forEach(element => {
						const keyWithDescription = key + "-" + element.description;
						counts[keyWithDescription] = (counts[keyWithDescription] || 0) + 1;
					});
				}
			});
			return counts;
		}, {});

	// Merge language levels (required by home and destinations) by their language.
	const commonLanguageRequirements = mergeLanguageLevels(
		getOrderedPreferredHostFaculties(parent)
			.map(preferredHostFaculty => preferredHostFaculty.languageLevels)
			.flat()
			// ERA-421 Also add home organisation's language levels.
			.concat(
				user.homeFaculty.outgoingApplicationRequirements &&
					user.homeFaculty.outgoingApplicationRequirements.languageLevels
			)
	);

	// Count how many times each language has been required (by host and destinations).
	const commonLanguageRequirementCounts = [
		// The home faculty may hold outgoing application requirements which may hold language levels.
		user.homeFaculty.outgoingApplicationRequirements,
		// The preferred host facilties may hold language levels.
		...getOrderedPreferredHostFaculties(parent)
	]
		.filter(Boolean) // Filter out possibly missing application requirements from home organisation or host faculties.
		.reduce((counts, holderOfLanguageLevels) => {
			// The language levels are optional.
			(holderOfLanguageLevels.languageLevels || [])
				// Only interested in the language code.
				.map(languageLevel => languageLevel.language.code)
				// Unique across a holder. i.e a faculty which, for whatever reason, requires English 2 times, we want to count that as 1 faculty
				.filter((languageCode, index, languageCodes) => languageCodes.indexOf(languageCode) === index)
				// Count.
				.forEach(languageCode => (counts[languageCode] = (counts[languageCode] || 0) + 1));
			return counts;
		}, {});

	// We need to remove spaces and dots because the backend converts those
	// to underscores (https://stackoverflow.com/a/17092455/72478) and this
	// would mean that we would not be able to match those when needing to
	// prepopulate the form.
	const sanitizeId = id => id.trim().replaceAll(/\s|\./g, "-");

	// Used to prepopulate form if the user is carrying draft form data.
	const draftFormData =
		prepopulateForm && user.lastApplicationUpdate && user.lastApplicationUpdate.data
			? user.lastApplicationUpdate.data
			: {};

	// Whether we are in read only mode (i.e view form).
	const readOnly = prepopulateForm && isLastApplicationUpdateReadOnly(user);

	// Deletes the specified draft file id. Current implementation simply hides
	// the dom element. Removing it (via parent state) was possible but mixing
	// react DOM and JS DOM manipulation did not work well.
	// If the user submits, the file will be removed from the server (because
	// the id will be missing from the POST).
	const deleteDraftFile = e => {
		const fileSpan = e.target.closest("span.file");
		fileSpan.style.display = "none";
		fileSpan.setAttribute("data-deleted", "true");
		const inputFile = fileSpan.parentElement.querySelector("input[type='file']");
		inputFile.dispatchEvent(new Event("input", { bubbles: true })); // Bubble up so the input's onInput is triggered as well.
	};

	// Remove the selected (not yet uploaded) file from the input.
	// https://stackoverflow.com/a/20552042/72478
	const deleteSelectedFile = e => {
		e.preventDefault();
		const inputFile = e.target.parentElement.querySelector("input[type='file']");
		inputFile.value = "";
		inputFile.dispatchEvent(new Event("input", { bubbles: true })); // Bubble up so the input's onInput is triggered as well.
	};

	const renderFileOrUrlField = (
		key,
		caption,
		multiple = false,
		description = caption,
		required = true,
		requiredBy = 0
	) => {
		const fileInputDraftId = "erasmus-" + sanitizeId(key + " file " + description);
		const draftFile = draftFormData[fileInputDraftId];

		return (
			<fieldset className="fileOrUrl" data-disabled={readOnly ? true : undefined}>
				<legend>
					{caption}
					{!required && <span className="optional">{t("optional")}</span>}
				</legend>
				<label>
					<span>
						{t("ProfilePanel.application.form.file")} <small>(PDF)</small>
					</span>
					{!readOnly && (
						<Fragment>
							{multiple && renderHiddenField(key + "-file-description", description, true)}
							<input
								name={key + "-file" + (multiple ? "[]" : "")}
								type="file"
								accept={DOCUMENT_UPLOAD_ACCEPT_ATTRIBUTE}
								required={required}
								onInput={() => checkFormPostSize(parent)}
								data-draft-id={fileInputDraftId}
								disabled={readOnly}
							/>
							{parent.state.selectedFileSizes[fileInputDraftId] && (
								<Fragment>
									<span className="size">
										{getHumanReadableFileSize(parent.state.selectedFileSizes[fileInputDraftId])}
									</span>
									<button className="delete" onClick={deleteSelectedFile} type="button">
										{t("delete")}
									</button>
								</Fragment>
							)}
						</Fragment>
					)}
					{draftFile && (
						<span
							className="file"
							data-size={draftFile.size}
							data-name-attr={key + "-file" + (multiple ? "[]" : "")}
							data-draft-id={fileInputDraftId}
							data-file-id={draftFile.id}
						>
							<span>
								<a
									href={BACKEND_DOMAIN + draftFile.url}
									rel="external noopener noreferrer"
									target="_blank"
									type={draftFile.mimeType}
								>
									{draftFile.name}
									<span className="visually-hidden">{t("opens_in_new_window")}</span>
								</a>
								<span className="size">{getHumanReadableFileSize(draftFile.size)}</span>
							</span>
							{!readOnly && (
								<button className="delete" onClick={deleteDraftFile} type="button">
									{t("delete")}
								</button>
							)}
						</span>
					)}
				</label>
				<label>
					<span>{t("ProfilePanel.application.form.link")}</span>
					{multiple && renderHiddenField(key + "-link-description", description, true)}
					<input
						name={key + "-link" + (multiple ? "[]" : "")}
						type="url"
						required={required}
						data-draft-id={"erasmus-" + sanitizeId(key + " link " + description)}
						defaultValue={draftFormData["erasmus-" + sanitizeId(key + " link " + description)]}
						disabled={readOnly}
					/>
				</label>
				{requiredBy > 1 && <p>{t("ProfilePanel.application.form.requiredBy", { count: requiredBy })}</p>}
			</fieldset>
		);
	};

	const renderTextField = (key, caption, multiple = false, requiredBy = 0) => (
		<fieldset className="textarea">
			<label>
				<span>{caption}</span>
				<textarea
					rows="1"
					name={key + (multiple ? "[]" : "")}
					required
					data-draft-id={"erasmus-" + sanitizeId(key + " " + caption)}
					defaultValue={draftFormData["erasmus-" + sanitizeId(key + " " + caption)]}
					disabled={readOnly}
				></textarea>
			</label>
			{requiredBy > 1 && <p>{t("ProfilePanel.application.form.requiredBy", { count: requiredBy })}</p>}
		</fieldset>
	);

	const renderHiddenField = (key, value, multiple = false, renderDraftId = false) => (
		<input
			type="hidden"
			name={key + (multiple ? "[]" : "")}
			defaultValue={value}
			data-draft-id={renderDraftId ? "erasmus-" + sanitizeId(key) : undefined}
		/>
	);

	const academicYear = academicYears.find(academicYear => academicYear.id === selectedAcademicYearId);
	return (
		<Fragment>
			{!readOnly && <p>{t("ProfilePanel.application.form.description")}</p>}
			<form
				ref={form => (applicationForm = form)}
				onSubmit={e => !readOnly && saveAndSubmitApplication(e, parent)}
			>
				{/* A missing academicYear cannot easily happen, but check anyway. */}
				{renderHiddenField(
					"selectedPreferredHostFacultyIds",
					selectedPreferredHostFacultyIds.join(","),
					false,
					true
				)}
				{academicYear && renderHiddenField("academicYearTitle", academicYear.title, false, true)}
				{academicYear && renderHiddenField("academicYearId", academicYear.id, false, true)}
				{renderHiddenField("academicTerm", selectedAcademicTerm, false, true)}
				{renderHiddenField(
					"orderedPreferredHostFacultyIds",
					orderedPreferredHostFacultyIds.join(","),
					false,
					true
				)}
				{user.homeFaculty.outgoingApplicationRequirements && (
					<Fragment>
						{user.homeFaculty.outgoingApplicationRequirements.requiredIsced &&
							renderHiddenField(
								"requiredIsced",
								user.homeFaculty.outgoingApplicationRequirements.requiredIsced
							)}
						{user.homeFaculty.outgoingApplicationRequirements.requiredEqf &&
							renderHiddenField(
								"requiredEqf",
								user.homeFaculty.outgoingApplicationRequirements.requiredEqf
							)}
						{user.homeFaculty.outgoingApplicationRequirements.requiredEcts &&
							renderHiddenField(
								"requiredEcts",
								user.homeFaculty.outgoingApplicationRequirements.requiredEcts
							)}
					</Fragment>
				)}
				{getOrderedPreferredHostFaculties(parent).map((faculty, index) => {
					const homeAndHostRequirements = mergeRequirements(
						user.homeFaculty.outgoingApplicationRequirements,
						faculty.incomingApplicationRequirements
					);
					return (
						<Fragment key={index}>
							{renderHiddenField("homeErasmusCode[]", user.homeOrganisation.code)}
							{renderHiddenField("homeName[]", user.homeOrganisation.title)}
							{renderHiddenField("homeOrgUnit[]", user.homeFaculty.title)}
							{renderHiddenField("coopConditionId[]", faculty.coopConditionId)}
							{renderHiddenField("hostErasmusCode[]", faculty.organisationCode)}
							{renderHiddenField("hostName[]", faculty.organisation, false, true)}
							{renderHiddenField("hostOrgUnit[]", faculty.title, false, true)}
							{renderHiddenField("cvRequired[]", homeAndHostRequirements.cvRequired === true)}
							{renderHiddenField(
								"externalCertRequired[]",
								homeAndHostRequirements.externalCertificationRequired === true
							)}
							{renderHiddenField(
								"motivationLetterRequired[]",
								homeAndHostRequirements.motivationLetterRequired === true
							)}
							{renderHiddenField("otherDocumentRequired[]", false /* ERA-590 */)}
							{renderHiddenField(
								"recomLetterRequired[]",
								homeAndHostRequirements.recommendationLetterRequired === true
							)}
							{renderHiddenField("torRequired[]", homeAndHostRequirements.transcriptRequired === true)}
							{renderHiddenField("portfolioRequired[]", homeAndHostRequirements.portfolioRequired)}
							{renderHiddenField(
								"otherRequirementRequired[]",
								Array.isArray(homeAndHostRequirements.otherRequirements)
							)}
							{renderHiddenField(
								"referenceContactRequired[]",
								homeAndHostRequirements.referenceContactRequired === true
							)}
						</Fragment>
					);
				})}
				{commonLanguageRequirements.map((commonLanguageRequirement, index) => (
					<Fragment key={index}>
						{renderFileOrUrlField(
							"languageCertificationRequirement",
							t("ProfilePanel.application.info.languageLevel", {
								language: commonLanguageRequirement.language.title,
								levels: commonLanguageRequirement.levels.map(level => level.title).join(", ")
							}),
							true,
							commonLanguageRequirement.language.code +
								"_" +
								commonLanguageRequirement.levels
									.map(level => level.code)
									.sort() // We sort the levels because this is used in the data-draft-id of the field. Reordering the destinations (which in turn provide levels) should not generate a different data-draft-id.
									.join("_"),
							undefined,
							commonLanguageRequirementCounts[commonLanguageRequirement.language.code]
						)}
					</Fragment>
				))}
				{commonRequirements.cvRequired &&
					renderFileOrUrlField(
						"cv",
						t("ProfilePanel.application.info.cv"),
						undefined,
						undefined,
						undefined,
						commonRequirementCounts.cvRequired
					)}
				{commonRequirements.motivationLetterRequired &&
					renderFileOrUrlField(
						"motivationLetter",
						t("ProfilePanel.application.info.motivationLetter"),
						undefined,
						undefined,
						undefined,
						commonRequirementCounts.motivationLetterRequired
					)}
				{commonRequirements.transcriptRequired &&
					renderFileOrUrlField(
						"transcript",
						t("ProfilePanel.application.info.transcript"),
						undefined,
						undefined,
						undefined,
						commonRequirementCounts.transcriptRequired
					)}
				{commonRequirements.recommendationLetterRequired &&
					renderFileOrUrlField(
						"recommendationLetter",
						t("ProfilePanel.application.info.recommendationLetter"),
						undefined,
						undefined,
						undefined,
						commonRequirementCounts.recommendationLetterRequired
					)}
				{commonRequirements.externalCertificationRequired &&
					renderFileOrUrlField(
						"externalCertification",
						t("ProfilePanel.application.info.externalCertification"),
						undefined,
						undefined,
						undefined,
						commonRequirementCounts.externalCertificationRequired
					)}
				{commonRequirements.referenceContactRequired &&
					renderTextField(
						"referenceContact",
						t("ProfilePanel.application.info.referenceContact"),
						undefined,
						commonRequirementCounts.referenceContactRequired
					)}
				{commonRequirements.portfolioRequired &&
					renderTextField(
						"portfolio",
						t("ProfilePanel.application.info.portfolio"),
						undefined,
						commonRequirementCounts.portfolioRequired
					)}
				{commonRequirements.otherRequirements &&
					commonRequirements.otherRequirements.map((otherRequirement, index) => (
						<Fragment key={index}>
							{renderHiddenField("otherRequirement-description", otherRequirement.description, true)}
							{renderTextField(
								"otherRequirement",
								otherRequirement.description,
								true,
								commonRequirementCounts["otherRequirements-" + otherRequirement.description]
							)}
						</Fragment>
					))}
				{commonRequirements.otherDocuments &&
					commonRequirements.otherDocuments.map((otherDocument, index) => (
						<Fragment key={index}>
							{renderFileOrUrlField(
								"otherDocument",
								otherDocument.description,
								true,
								otherDocument.description,
								false,
								commonRequirementCounts["otherDocuments-" + otherDocument.description]
							)}
						</Fragment>
					))}
				{/* Hidden button which can be clicked via code so the form submits.
					It is done that way because HTMLFormElement#requestSubmit() is not yet globally supported. */}
				{!readOnly && (
					<button type="submit" style={{ display: "none" }}>
						{t("next")}
					</button>
				)}
			</form>
			{status === "warning-uploadMaxSize" && (
				<div className="message warning">
					<p>
						{t("Form.submissionStatus.warning-uploadMaxSize", {
							uploadMaxSize: MAX_UPLOAD_SIZE
						})}
					</p>
				</div>
			)}
			{status === "pending" && (
				<div className="message pending">
					<p>{t("Form.submissionStatus.pending")}</p>
				</div>
			)}
			{status === "error" && (
				<div className="message error">
					<p>{t("Form.submissionStatus.error")}</p>
				</div>
			)}
			{!readOnly && (
				<div className="actions">
					<button
						onClick={e => {
							if (status === "warning-uploadMaxSize") {
								e.preventDefault();
								return;
							}
							saveApplication(parent);
						}}
						className={"button save" + (status === "warning-uploadMaxSize" ? " disabled" : "")}
					>
						{t("ProfilePanel.application.form.save")}
					</button>
					{user.lastApplicationUpdate && user.lastApplicationUpdate.status === "draft" && (
						<button
							onClick={() =>
								window.confirm(t("Applications.confirmAction.discard")) &&
								deleteDraftApplication(parent)
							}
							className="button discard"
						>
							{t("ProfilePanel.application.form.discard")}
						</button>
					)}
					<Link
						onClick={e => {
							if (status === "warning-uploadMaxSize") {
								e.preventDefault();
								return;
							}
							if (!isApplicationFormValid()) {
								// This will not actually submit form, because there are validation errors, but simply display the errors.
								applicationForm.querySelector("[type=submit]").click();
								// Do not follow the link.
								e.preventDefault();
							}
						}}
						to={getUrl(nextUrl)}
						role="button"
						className={"button" + (status === "warning-uploadMaxSize" ? " disabled" : "")}
					>
						{t("next")}
					</Link>
				</div>
			)}
		</Fragment>
	);
};

const renderApplicationReviewAndSubmit = parent => {
	const { t } = parent.props;
	const { applicationFormSubmissionStatus: status } = parent.state;
	if (status === "success")
		return (
			<div className="message success">
				<p>{t("ProfilePanel.application.review.success.description")}</p>
			</div>
		);
	return (
		<Fragment>
			<p>{t("ProfilePanel.application.review.description")}</p>
			{getOrderedPreferredHostFaculties(parent).map((preferredHostFaculty, index) => (
				<div key={index} className="facultyRequirements">
					<div className="faculty">
						<div className="ordinal">
							{t("ProfilePanel.application.choices.choice", {
								ordinal: getOrdinal(t, index + 1)
							})}
						</div>
						<div>
							<h2>{preferredHostFaculty.organisation}</h2>
							<p>
								{preferredHostFaculty.city}, {preferredHostFaculty.country},{" "}
								{preferredHostFaculty.title}
							</p>
							<p className="complete">{t("ProfilePanel.application.review.complete")}</p>
						</div>
					</div>
				</div>
			))}
			{status === "pending" && (
				<div className="message pending">
					<p>{t("Form.submissionStatus.pending")}</p>
				</div>
			)}
			{status === "error" && (
				<div className="message error">
					<p>{t("Form.submissionStatus.error")}</p>
				</div>
			)}
			<div className="actions">
				<button
					className="button"
					onClick={() => applicationForm.querySelector("[type=submit]").click()}
					disabled={status === "pending"}
				>
					{t("ProfilePanel.application.review.submit")}
				</button>
			</div>
		</Fragment>
	);
};

const renderApplicationFormResumePrepare = parent => renderApplicationFormPrepare(parent);

const renderApplicationFormViewPrepare = parent => renderApplicationFormPrepare(parent);

const renderApplicationFormPrepare = parent => {
	const { getUrl, user, academicYears, academicTerms } = parent.props;

	// Attempt to resolve some form values from the draft form.
	const selectedPreferredHostFacultyIds = (
		user.lastApplicationUpdate.data["erasmus-selectedPreferredHostFacultyIds"] || ""
	)
		.split(",")
		.map(Number);
	const selectedAcademicYear = academicYears.find(
		academicYear => academicYear.id === Number(user.lastApplicationUpdate.data["erasmus-academicYearId"])
	);
	const selectedAcademicTerm = academicTerms.find(
		academicTerm => academicTerm === user.lastApplicationUpdate.data["erasmus-academicTerm"]
	);
	const orderedPreferredHostFacultyIds = (
		user.lastApplicationUpdate.data["erasmus-orderedPreferredHostFacultyIds"] || ""
	)
		.split(",")
		.map(Number);

	// Set part of the ProfilePanel state relating to the form.
	parent.setState(
		{
			selectedPreferredHostFacultyIds,
			selectedAcademicYearId: selectedAcademicYear ? selectedAcademicYear.id : undefined,
			selectedAcademicTerm,
			orderedPreferredHostFacultyIds,
			prepopulateForm: true
		},
		// As soon as state is set, move user to the form.
		// Maybe not all values are good/present (if things have changed), but it doesn't matter since the subPanelsMetadata requirements checks will leave the user into a valid subPanel.
		() => history.replace(getUrl("/profile/application/form"))
	);
};
