import React from "react";
import { createRoot } from "react-dom/client";
import configureApp from "@/config.js";
import { RoutedTranslatedApp } from "@/skeleton/App.jsx";

console.log(`React: ${React.version}`);
configureApp();

createRoot(document.getElementById("root")).render(
	<React.StrictMode>
		<RoutedTranslatedApp />
	</React.StrictMode>
);
