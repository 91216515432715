import { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import "./SkillsAssessment.scss";
import {
	handleError,
	loadSkillsAssessment as loadSkillsAssessmentFromServer,
	createSkillsAssessmentMobility,
	editSkillsAssessmentMobility,
	deleteSkillsAssessmentMobility,
	submitSkillsAssessmentMobility,
	loadOrganisations
} from "@/skeleton/DataAccess.js";
import Loading from "@/common/Loading.jsx";
import { userPropTypes, getLinkToLoginPage } from "@/common/user.js";
import { Redirect } from "react-router-dom";
import { isoDateToTimestamp, renderDate, renderDateIso, serializeFormToFormData } from "@/common/utils.jsx";
import { SKILLS_LOGIN_URL, SKILLS_REGISTER_URL, SKILLS_PRIVACY_URL } from "@/config.js";
import Wrapper from "@/common/Wrapper.jsx";
import Autosuggest from "react-autosuggest";

const MINIMUM_MOBILITY_DURATION_DAYS = 60;

export default class SkillsAssessment extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		user: userPropTypes
	};

	initialState = {
		data: undefined,
		loadingStatus: undefined, // [undefined, "loading", "error-401", "error-404", "error-5xx", "error-network"]
		submissionStatus: undefined, // [undefined, "pending", "error"]
		action: undefined, // [undefined, "view", "create", "edit", "delete"]
		mobility: undefined,
		autosuggestValueSending: "",
		autosuggestValueReceiving: "",
		autosuggestItems: []
	};

	state = this.initialState;

	closeLoading = () => this.setState({ loadingStatus: undefined });

	componentDidMount() {
		const { user } = this.props;
		if (user) this.loadSkillsAssessment();
	}

	loadSkillsAssessment = () => {
		const { t } = this.props;
		this.setState({ ...this.initialState, loadingStatus: "loading" });
		loadSkillsAssessmentFromServer()
			.then(data => {
				this.setState({ data, loadingStatus: undefined });
			})
			.catch(error =>
				handleError(
					t,
					error,
					() => this.setState({ loadingStatus: "error-401" }), // Tiny possibility that user exists in the react application but has been invalidated on the backend, or has logged on from react in another tab.
					() => this.setState({ loadingStatus: "error-404" }), // Skills assessment user not found.
					() => this.setState({ loadingStatus: "error-5xx" }),
					() => this.setState({ loadingStatus: "error-network" })
				)
			);
	};

	createMobilityFormSubmitted = e => {
		const { action } = this.state;
		e.preventDefault();
		const formData = serializeFormToFormData(this.formDom);
		this.setState({ submissionStatus: "pending" });
		createSkillsAssessmentMobility(formData)
			.then(this.loadSkillsAssessment)
			.catch(() => {
				if (action === undefined) return; // User has cancelled in the mean time.
				this.setState({ submissionStatus: "error" });
			});
	};

	editMobilityFormSubmitted = e => {
		const { action, mobility } = this.state;
		e.preventDefault();
		const formData = serializeFormToFormData(this.formDom);
		this.setState({ submissionStatus: "pending" });
		editSkillsAssessmentMobility(mobility.id, formData)
			.then(this.loadSkillsAssessment)
			.catch(() => {
				if (action === undefined) return; // User has cancelled in the mean time.
				this.setState({ submissionStatus: "error" });
			});
	};

	deleteMobilityFormSubmitted = e => {
		const { action, mobility } = this.state;
		e.preventDefault();
		this.setState({ submissionStatus: "pending" });
		deleteSkillsAssessmentMobility(mobility.id)
			.then(this.loadSkillsAssessment)
			.catch(() => {
				if (action === undefined) return; // User has cancelled in the mean time.
				this.setState({ submissionStatus: "error" });
			});
	};

	mobilityFormSubmitted = e => {
		const { action, mobility } = this.state;
		e.preventDefault();
		const formSubmissionData = { status: mobility.questionnaire.status, answers: [] };
		mobility.questionnaire.categories.forEach(category =>
			category.questions.forEach(question =>
				formSubmissionData.answers.push({
					category: category.id,
					q_id: question.id,
					answer: Number(this.formDom.querySelector("[name=" + question.id + "]").value)
				})
			)
		);
		this.setState({ submissionStatus: "pending" });
		submitSkillsAssessmentMobility(mobility.id, formSubmissionData)
			.then(this.loadSkillsAssessment)
			.catch(() => {
				if (action === undefined) return; // User has cancelled in the mean time.
				this.setState({ submissionStatus: "error" });
			});
	};

	setEndDateMin = () => {
		const start = this.formDom.querySelector("input[type=date][name=start]");
		const end = this.formDom.querySelector("input[type=date][name=end]");
		if (start.valueAsNumber) {
			end.setAttribute(
				"min",
				renderDateIso(start.valueAsNumber / 1000 + 60 * 60 * 24 * MINIMUM_MOBILITY_DURATION_DAYS)
			);
		} else {
			end.removeAttribute("min");
		}
	};

	setStartDateMax = () => {
		const start = this.formDom.querySelector("input[type=date][name=start]");
		const end = this.formDom.querySelector("input[type=date][name=end]");
		if (end.valueAsNumber) {
			start.setAttribute(
				"max",
				renderDateIso(end.valueAsNumber / 1000 - 60 * 60 * 24 * MINIMUM_MOBILITY_DURATION_DAYS)
			);
		} else {
			start.removeAttribute("max");
		}
	};

	renderMobilityHeader = mobility => {
		const { t } = this.props;
		return (
			<div className="mobilityHeader">
				<h3>
					{t("SkillsAssessment.list.mobilityTitle", {
						organisation: mobility.receivingOrganisationLabel
					})}
				</h3>
				<p>
					{t("SkillsAssessment.list.mobilityDuration", {
						start: renderDate(isoDateToTimestamp(mobility.start), t("locale.full")),
						end: renderDate(isoDateToTimestamp(mobility.end), t("locale.full"))
					})}
				</p>
			</div>
		);
	};

	render() {
		const { t, user } = this.props;
		const {
			data,
			loadingStatus,
			submissionStatus,
			action,
			mobility,
			autosuggestValueSending,
			autosuggestValueReceiving,
			autosuggestItems
		} = this.state;

		// Only available to logged in users. Otherwise redirect to login page.
		if (!user) return <Redirect to={getLinkToLoginPage()} />;

		const renderUserNotFound = () => (
			<div className="message warning userNotFound">
				<p>
					{t.map(
						"SkillsAssessment.userNotFound.text",
						{},
						{
							_BR_: <br />,
							_EMAIL_: <code>{user.email}</code>,
							_LOGIN_: (
								<a href={SKILLS_LOGIN_URL} rel="external noopener noreferrer">
									{t("SkillsAssessment.userNotFound.login")}
								</a>
							),
							_REGISTER_: (
								<a href={SKILLS_REGISTER_URL} rel="external noopener noreferrer">
									{t("SkillsAssessment.userNotFound.register")}
								</a>
							),
							_RETRY_: <button onClick={this.loadSkillsAssessment}>{t("retry")}</button>
						}
					)}
				</p>
			</div>
		);

		const renderLoader = () => (
			<Loading
				t={t}
				status={loadingStatus}
				retry={this.loadSkillsAssessment}
				close={this.closeLoading}
				instant={true}
			/>
		);

		const renderMobilitiesList = () => (
			<Fragment>
				<h2>{t("SkillsAssessment.list.title")}</h2>
				{data.mobilities && data.mobilities.length > 0 && (
					<ul className="mobilities">
						{data.mobilities.map((mobility, index) => (
							<li key={index}>
								{this.renderMobilityHeader(mobility)}
								<div className="actions">
									<button
										className="button small hollow view"
										onClick={() => this.setState({ action: "view", mobility: mobility })}
									>
										{t("SkillsAssessment.list.view")}
									</button>
									<button
										className="button small hollow edit"
										onClick={() =>
											this.setState({
												action: "edit",
												mobility: mobility,
												autosuggestValueSending: mobility.sendingOrganisationLabel,
												autosuggestValueReceiving: mobility.receivingOrganisationLabel
											})
										}
									>
										{t("edit")}
									</button>
									<button
										className="button small hollow delete"
										onClick={() => this.setState({ action: "delete", mobility: mobility })}
									>
										{t("delete")}
									</button>
								</div>
							</li>
						))}
					</ul>
				)}
				<div className="actions">
					<button
						className="button create"
						onClick={() =>
							this.setState({
								action: "create",
								mobility: {
									sendingOrganisationLabel:
										(user.homeOrganisation && user.homeOrganisation.title) || "",
									receivingOrganisationLabel:
										(user.hostOrganisation && user.hostOrganisation.title) || ""
								},
								autosuggestValueSending: (user.homeOrganisation && user.homeOrganisation.title) || "",
								autosuggestValueReceiving: (user.hostOrganisation && user.hostOrganisation.title) || ""
							})
						}
					>
						{t("SkillsAssessment.list.create")}
					</button>
				</div>
			</Fragment>
		);

		const renderMobilityQuestionnaireForm = () => (
			<form className="Form" onSubmit={this.mobilityFormSubmitted} ref={element => (this.formDom = element)}>
				<div className="fields">
					{mobility.questionnaire.categories.map(category => (
						<fieldset key={category.id}>
							<legend>{category.name}</legend>
							{category.questions.map(question => (
								<div key={question.id} className="field-type-select">
									<label htmlFor={question.id}>{question.question}</label>
									<select name={question.id} id={question.id} required>
										<option value="">{t("SkillsAssessment.view.pleaseSelect")}</option>
										<option value="1">1 {t("SkillsAssessment.view.rateMin")}</option>
										<option>2</option>
										<option>3</option>
										<option>4</option>
										<option>5</option>
										<option>6</option>
										<option>7</option>
										<option>8</option>
										<option>9</option>
										<option value="10">10 {t("SkillsAssessment.view.rateMax")}</option>
									</select>
								</div>
							))}
						</fieldset>
					))}
					<div className="field-type-checkbox">
						<input name="agree" id="field-name-agree" type="checkbox" required />
						<label htmlFor="field-name-agree">
							{t.map(
								"SkillsAssessment.view.agree",
								{},
								{
									_PRIVACY_STATEMENT_: (
										<a href={SKILLS_PRIVACY_URL} rel="external noopener noreferrer" target="_blank">
											{t("SkillsAssessment.view.privacyPolicy")}
										</a>
									)
								}
							)}
						</label>
					</div>
				</div>
				{submissionStatus === "pending" && (
					<div className="message pending">
						<p>{t("Form.submissionStatus.pending")}</p>
					</div>
				)}
				{submissionStatus === "error" && (
					<div className="message error">
						<p>{t("Form.submissionStatus.error")}</p>
					</div>
				)}
				<div className="actions">
					<button type="submit" className="button submit" disabled={submissionStatus === "pending"}>
						{t("SkillsAssessment.view.submit")}
					</button>
					<button
						type="button"
						className="button cancel"
						onClick={() => this.setState({ action: undefined, mobility: undefined })}
					>
						{t("cancel")}
					</button>
				</div>
			</form>
		);

		const renderBackButton = () => (
			<div className="actions">
				<button
					className="button back"
					onClick={() =>
						this.setState({ submissionStatus: undefined, action: undefined, mobility: undefined })
					}
				>
					{t("back")}
				</button>
			</div>
		);

		const renderViewMobility = () => (
			<Fragment>
				{this.renderMobilityHeader(mobility)}
				<Wrapper className="actions">
					{mobility.questionnaire &&
						mobility.questionnaire.categories &&
						mobility.questionnaire.categories[0] &&
						mobility.questionnaire.categories[0].preMobilityResult !== undefined &&
						mobility.questionnaire.categories[0].postMobilityResult === undefined && (
							<button
								onClick={() => {
									this.setState({ action: "results" });
								}}
								className="button"
							>
								{t("SkillsAssessment.view.linkToPreMobilityResults")}
							</button>
						)}
					{mobility.questionnaire &&
						mobility.questionnaire.categories &&
						mobility.questionnaire.categories[0] &&
						mobility.questionnaire.categories[0].postMobilityResult !== undefined && (
							<button
								onClick={() => {
									this.setState({ action: "results" });
								}}
								className="button"
							>
								{t("SkillsAssessment.view.linkToResults")}
							</button>
						)}
				</Wrapper>
				{mobility.questionnaire && mobility.questionnaire.active && renderMobilityQuestionnaireForm()}
				{mobility.questionnaire && !mobility.questionnaire.active && (
					<Fragment>
						<p>{t("SkillsAssessment.view.inactive")}</p>
						{renderBackButton()}
					</Fragment>
				)}
			</Fragment>
		);

		const renderMobilityResults = () => (
			<Fragment>
				<h2>{t("SkillsAssessment.results.title")}</h2>
				{this.renderMobilityHeader(mobility)}
				<div className="resultsLegend" aria-hidden="true">
					<p className="preMobility">{t("SkillsAssessment.results.before")}</p>
					<p className="postMobility">{t("SkillsAssessment.results.after")}</p>
				</div>
				<div className="resultsTotals">
					<h4>Results in total</h4>
					{mobility.questionnaire.preMobilityResult !== undefined && (
						<div className="preMobility">
							<p>Before mobility</p>
							<div>
								<span style={{ "--pieChartPercentage": mobility.questionnaire.preMobilityResult }}>
									{mobility.questionnaire.preMobilityResult}
								</span>
							</div>
						</div>
					)}
					{mobility.questionnaire.postMobilityResult !== undefined && (
						<div className="postMobility">
							<p>After mobility</p>
							<div>
								<span style={{ "--pieChartPercentage": mobility.questionnaire.postMobilityResult }}>
									{mobility.questionnaire.postMobilityResult}
								</span>
							</div>
						</div>
					)}
				</div>
				<Wrapper className="results" htmlTag="ul">
					{mobility.questionnaire.categories.map(category => (
						<li key={category.id} className="result">
							<h4>{category.name}</h4>
							<div className="bar">
								{category.preMobilityResult !== undefined && (
									<div className="value preMobility">
										<label>{t("SkillsAssessment.results.before")}:</label>
										<span
											style={{
												left: Math.round(((category.preMobilityResult - 1) / 9) * 100) + "%"
											}}
										>
											{category.preMobilityResult}
										</span>
									</div>
								)}
								{category.postMobilityResult !== undefined && (
									<div className="value postMobility">
										<label>{t("SkillsAssessment.results.after")}:</label>
										<span
											style={{
												left: Math.round(((category.postMobilityResult - 1) / 9) * 100) + "%"
											}}
										>
											{category.postMobilityResult}
										</span>
									</div>
								)}
							</div>
						</li>
					))}
				</Wrapper>
				{renderBackButton()}
			</Fragment>
		);

		const renderCreateMobilityForm = () => (
			<Fragment>
				<h2>{t("SkillsAssessment.create.title")}</h2>
				<form
					className="Form"
					onSubmit={this.createMobilityFormSubmitted}
					ref={element => (this.formDom = element)}
				>
					<div className="fields">
						<div className="field-type-text">
							<label htmlFor="sendingOrganisationLabel">
								{t("SkillsAssessment.mobility.sendingOrganisationLabel")}
							</label>
							<Autosuggest
								suggestions={autosuggestItems}
								onSuggestionsFetchRequested={({ value }) =>
									loadOrganisations(value).then(values => this.setState({ autosuggestItems: values }))
								}
								onSuggestionsClearRequested={() => this.setState({ autosuggestItems: [] })}
								onSuggestionSelected={(event, { suggestion }) =>
									this.setState({
										mobility: { ...mobility, sendingOrganisationLabel: suggestion.title }
									})
								}
								getSuggestionValue={organisation => organisation.title}
								renderSuggestion={organisation => organisation.title}
								inputProps={{
									placeholder: t("ProfilePanel.organisation_prompt"),
									onChange: (event, { newValue }) =>
										this.setState({ autosuggestValueSending: newValue }),
									onBlur: () =>
										this.setState({ autosuggestValueSending: mobility.sendingOrganisationLabel }),
									maxLength: 255,
									name: "sendingOrganisationLabel",
									required: true,
									value: autosuggestValueSending
								}}
							/>
						</div>
						<div className="field-type-text">
							<label htmlFor="receivingOrganisationLabel">
								{t("SkillsAssessment.mobility.receivingOrganisationLabel")}
							</label>
							<Autosuggest
								suggestions={autosuggestItems}
								onSuggestionsFetchRequested={({ value }) =>
									loadOrganisations(value).then(values => this.setState({ autosuggestItems: values }))
								}
								onSuggestionsClearRequested={() => this.setState({ autosuggestItems: [] })}
								onSuggestionSelected={(event, { suggestion }) =>
									this.setState({
										mobility: { ...mobility, receivingOrganisationLabel: suggestion.title }
									})
								}
								getSuggestionValue={organisation => organisation.title}
								renderSuggestion={organisation => organisation.title}
								inputProps={{
									placeholder: t("ProfilePanel.organisation_prompt"),
									onChange: (event, { newValue }) =>
										this.setState({ autosuggestValueReceiving: newValue }),
									onBlur: () =>
										this.setState({
											autosuggestValueReceiving: mobility.receivingOrganisationLabel
										}),
									maxLength: 255,
									name: "receivingOrganisationLabel",
									required: true,
									value: autosuggestValueReceiving
								}}
							/>
						</div>
						<div className="field-type-date">
							<label htmlFor="start">{t("SkillsAssessment.mobility.start")}</label>
							<input name="start" id="start" type="date" onChange={this.setEndDateMin} required />
						</div>
						<div className="field-type-date">
							<label htmlFor="end">{t("SkillsAssessment.mobility.end")}</label>
							<input name="end" id="end" type="date" onChange={this.setStartDateMax} required />
						</div>
					</div>
					{submissionStatus === "pending" && (
						<div className="message pending">
							<p>{t("Form.submissionStatus.pending")}</p>
						</div>
					)}
					{submissionStatus === "error" && (
						<div className="message error">
							<p>{t("Form.submissionStatus.error")}</p>
						</div>
					)}
					<div className="actions">
						<button type="submit" className="button submit" disabled={submissionStatus === "pending"}>
							{t("SkillsAssessment.create.submit")}
						</button>
						<button
							type="button"
							className="button cancel"
							onClick={() =>
								this.setState({ submissionStatus: undefined, action: undefined, mobility: undefined })
							}
						>
							{t("cancel")}
						</button>
					</div>
				</form>
			</Fragment>
		);

		const renderEditMobilityForm = () => (
			<Fragment>
				<h2>{t("SkillsAssessment.edit.title")}</h2>
				<form
					className="Form"
					onSubmit={this.editMobilityFormSubmitted}
					ref={element => (this.formDom = element)}
				>
					<div className="fields">
						<div className="field-type-text">
							<label htmlFor="sendingOrganisationLabel">
								{t("SkillsAssessment.mobility.sendingOrganisationLabel")}
							</label>
							<Autosuggest
								suggestions={autosuggestItems}
								onSuggestionsFetchRequested={({ value }) =>
									loadOrganisations(value).then(values => this.setState({ autosuggestItems: values }))
								}
								onSuggestionsClearRequested={() => this.setState({ autosuggestItems: [] })}
								onSuggestionSelected={(event, { suggestion }) =>
									this.setState({
										mobility: { ...mobility, sendingOrganisationLabel: suggestion.title }
									})
								}
								getSuggestionValue={organisation => organisation.title}
								renderSuggestion={organisation => organisation.title}
								inputProps={{
									placeholder: t("ProfilePanel.organisation_prompt"),
									onChange: (event, { newValue }) =>
										this.setState({ autosuggestValueSending: newValue }),
									onBlur: () =>
										this.setState({ autosuggestValueSending: mobility.sendingOrganisationLabel }),
									maxLength: 255,
									name: "sendingOrganisationLabel",
									required: true,
									value: autosuggestValueSending
								}}
							/>
						</div>
						<div className="field-type-text">
							<label htmlFor="receivingOrganisationLabel">
								{t("SkillsAssessment.mobility.receivingOrganisationLabel")}
							</label>
							<Autosuggest
								suggestions={autosuggestItems}
								onSuggestionsFetchRequested={({ value }) =>
									loadOrganisations(value).then(values => this.setState({ autosuggestItems: values }))
								}
								onSuggestionsClearRequested={() => this.setState({ autosuggestItems: [] })}
								onSuggestionSelected={(event, { suggestion }) =>
									this.setState({
										mobility: { ...mobility, receivingOrganisationLabel: suggestion.title }
									})
								}
								getSuggestionValue={organisation => organisation.title}
								renderSuggestion={organisation => organisation.title}
								inputProps={{
									placeholder: t("ProfilePanel.organisation_prompt"),
									onChange: (event, { newValue }) =>
										this.setState({ autosuggestValueReceiving: newValue }),
									onBlur: () =>
										this.setState({
											autosuggestValueReceiving: mobility.receivingOrganisationLabel
										}),
									maxLength: 255,
									name: "receivingOrganisationLabel",
									required: true,
									value: autosuggestValueReceiving
								}}
							/>
						</div>
						<div className="field-type-date">
							<label htmlFor="start">{t("SkillsAssessment.mobility.start")}</label>
							<input
								name="start"
								id="start"
								type="date"
								defaultValue={renderDateIso(isoDateToTimestamp(mobility.start))}
								onChange={this.setEndDateMin}
								required
							/>
						</div>
						<div className="field-type-date">
							<label htmlFor="end">{t("SkillsAssessment.mobility.end")}</label>
							<input
								name="end"
								id="end"
								type="date"
								defaultValue={renderDateIso(isoDateToTimestamp(mobility.end))}
								onChange={this.setStartDateMax}
								required
							/>
						</div>
					</div>
					{submissionStatus === "pending" && (
						<div className="message pending">
							<p>{t("Form.submissionStatus.pending")}</p>
						</div>
					)}
					{submissionStatus === "error" && (
						<div className="message error">
							<p>{t("Form.submissionStatus.error")}</p>
						</div>
					)}
					<div className="actions">
						<button type="submit" className="button submit" disabled={submissionStatus === "pending"}>
							{t("SkillsAssessment.edit.submit")}
						</button>
						<button
							type="button"
							className="button cancel"
							onClick={() =>
								this.setState({ submissionStatus: undefined, action: undefined, mobility: undefined })
							}
						>
							{t("cancel")}
						</button>
					</div>
				</form>
			</Fragment>
		);

		const renderDeleteMobilityForm = () => (
			<Fragment>
				<h2>{t("SkillsAssessment.delete.title")}</h2>
				<form className="Form" onSubmit={this.deleteMobilityFormSubmitted}>
					{this.renderMobilityHeader(mobility)}
					<div className="message warning">
						<p>{t("SkillsAssessment.delete.confirm")}</p>
					</div>
					{submissionStatus === "pending" && (
						<div className="message pending">
							<p>{t("Form.submissionStatus.pending")}</p>
						</div>
					)}
					{submissionStatus === "error" && (
						<div className="message error">
							<p>{t("Form.submissionStatus.error")}</p>
						</div>
					)}
					<div className="actions">
						<button type="submit" className="button submit" disabled={submissionStatus === "pending"}>
							{t("SkillsAssessment.delete.submit")}
						</button>
						<button
							type="button"
							className="button cancel"
							onClick={() =>
								this.setState({ submissionStatus: undefined, action: undefined, mobility: undefined })
							}
						>
							{t("cancel")}
						</button>
					</div>
				</form>
			</Fragment>
		);

		return (
			<div className="SkillsAssessment">
				{loadingStatus === "error-404" && renderUserNotFound()}
				{loadingStatus !== undefined && loadingStatus !== "error-404" && renderLoader()}
				{data !== undefined && action === undefined && renderMobilitiesList()}
				{action === "view" && mobility !== undefined && renderViewMobility()}
				{action === "results" && mobility !== undefined && renderMobilityResults()}
				{action === "create" && renderCreateMobilityForm()}
				{action === "edit" && mobility !== undefined && renderEditMobilityForm()}
				{action === "delete" && mobility !== undefined && renderDeleteMobilityForm()}
			</div>
		);
	}
}
