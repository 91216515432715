import { PureComponent } from "react";
import PropTypes from "prop-types";

/**
 * Returns a human friendly string of how long the specified timestamp is.
 *
 * @param {number} timestamp - Unix timestamp in seconds.
 * @param {function} t - The translate function.
 */
export function ago(timestamp, t) {
	const NOW = Date.now();
	const DIFF_SECONDS = Math.floor(NOW / 1000) - timestamp;
	if (DIFF_SECONDS < 0) {
		return t("ago.future");
	}
	if (DIFF_SECONDS === 0) {
		return t("ago.now");
	}
	if (DIFF_SECONDS < 60) {
		return t("ago.seconds", DIFF_SECONDS);
	}
	if (DIFF_SECONDS < 60 * 60) {
		return t("ago.minutes", Math.floor(DIFF_SECONDS / 60));
	}
	if (DIFF_SECONDS < 60 * 60 * 24) {
		return t("ago.hours", Math.floor(DIFF_SECONDS / (60 * 60)));
	}
	if (DIFF_SECONDS < 60 * 60 * 24 * 30) {
		return t("ago.days", Math.floor(DIFF_SECONDS / (60 * 60 * 24)));
	}
	if (DIFF_SECONDS < 60 * 60 * 24 * 365) {
		return t("ago.months", Math.floor(DIFF_SECONDS / (60 * 60 * 24 * 30)));
	}
	return t("ago.years", Math.floor(DIFF_SECONDS / (60 * 60 * 24 * 365)));
}

/**
 * Returns the date and time of the specified timestamp.
 *
 * @param {number} timestamp - Unix timestamp in seconds.
 * @param {function} t - The translate function.
 */
export function renderTimestamp(timestamp, t) {
	return new Date(timestamp * 1000).toLocaleString(t("locale.full"), {
		year: "numeric",
		month: "short",
		day: "2-digit",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit"
	});
}

/**
 * Returns the date and time of the specified timestamp.
 *
 * @param {number} timestamp - Unix timestamp in seconds.
 * @param {function} t - The translate function.
 */
export function renderDate(timestamp, t) {
	return new Date(timestamp * 1000).toLocaleString(t("locale.full"), {
		year: "numeric",
		month: "long",
		day: "numeric"
	});
}

export default class Ago extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		timestamp: PropTypes.number.isRequired
	};

	render() {
		const { t, timestamp } = this.props;
		return (
			<span className="Ago" title={renderTimestamp(timestamp, t)}>
				{ago(timestamp, t)}
			</span>
		);
	}
}
