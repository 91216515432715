import { PureComponent } from "react";
import PropTypes from "prop-types";
import { userPropTypes } from "@/common/user.js";
import { renderNodeUrl } from "@/common/utils.jsx";
import { Link } from "react-router-dom";
import "./NodeList.scss";

export const listPropTypes = PropTypes.exact({
	title: PropTypes.string,
	nodes: PropTypes.arrayOf(
		PropTypes.exact({
			id: PropTypes.number.isRequired,
			type: PropTypes.oneOf(["deal", "event", "service", "story", "tip", "useful_information"]).isRequired,
			title: PropTypes.string.isRequired,
			alias: PropTypes.string.isRequired,
			language: PropTypes.string,
			languages: PropTypes.arrayOf(PropTypes.string),
			miniSummary: PropTypes.string.isRequired
		})
	).isRequired
});

export default class NodeList extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
		getUrl: PropTypes.func.isRequired,
		user: userPropTypes,
		list: listPropTypes
	};

	render() {
		const { t, getUrl, user, list } = this.props;

		return (
			<div className="NodeList">
				{list.title && <h2>{list.title}</h2>}
				<ul>
					{list.nodes.map(node => (
						<li key={node.id} className="mini-teaser">
							<h3>
								<Link to={getUrl(renderNodeUrl(node, user))}>{node.title}</Link>
							</h3>
							<span className={"ct_" + node.type}>{t("NodeType." + node.type)}</span>
							<p className="small-text">{node.miniSummary}</p>
						</li>
					))}
				</ul>
			</div>
		);
	}
}
