import { PureComponent } from "react";
import PropTypes from "prop-types";
import { BACKEND_DOMAIN, RUNS_THROUGH_REACT_NATIVE_APP } from "@/config.js";
import "./ShareButton.scss";

const getShareData = () => ({ title: window.document.title, url: window.location.toString() });

const shareForBrowser = async function () {
	try {
		await window.navigator.share(getShareData());
		console.log("URL shared successfully.");
	} catch (error) {
		console.log("URL share failed", error.message);
	}
};

const shareForReactNative = function () {
	if (!window.ReactNativeWebView) return;
	window.ReactNativeWebView.postMessage(
		"shareUrl," +
			window.encodeURIComponent(window.document.title) +
			"," +
			window.encodeURIComponent(BACKEND_DOMAIN + window.location.pathname)
	);
};

export default class ShareButton extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired
	};

	onClick = function () {
		if (RUNS_THROUGH_REACT_NATIVE_APP) {
			shareForReactNative();
		} else {
			shareForBrowser();
		}
	};

	render() {
		// Only render share button if running through react native or via a share capable browser.
		// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share
		if (
			!(RUNS_THROUGH_REACT_NATIVE_APP || (window.navigator.canShare && window.navigator.canShare(getShareData())))
		)
			return null;

		const { t } = this.props;
		return (
			<button className="button secondary small ShareButton" onClick={this.onClick}>
				<span>{t("ShareButton")} </span>
			</button>
		);
	}
}
